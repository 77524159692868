<template>
  <fcs-row class="mt-4" align-h="center" align-self="center">
    <fcs-col class="d-flex flex-column">
      <p class="mb-1 title">{{ cardTitle }}</p>
      <fcs-form-input-text
        id="scheduleName"
        :placeholder="$t(placeholderNameValue)"
        v-model="scheduleName"
        :error-state="isInvalid"
        :errorText="errorText"
        ref="schedule-name"
        data-qa="scheduleName">
      </fcs-form-input-text>
<!--      <FSISupportNumber @isValid="setSupportNumberValid" v-if="showSupportNumber" data-qa="supportNumber"/>-->
      <hr>
      <fcs-row class="pr-3 pl-3" align-h="end">
        <fcs-button class="mobile-friendly-buttons" size="lg" :disabled="!isComplete()" data-qa="next-button" variant="primary" @click="handleNextClick()"> {{ $t('global.next') }} </fcs-button>
      </fcs-row>
      <fcs-button class="mobile-friendly-buttons cancel-button" data-qa="cancel-button" variant="outline-primary" @click="handleCancel()"> {{ $t('global.cancel') }} </fcs-button>
    </fcs-col>
  </fcs-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'FSISetParameters',
  components: {},
  props: {
    placeholder: {
      default: `${i18n.t('inhibit.editableText.validationDuplicateName')}`
    },
    cardTitle: String,
    card: Object,
    showSupportNumberComponent: Boolean,
    placeholderNameValue: {
      default: 'inhibit.start.setParams.placeholderText'
    }
  },
  created () {
    if (this.getSupportNumber) {
      this.isSupportNumberValid = true;
    }
  },
  data () {
    return {
      scheduleName: this.$store.state.scheduleName,
      selectedText: this.$store.state.vehicles.textAlertPrefOn,
      selectedEmail: this.$store.state.vehicles.emailAlertPrefOn,
      showSupportNumber: this.showSupportNumberComponent,
      options: [],
      showInfoIcon: this.$store.state.vehicles.isUserPrefCallSuccess,
      isInvalid: false,
      isSupportNumberValid: false,
      errorText: ''
    };
  },
  watch: {
    scheduleName: function () {
      if (this.getScheduleNames().includes(this.scheduleName.toLowerCase().trim())) {
        this.errorText = `${i18n.t('inhibit.editableText.validationDuplicateName')}`;
        this.isInvalid = true;
      } else if (/[^A-Z a-z0-9]/.test(this.scheduleName)) {
        this.errorText = `${i18n.t('inhibit.editableText.validationSpecialCharacters')}`;
        this.isInvalid = true;
      } else if (this.scheduleName.length > 30) {
        this.errorText = `${i18n.t('inhibit.editableText.validationMaxLength')}`;
        this.isInvalid = true;
      } else {
        this.isInvalid = false;
      }
    }
  },
  methods: {
    ...mapActions(['clearSupportNumber', 'clearWizard']),
    ...mapGetters('vehicles', ['getScheduleNames']),
    isComplete () {
      return (this.scheduleName.length && !this.isInvalid && !this.isDuplicateName);
    },
    handleNextClick (e) {
      this.$store.commit('incrementProgressbarIndex');
      this.$store.commit('saveParameters', { scheduleName: this.scheduleName, selectedText: this.selectedText, selectedEmail: this.selectedEmail });
    },
    setSupportNumberValid (isValid) {
      this.isSupportNumberValid = isValid;
    },
    handleCancel () {
      this.clearWizard();
      this.clearSupportNumber();
      this.$router.push('/inhibit');
    }
  },
  computed: {
    ...mapState(['activeIndex', 'supportNumber']),
    ...mapState('vehicles', ['isUserPrefCallSuccess', 'textAlertPrefOn', 'emailAlertPrefOn']),
    ...mapGetters(['isErrorStatus', 'getSupportNumber']),
    isDuplicateName () {
      return this.getScheduleNames().includes(this.scheduleName.toLowerCase().trim());
    }
  }
};
</script>
<style scoped lang="scss">

.form-control {
  width: 30% !important;
  height: 3rem !important;
  &:focus {
    box-shadow: none;
  }
}

.title{
  color: #8A8A8A !important;
}

#scheduleName {
  height: 60px;
  width: 400px;
}

@media only screen and (max-width: 640px) {
  .mobile-friendly-buttons {
    flex-grow: 1;
  }

  .cancel-button {
    margin-top: .5rem;
  }
}

@media only screen and (min-width: 641px) {
  .cancel-button {
    display: none;
  }
}

.mobile-checkboxes {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
}
</style>
