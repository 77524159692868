<template>
  <fcs-customizable-dialog
    :dialog-title="$t('inhibit.confirmationDialogTitle')"
    ref="adhoc-warn-modal"
    no-close-on-esc
    no-close-on-backdrop
    :submit-button-text="$t('inhibit.adhoc.continue')"
    :cancel-button-text="$t('inhibit.adhoc.cancel')"
    :submit-button-visible="true"
    :cancel-button-visible="true"
    :close-button-handler="closeModal"
    :cancel-button-handler="closeModal"
    :submit-button-handler="handleSubmit"
    :append-to-body="appendToBody"
    submitButtonType="primary">
    <fcs-container>
        {{ $t('inhibit.adhoc.dialogWarnText') }}
        <fcs-icon id="showInfo" data-qa="info-icon" scale="0" fill-color="#0064D4" name="icon-info"></fcs-icon>
      <fcs-tooltip target="showInfo" triggers="hover click" placement="right" id="showInfoTooltip">
          <p>{{ $t('inhibit.adhoc.superDutyNote') }}</p>
      </fcs-tooltip>
    </fcs-container>
  </fcs-customizable-dialog>
</template>

<script>
export default {
  name: 'AdHocWarnModal.vue',
  props: {
    appendToBody: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal () {
      this.$refs['adhoc-warn-modal'].hideModal();
    },
    handleSubmit () {
      this.closeModal();
      this.$parent.$refs.adhocInhibitModal.$refs['inhibit-modal'].showModal();
    }
  }
};
</script>
