<template>
  <fcs-container fluid class="body overflow-auto vh-100 d-flex flex-column" id="impact-alert-screen" align-v="center">
    <fcs-row>
      <Header :title="this.title"></Header>
    </fcs-row>
    <div class="mt-1 pt-1"></div>
    <fcs-row class="step1" v-if="activeIndex === 0">
      <fcs-col class="mx-auto" lg="12" xl="12">
        <fcs-progress-tracker
          :currentValue="$t('global.progressBar.setParam')"
          :steps="$t('global.progressBarElements')"
          :allStepsCompleted="false"
          class="progressTrackerStyles"
        />
      </fcs-col>
      <fcs-col class="mx-auto" lg="8">
        <FSISetParameters
          :show-support-number-component="true"
          :activeIndex='0'
          :cardTitle="$t('inhibit.start.setParams.cardSubtitle')">
        </FSISetParameters>
      </fcs-col>
    </fcs-row>
    <fcs-row class="step2" v-if="activeIndex === 1">
      <fcs-col class="mx-auto" lg="12" xl="12">
        <fcs-progress-tracker
          :currentValue="$t('global.progressBar.createSchedule')"
          :steps="$t('global.progressBarElements')"
          :allStepsCompleted="false"
          class="progressTrackerStyles"
        />
      </fcs-col>
      <fcs-col class="mx-auto" lg="8">
        <p class="mt-3">{{$t('inhibit.scheduleTypePrompt')}}</p>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="selected" @input="updateScheduleType" :aria-describedby="ariaDescribedby" name="some-radios" data-qa="recurring-radio" value="RECURRING">
            {{$t('inhibit.recurringSchedule')}}
          </b-form-radio>
          <b-form-radio v-model="selected" @input="updateScheduleType" :aria-describedby="ariaDescribedby" name="some-radios" data-qa="onetime-radio" value="ONE_TIME">
            {{$t('inhibit.oneTimeSchedule')}}
          </b-form-radio>
        </b-form-group>
        <ChooseRecurringSchedule v-if="selected === 'RECURRING'" v-bind:scheduleType="'start'"/>
        <ChooseOneTimeSchedule v-if="selected === 'ONE_TIME'" v-bind:scheduleType="'start'"/>
      </fcs-col>
    </fcs-row>
    <div class="flex-grow-1 d-flex flex-column h-100 col-lg-9 mx-auto" v-if="activeIndex === 2">
      <fcs-row>
        <fcs-col class="mx-auto" lg="12" xl="12">
          <fcs-progress-tracker
            :currentValue="$t('global.progressBar.assignVehicle')"
            :steps="$t('global.progressBarElements')"
            :allStepsCompleted="false"
            class="progressTrackerStyles"
          />
        </fcs-col>
      </fcs-row>
      <InhibitVehiclePaginatedTable :should-run-vehicles-refresh=false />
      <fcs-row class="justify-content-center">
        <fcs-col class="col-md-11"><hr></fcs-col>
      </fcs-row>
      <fcs-button class="mobile-friendly-buttons" :disabled="!areAnyVehiclesSelected()" data-qa="finish-button" variant="primary" @click="handleFinishClick">{{ $t('global.finish') }}</fcs-button>
      <fcs-button class="mobile-friendly-buttons" v-if="activeIndex>0" data-qa="previous-button" variant="outline-primary"
                  @click="handlePrevClick">{{ $t('global.previous') }}</fcs-button>
      <fcs-row class="align-items-center py-3 mobile-friendly-buttons" align-h="end" style="margin-left: 5%; margin-right: 5%;">
        <fcs-button class="mr-4" v-if="activeIndex>0" data-qa="previous-button" variant="outline-primary"
                    @click="handlePrevClick">{{ $t('global.previous') }}</fcs-button>
        <fcs-button :disabled="!areAnyVehiclesSelected()" data-qa="finish-button" variant="primary" @click="handleFinishClick">{{ $t('global.finish') }}</fcs-button>
      </fcs-row>
    </div>
    <fcs-customizable-dialog
      :dialogTitle="$t('inhibit.confirmationDialogTitle')"
      ref="inhibitOverlapWarningModal"
      data-qa="inhibitOverlapWarningModal"
      :submitDisabled="false"
      :submitButtonText="$t('global.submit')"
      :cancelButtonText="$t('global.cancel')"
      submitButtonVisible
      cancelButtonVisible
      appendToBody
      :cancel-button-handler="cancelWarningModal"
      :submit-button-handler="closeWarningModalOnSubmit"
      submitButtonType="primary"
    >
    <p>{{this.vinsWithOverlappingInhibitSchedules}} {{$t('inhibit.overlappingInhibitWarning')}}</p>
    </fcs-customizable-dialog>
  </fcs-container>
</template>

<script>
import Header from '@/components/common/Header';
import FSISetParameters from '@/fleetstartinhibit/components/FSISetParameters';
import ChooseRecurringSchedule from '@/components/common/ChooseRecurringSchedule';
import ChooseOneTimeSchedule from '@/components/common/ChooseOneTimeSchedule';
import { mapActions, mapGetters, mapState } from 'vuex';
import InhibitVehiclePaginatedTable from '@/fleetstartinhibit/components/InhibitVehiclePaginatedTable';
import moment from 'moment-timezone';
import { i18n } from '@/i18n';

export default {
  name: 'ScheduleInhibit',
  components: {
    InhibitVehiclePaginatedTable,
    Header,
    FSISetParameters,
    ChooseRecurringSchedule,
    ChooseOneTimeSchedule
  },
  data () {
    return {
      selected: '',
      startInhibitDateTime: '',
      endInhibitDateTime: '',
      scheduledRequest: {},
      vinsWithOverlappingInhibitSchedules: 0,
      isWarningModalVisible: false,
      title: '',
      inhibitType: ''
    };
  },
  created () {
    this.clearWizard();
    this.setTitle(this.$route.params.title);
    this.setInhibitType(this.$route.params.inhibitType);
    this.$store.commit('resetTimeZone');
  },
  methods: {
    ...mapActions(['clearWizard', 'saveScheduleType']),
    setTitle (title) {
      if (title === undefined || title === '') {
        this.title = i18n.t('inhibit.start.headerTitle');
      } else {
        this.title = this.$route.params.title;
      }
    },
    setInhibitType (type) {
      if (type === undefined || type === '') {
        this.inhibitType = 'START';
      } else {
        this.inhibitType = this.$route.params.inhibitType;
      }
    },
    showWarningModal () {
      this.isWarningModalVisible = true;
      this.$refs.inhibitOverlapWarningModal.showModal();
    },
    cancelWarningModal () {
      this.isWarningModalVisible = false;
      this.$refs.inhibitOverlapWarningModal.hideModal();
    },
    async closeWarningModalOnSubmit () {
      this.isWarningModalVisible = false;
      this.$refs.inhibitOverlapWarningModal.hideModal();
      await this.submitRequest(this.scheduledRequest);
    },
    areAnyVehiclesSelected () {
      return this.getSelectedVins && this.getSelectedVins.length > 0;
    },
    async submitRequest (scheduledRequest) {
      await this.$store.dispatch('vehicles/sendScheduleRequest', scheduledRequest)
        .then(() => this.$router.push({
          name: 'Inhibit',
          params: {
            displayScheduleInhibitAlerts: true,
            inhibitAlertType: this.inhibitType.toLowerCase()
          }
        }));
      this.$store.commit('updateSupportNumber', { supportNumber: '' });
    },
    handlePrevClick (e) {
      this.$store.commit('decrementProgressbarIndex');
      this.$store.commit('vehicles/SET_SELECTED', this.getSelected);
    },
    async handleFinishClick () {
      this.scheduledRequest = this.buildScheduledInhibitRequest();
      await this.$store.dispatch('vehicles/validateSchedule', this.scheduledRequest);
      this.vinsWithOverlappingInhibitSchedules = this.$store.getters['vehicles/getRequestVinsWithOverlappingScheduleSize'];
      if (this.vinsWithOverlappingInhibitSchedules > 0) {
        this.showWarningModal();
      } else {
        await this.submitRequest(this.scheduledRequest);
      }
    },
    buildScheduledInhibitRequest () {
      let inhibitStartTime, inhibitEndTime;
      if (this.scheduleType === 'RECURRING') {
        const format = 'hh:mm A';
        inhibitStartTime = moment.tz(`${this.startTime.hour}:${this.startTime.min} ${this.startTime.amPm}`, format, this.fsiTimeZone).utc();
        inhibitEndTime = moment.tz(`${this.endTime.hour}:${this.endTime.min} ${this.endTime.amPm}`, format, this.fsiTimeZone).utc();
        if (inhibitEndTime.isBefore(inhibitStartTime)) {
          inhibitEndTime = inhibitEndTime.add(1, 'days'); // bump to "overnight" schedule
        }
      } else {
        const format = 'YYYY-MM-DD hh:mm A';
        inhibitStartTime = moment.tz(`${this.startDate} ${this.startTime.hour}:${this.startTime.min} ${this.startTime.amPm}`, format, this.fsiTimeZone).utc();
        inhibitEndTime = moment.tz(`${this.endDate} ${this.endTime.hour}:${this.endTime.min} ${this.endTime.amPm}`, format, this.fsiTimeZone).utc();
      }

      return {
        vins: this.getSelectedVins,
        scheduleName: this.scheduleName,
        inhibitType: this.convertInhibitType(this.inhibitType),
        inhibitStartTime: inhibitStartTime,
        inhibitEndTime: inhibitEndTime,
        timezone: this.getTimeZone,
        scheduleType: this.scheduleType,
        activeDays: this.getDays
      };
    },
    convertInhibitType (inhibitType) {
      if (inhibitType === 'START') {
        return 'INHIBIT_CRANK';
      } else {
        return 'INHIBIT_DRIVING';
      }
    },
    convertTimeZone (timeZoneValue) {
      for (const i in this.timeZoneConverter) {
        if (timeZoneValue === this.timeZoneConverter[i].text) {
          return this.timeZoneConverter[i].value;
        }
      }
      return 'utc';
    },
    updateScheduleType (text) {
      this.saveScheduleType(text);
    }
  },
  computed: {
    ...mapGetters('vehicles', ['getSelectedVins']),
    ...mapState(['activeIndex', 'scheduleType', 'scheduleName', 'supportNumber', 'startTime', 'startDate',
      'endTime', 'endDate', 'timeZoneConverter', 'fsiTimeZone']),
    ...mapState({
      getDays: state => state.selected,
      getTimeZone: state => state.fsiTimeZone
    })
  }
};
</script>

<style scoped lang="scss">
.progressTrackerStyles {
  font-size: 12px;
}

span.step-value {
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  button.mobile-friendly-buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: .5rem;
  }
  div.mobile-friendly-buttons {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  button.mobile-friendly-buttons {
    display: none;
  }
}
</style>
