import axios, { AxiosInstance } from 'axios';
import { csrfInterceptor } from '@/services/CsrfInterceptor';

class UserService {
  private readonly client: AxiosInstance;

  constructor (client?: AxiosInstance) {
    if (client) {
      this.client = client;
    } else {
      this.client = axios.create({
        baseURL: process.env.VUE_APP_SMA_BASE_URL,
        withCredentials: true
      });
    }
    this.client.interceptors.request.use(csrfInterceptor);
  }

  async getAllUsers (ids?:Array<string>) {
    let pageNumber = 0;
    let usersPage = await this.getUsers(pageNumber, ids);

    let users = usersPage.results;
    const totalUsers = usersPage.totalSize;

    while (users.length < totalUsers) {
      pageNumber += 1;
      usersPage = await this.getUsers(pageNumber, ids);

      users = users.concat(usersPage.results);
    }
    return users;
  }

  async getUsers (page: number, ids?: Array<string>, size = 25) {
    try {
      const queryParams = ids ? { page, size, userIds: ids.join(',') } : { page, size };
      const userPageResponse = await this.client.get('v1/users', { params: queryParams });
      return userPageResponse.data;
    } catch (error) {
      console.warn(`error getting users defaulting to empty list, error: ${error}`);
      return { totalSize: 0, results: [] };
    }
  }

  async getUsersById (ids: Array<string>) {
    try {
      const users = await this.getAllUsers(ids);
      return users.map((user: any) => ({
        id: user.id,
        name: user.firstName.concat(' ', user.lastName),
        checked: true
      }));
    } catch (error) {
      console.log(`Error getting users from API ${error}`);
      return null;
    }
  }
}

export default new UserService();
export { UserService };
