import axios, { AxiosInstance } from 'axios';
import moment from 'moment-timezone';
import { ALERT_TYPE } from '@/constants';
import { CardInfo, Recipient } from '@/store/types';
import userService from '@/services/UserService';
import { csrfInterceptor } from '@/services/CsrfInterceptor';

 interface NotificationDTO {
  id?: number
  preferenceName: string,
  recipientIDs:Array<string>,
  companyGuid?: string,
  requestId?: string,
  vins: Array<string>,
  preference:{
    id?: number
    alertCategory: string,
    alertType: string,
    timeframe:{
      id?: number
      days:Array<string>,
      startTime: string,
      endTime: string
      zone: string
    }
  }
}

class NotificationService {
  private readonly client: AxiosInstance;

  constructor (client?: AxiosInstance) {
    if (client) {
      this.client = client;
    } else {
      this.client = axios.create({
        baseURL: process.env.VUE_APP_SMA_BASE_URL,
        withCredentials: true
      });
    }
    this.client.interceptors.request.use(csrfInterceptor);
  }

  async postNotification (payload: CardInfo) {
    const requestBody = this.convertToJSON(payload);
    try {
      const response = await this.client.post('v1/notification', requestBody);
      return response.data;
    } catch (error) {
      // TODO: handle error
      console.log(`Error persisting schedules to database: ${error}`);
      return null;
    }
  }

  async getNotification () {
    try {
      const response = await this.client.get('v1/notification');
      return Promise.all(response.data.map(async (item: NotificationDTO) => this.convertResponseObjectToCardInfo(item)));
    } catch (error) {
      // TODO: handle error
      console.log(`Error querying notifications from database: ${error}`);
      return null;
    }
  }

  async editNotification (payload: CardInfo) {
    const requestBody = this.convertToJSON(payload);
    try {
      const response = await this.client.put('v1/notification', requestBody);
      return response.data;
    } catch (error) {
      // TODO: handle error
      console.log(`Error updating schedules to database: ${error}`);
      return null;
    }
  }

  async deleteNotification (id: number) {
    try {
      const response = await this.client.delete(`v1/notification/${id}`);
      return response;
    } catch (error) {
      // TODO: handle error
      console.log(`Error deleting schedules from database: ${error}`);
      return null;
    }
  }

  async convertResponseObjectToCardInfo (payload: NotificationDTO): Promise<CardInfo> {
    const { recipientIDs, id, preference, vins, preferenceName } = payload;
    const alertType = preference.alertType;

    const isSecurityAlert = alertType === ALERT_TYPE.security;
    return {
      recipients: await userService.getUsersById(recipientIDs),
      correlationID: id!.toString(),
      startTime: isSecurityAlert ? moment(preference.timeframe.startTime, 'HH:mm:ss').format('h:mma') : '12:00am',
      endTime: isSecurityAlert ? moment(preference.timeframe.endTime, 'HH:mm:ss').format('h:mma') : '11:59pm',
      timeZone: preference.timeframe.zone,
      days: preference.timeframe.days,
      alertType: preference.alertType,
      selected: Object.assign({}, ...vins.map((item) => ({ [item]: { vin: item } }))),
      selectedText: false, // todo: get it from backend
      selectedEmail: true, // todo: get it from backend
      numberOfVehicles: vins.length,
      scheduleName: preferenceName,
      companyId: payload.companyGuid,
      requestId: payload.requestId,
      preferenceId: payload.preference.id,
      timeframeId: payload.preference.timeframe.id
    };
  }

  private convertToJSON (payload: CardInfo) : NotificationDTO {
    const { selected, scheduleName, recipients, alertType, days, startTime, endTime, timeZone, requestId, companyId, preferenceId, timeframeId } = payload;

    // TODO: cleanup temp fixes when backend is ready
    const isSecurityAlert = alertType === ALERT_TYPE.security;
    return {
      preferenceName: scheduleName,
      recipientIDs: recipients.map((item: Recipient) => item.id),
      requestId: requestId,
      companyGuid: companyId,
      vins: Object.values(selected).map(s => s.vin),
      preference: {
        id: preferenceId,
        alertCategory: 'VEHICLE SECURITY',
        alertType,
        timeframe: {
          id: timeframeId,
          days: isSecurityAlert ? days : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
          startTime: startTime,
          endTime: endTime,
          zone: timeZone
        }
      },
      ...(payload.correlationID && { id: parseInt(payload.correlationID) })
    };
  }
}
export default new NotificationService();
export { NotificationService, NotificationDTO };
