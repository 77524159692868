<template>
  <fcs-customizable-dialog
    :dialog-title="$t('vehicleSecurity.titles.getLocation')"
    ref="location-modal"
    no-close-on-esc
    no-close-on-backdrop
    cancelButtonVisible
    :cancelButtonText="$t('global.cancel')"
    :cancelButtonHandler="closeModal"
    :closeButtonHandler="closeModal"
    submitButtonVisible
    submitButtonType="primary"
    :submitButtonText="$t('vehicleSecurity.titles.getLocation')"
    :submitButtonHandler="handleLocationFinish"
    appendToBody>
    <VehiclePaginatedTable />
  </fcs-customizable-dialog>
</template>
<script>

import { mapActions, mapGetters, mapMutations } from 'vuex/dist/vuex.mjs';
import VehiclePaginatedTable from '@/components/VehiclePaginatedTable';
import { ALERT_TYPE } from '@/constants';
import { i18n } from '@/i18n';
import { CommandType } from '@/services/CommandService';

export default {
  name: 'GetLocationModal',
  components: { VehiclePaginatedTable },
  computed: {
    ...mapGetters(['getCheckedItem'])
  },
  methods: {
    ...mapMutations(['toggleModal']),
    ...mapActions(['clearWizard', 'sendCommand', 'assignAlertType', 'sendLocation']),
    closeModal () {
      this.$refs['location-modal'].hideModal();
      this.toggleModal();
    },
    async handleLocationFinish () {
      const message = `${i18n.t('global.locationConfirmation')}`;
      const command = CommandType.Location;

      this.$store.commit('locationClassAlert', 'fcs-alert-secondary');
      this.$emit('command', message);

      try {
        await this.sendCommand(command)
          .then((response) => {
            if (response.status === 204) {
              this.$store.commit('locationClassAlert', 'fcs-alert-secondary');
              this.$store.commit('displayLocationButton', false);
              this.$store.commit('isLocNotify', false);
              this.$store.commit('locationClass', 'd-none');
              // Above two lines translate to show alert. The next one is to the LocationController.
              console.log('Going to the send location now');
              this.sendLocation()
                .then((resp) => {
                  if (resp.status === 200) {
                    this.$store.commit('locationClassAlert', 'd-none');
                    this.$store.commit('displayLocationButton', true);
                    this.$store.commit('isLocNotify', true);
                    this.$store.commit('locationClass', '');
                    this.$store.commit('latitude', resp.data.latitude);
                    this.$store.commit('longitude', resp.data.longitude);
                    this.$store.commit('locationCordinates', '' + this.$store.state.latitude + ', ' + this.$store.state.longitude);
                  } else {
                    console.log(resp.status);
                  }
                })
                .catch((error) => {
                  this.$store.commit('locationClassAlert', 'd-none');
                  this.$store.commit('displayLocationButton', true);
                  this.$store.commit('isLocNotify', true);
                  this.$store.commit('locationClass', '');
                  this.$store.commit('latitude', '0');
                  this.$store.commit('longitude', '0');
                  this.$store.commit('locationCordinates', 'Error retrieving location, please try again');
                  console.log(`Error sending the second command:  ${error} `);
                });
            }
          });
      } catch (err) {
        this.$store.commit('locationClassAlert', 'd-none');
        this.$store.commit('displayLocationButton', true);
        this.$store.commit('isLocNotify', true);
        this.$store.commit('locationClass', '');
        this.$store.commit('latitude', '0');
        this.$store.commit('longitude', '0');
        this.$store.commit('locationCordinates', 'Error retrieving location, please try again');
        console.log(`error sending command: ${err}`);
      }
      this.closeModal();
      this.$emit('command', message);
    }
  },
  mounted () {
    this.clearWizard();
    this.assignAlertType(ALERT_TYPE.onDemandLocation);
  }
};
</script>
