<template>
  <fcs-row class="mt-4"  align-h="center" align-self="center">
    <fcs-col class="d-flex flex-column">
      {{ $t('createSchedule.' + scheduleType + '.selectDay') }}
      <div class="mt-2">
        <div class="d-sm-inline-flex d-md-none d-lg-none d-xl-none ">
          <fcs-toggle-button-group
            :secondary="true"
            v-model="selected"
            :options="abvOptions"
            :compact="true"
            name="buttons-1"
            data-qa="selectDays"
          ></fcs-toggle-button-group>
        </div>
        <div class="d-sm-none d-md-flex d-lg-flex d-xl-flex">
          <fcs-toggle-button-group
            :secondary="true"
            v-model="selected"
            :options="options"
            name="buttons-1"
            data-qa="selectDays"
          ></fcs-toggle-button-group>
        </div>
        <fcs-row>
          <fcs-col class="mt-4">
            {{ $t('createSchedule.' + scheduleType + '.selectTime') }}
          </fcs-col>
        </fcs-row>
        <fcs-row align-v="center">
          <div class="ml-3">
            <fcs-time-picker
              class="mt-3"
              v-model="startTime"
              @onInput="updateStartTime"
              data-qa="startTimePicker"
              ref="startTimePicker"
              :labelText="$t('global.createSchedule.startTime')"
              :errorState="false"
              :disabled="false"
              :clear="false"
              helperText=""
              hoursMins
              initialDropdownHour="00"
              initialDropdownMin="00"
              :hoursMinsInput="true"
              twelveHour
              :value="presetStartTime"
            />
          </div>
          <div>
            <p class="mt-0.5 mr-2 ml-4" style="margin-top: 50px"> {{ $t('global.to') }} </p>
          </div>
          <div class="ml-2">
            <fcs-time-picker
              class="mt-3"
              style="margin-left: 8px"
              v-model="endTime"
              @onInput="updateEndTime"
              data-qa="endTimePicker"
              ref="endTimePicker"
              :labelText="$t('global.createSchedule.endTime')"
              :errorState="false"
              :disabled="false"
              :clear="false"
              helperText=""
              hoursMins
              initialDropdownHour="00"
              initialDropdownMin="00"
              :hoursMinsInput="true"
              twelveHour
              :value="presetEndTime"
            />
          </div>
          <div>
            <TimezoneSelector class="TimezoneSelector pt-2" data-qa="TimezoneSelector" />
          </div>
        </fcs-row>
      </div>
      <hr>
      <fcs-row class="pr-3 pl-3 mobile-friendly-buttons" align-h="end">
        <fcs-button class="mr-4" v-if="activeIndex>0" data-qa="previous-button" variant="outline-primary" @click="handlePrevClick()">{{ $t('global.previous') }}</fcs-button>
        <fcs-button v-if="!isEditWindow" class="stacked-buttons" :disabled="!isComplete()" data-qa="next-button" variant="primary" @click="handleNextClick()"> {{ $t('global.next') }} </fcs-button>
        <fcs-button v-if="isEditWindow" class="stacked-buttons" :disabled="!isComplete()" data-qa="finish-button" variant="primary" @click="update()"> {{ $t('global.finish') }} </fcs-button>
      </fcs-row>
      <fcs-row>
      </fcs-row>
    </fcs-col>
  </fcs-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TimezoneSelector from '@/components/common/TimezoneSelector';
import { i18n } from '@/i18n';

export default {
  name: 'ChooseRecurringSchedule',
  components: { TimezoneSelector },
  props: {
    isEditWindow: Boolean,
    card: Object,
    scheduleType: String
  },
  data () {
    return {
      selected: this.$store.state.selected,
      isValidated: false,
      isInhibitRangeValid: true,
      abvOptions: [
        { label: `${i18n.t('global.weekdays.abv.sunday')}`, value: 'sunday' },
        { label: `${i18n.t('global.weekdays.abv.monday')}`, value: 'monday' },
        { label: `${i18n.t('global.weekdays.abv.tuesday')}`, value: 'tuesday' },
        { label: `${i18n.t('global.weekdays.abv.wednesday')}`, value: 'wednesday' },
        { label: `${i18n.t('global.weekdays.abv.thursday')}`, value: 'thursday' },
        { label: `${i18n.t('global.weekdays.abv.friday')}`, value: 'friday' },
        { label: `${i18n.t('global.weekdays.abv.saturday')}`, value: 'saturday' }
      ],
      options: [
        { label: `${i18n.t('global.weekdays.sunday')}`, value: 'sunday' },
        { label: `${i18n.t('global.weekdays.monday')}`, value: 'monday' },
        { label: `${i18n.t('global.weekdays.tuesday')}`, value: 'tuesday' },
        { label: `${i18n.t('global.weekdays.wednesday')}`, value: 'wednesday' },
        { label: `${i18n.t('global.weekdays.thursday')}`, value: 'thursday' },
        { label: `${i18n.t('global.weekdays.friday')}`, value: 'friday' },
        { label: `${i18n.t('global.weekdays.saturday')}`, value: 'saturday' }
      ],
      presetStartTime: undefined,
      presetEndTime: undefined,
      originalCard: undefined
    };
  },
  methods: {
    ...mapActions(['updateSchedule', 'saveStartTime', 'saveEndTime']),
    displayInitialSetTimes () {
      if (this.startTime.hour !== undefined) {
        this.presetStartTime = {
          hour: this.startTime.hour,
          min: this.startTime.min,
          amPm: this.startTime.amPm
        };
      }
      if (this.endTime.hour !== undefined) {
        this.presetEndTime = {
          hour: this.endTime.hour,
          min: this.endTime.min,
          amPm: this.endTime.amPm
        };
      }
    },
    updateStartTime (text) {
      this.saveStartTime(text);
      this.isComplete();
    },
    updateEndTime (text) {
      this.saveEndTime(text);
      this.isComplete();
    },
    isComplete () {
      return this.startTime.hour > 0 && this.endTime.hour > 0 && this.selected.length > 0 && this.fsiTimeZone !== '';
    },
    handleNextClick (e) {
      this.$store.commit('incrementProgressbarIndex');
      this.$store.commit('saveSchedule', { startTime: this.startTime, endTime: this.endTime, selected: this.selected, timeZone: this.fsiTimeZone });
    },
    handlePrevClick (e) {
      this.$store.commit('decrementProgressbarIndex');
      this.$store.commit('saveSchedule', { startTime: this.startTime, endTime: this.endTime, selected: this.selected, timeZone: this.fsiTimeZone });
    },
    async update () {
      await this.updateSchedule({ originalCardInfo: this.originalCard, updatedDays: this.selected, updatedStartTime: this.startTime, updatedEndTime: this.endTime, updatedTimeZone: this.fsiTimeZone });
      const message = this.isErrorStatus ? i18n.t('global.editErrorMessage') : i18n.t('global.editConfirmation', { name: this.card.scheduleName });
      return this.$router.push({ name: 'Home', params: { message } }).catch(() => ({}));
    }
  },
  computed: {
    ...mapState(['activeIndex', 'fsiTimeZone']),
    ...mapGetters(['isErrorStatus']),
    startTime: {
      get () {
        return this.$store.state.startTime;
      },
      set (newValue) {
        this.saveStartTime(newValue);
      }
    },
    endTime: {
      get () {
        return this.$store.state.endTime;
      },
      set (newValue) {
        this.saveEndTime(newValue);
      }
    }
  },
  mounted () {
    this.displayInitialSetTimes();
    if (this.card !== undefined) {
      this.originalCard = JSON.parse(JSON.stringify(this.card));
    }
  }
};
</script>

<style scoped  lang="scss">

.options{
  color: #8A8A8A;
}
a.btn.disabled {
  background-color: var(--black-700) !important;
  border: none;
}
.outline-button {
  background-color: transparent !important;
  border: 0.1rem solid var(--number-background) !important;
  color: var(--number-background) !important;
  &:hover {
    background: rgb(0, 166, 186, 0.1) !important;
    box-shadow: none !important;
  }
}
p.card-text {
  color: var(--black-700);
  margin-bottom: 3px;
}
h4.card-title {
  font-weight: 600;
}
.to{
  display: inline;
  text-transform: lowercase;
}

.dropdown-toggle {
  width: 500px !important;
}

.TimezoneSelector {
  padding-top: 1.15%;
  padding-left: 11%;
}

@media only screen and (max-width: 640px) {
  .mobile-friendly-buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .stacked-buttons {
    margin-right: 1.5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}
</style>
