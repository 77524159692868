import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enUS from '@/locales/en-US.json';

Vue.use(VueI18n);

const defaultLang = 'en-US';

export const i18n = new VueI18n({
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages: {
    'en-US': enUS
  }
});

export const loadedLanguages = ['en-US'];

// TODO: hookup i18n and see where to pull locale from
// see https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
export function setI18nLanguage (lang: string) {
  i18n.locale = lang;
  const HTMLElement = document.querySelector('html')!;
  HTMLElement.setAttribute('lang', lang);
  return lang;
}

export async function loadLanguageAsync (lang: string) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(
        /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`
      )
        .then((messages) => {
          i18n.setLocaleMessage(lang, messages.default);
          loadedLanguages.push(lang);
          return setI18nLanguage(lang);
        })
        .catch((error) => {
          console.log(`invalid language given: ${error}`);
          return setI18nLanguage(defaultLang);
        });
    }
    return Promise.resolve(setI18nLanguage(defaultLang));
  }
  return Promise.resolve(setI18nLanguage(lang));
}
