const getters = {
  isImpactAlertEnabled (state: any) {
    return state.featureFlags.impactAlertEnabled;
  },
  isInclineAlertEnabled (state: any) {
    return state.featureFlags.inclineAlertEnabled;
  },
  isGetLocationEnabled (state: any) {
    return state.featureFlags.getLocationEnabled;
  },
  isSyncOverrideEnabled (state: any) {
    return state.featureFlags.syncOverride;
  }
};

export { getters };
