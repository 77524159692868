<template>
  <fcs-customizable-dialog
    :dialog-title="$t('vehicleSecurity.titles.triggerAlarm')"
    ref="alarm-modal"
    no-close-on-esc
    no-close-on-backdrop
    cancelButtonVisible
    :cancelButtonText="$t('global.cancel')"
    :cancelButtonHandler="closeModal"
    submitButtonVisible
    submitButtonType="primary"
    :submitButtonText="$t('vehicleSecurity.titles.triggerAlarm')"
    :submitButtonHandler="handleAlarmFinish"
    appendToBody>
    <VehiclePaginatedTable />
  </fcs-customizable-dialog>
</template>
<script>

import { mapActions, mapGetters, mapMutations } from 'vuex/dist/vuex.mjs';
import VehiclePaginatedTable from '@/components/VehiclePaginatedTable';
import { ALERT_TYPE } from '@/constants';
import { i18n } from '@/i18n';
import { CommandType } from '@/services/CommandService';

export default {
  name: 'TriggerAlarmModal',
  components: { VehiclePaginatedTable },
  computed: {
    ...mapGetters(['getCheckedItem'])
  },
  methods: {
    ...mapMutations(['toggleModal']),
    ...mapActions(['clearWizard', 'sendCommand', 'assignAlertType']),
    closeModal () {
      this.$refs['alarm-modal'].hideModal();
      this.toggleModal();
    },
    async handleAlarmFinish () {
      let message = `${i18n.t('global.alarmSending')}`;
      let success = true;
      const command = CommandType.Alarm;
      try {
        await this.sendCommand(command);
      } catch (err) {
        message = `${i18n.t('global.alarmFail')}`;
        success = false;
        this.$store.commit('locationClassAlert', 'd-none');
        this.$store.commit('displayLocationButton', true);
        this.$store.commit('isLocNotify', true);
        this.$store.commit('locationClass', '');
        this.$store.commit('latitude', '0');
        this.$store.commit('longitude', '0');
        this.$store.commit('locationCordinates', 'Error retrieving location, please try again');
        console.log(`error sending command: ${err}`);
      } finally {
        this.closeModal();
        this.$emit('command', message, success);
      }
    }
  },
  mounted () {
    this.clearWizard();
    this.assignAlertType(ALERT_TYPE.alarm);
  }
};
</script>
