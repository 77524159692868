<template>
  <fcs-container fluid class="scrollableContainer">
    <fcs-row>
      <fcs-alert ref="no-fsi-enrolled-vehicles-alert"
                 styleClass="fcs-alert-primary"
                 :content="$t('inhibit.noFsiEnrolledVehiclesBanner')"
                 :auto-disappear="false" />
    </fcs-row>
    <fcs-row>
      <fcs-alert ref="fsi-ad-hoc-inhibit-succeeded"
                 icon="iconcheckmark"
                 styleClass="fcs-alert-success"
                 :content="$t('inhibit.adhocInhibitVehicleSuccess')"
                 :auto-disappear="false" />
    </fcs-row>
    <fcs-row>
      <fcs-alert ref="fsi-ad-hoc-inhibit-failed"
                 icon="iconalert"
                 styleClass="fcs-alert-danger"
                 :content="$t('inhibit.adhocInhibitVehicleFailure')"
                 :auto-disappear="false" />
    </fcs-row>
    <fcs-row>
      <fcs-alert ref="fsi-adhoc-deinhibit-succeeded"
                 icon="iconcheckmark"
                 styleClass="fcs-alert-success"
                 :content="$t('inhibit.adhocDeInhibitVehicleSuccess')"
                 :auto-disappear="false" />
    </fcs-row>
    <fcs-row>
      <fcs-alert ref="fsi-adhoc-deinhibit-failed"
                 icon="iconalert"
                 styleClass="fcs-alert-danger"
                 :content="$t('inhibit.adhocDeInhibitVehicleFailure')"
                 :auto-disappear="false" />
    </fcs-row>
    <fcs-row>
      <banner ref="updateScheduleName" />
    </fcs-row>
    <fcs-row>
      <banner ref="deleteScheduleStatus" />
    </fcs-row>
    <fcs-row>
      <fcs-alert ref="fsi-schedule-inhibit-failed"
                 icon="iconcheckmark"
                 styleClass="fcs-alert-danger"
                 :content="$t('inhibit.scheduleCreationFailure')"
                 :auto-disappear="false" />
    </fcs-row>
    <fcs-row>
      <fcs-alert ref="fsi-schedule-inhibit-succeeded"
                 icon="iconalert"
                 styleClass="fcs-alert-success"
                 :content="$t('inhibit.startScheduleCreationSuccess')"
                 :auto-disappear="false" />
    </fcs-row>
    <fcs-row class="mt-1">
      <fcs-alert ref="updateVehicles"
                 :styleClass="this.responseStyle"
                 :content="this.message"
                 :auto-disappear="false" />
    </fcs-row>
  <fcs-container align-v="center">
    <fcs-row class="mt-1">
      <fcs-col class="mt-1 col-md-3" style="padding-top: 15px;">
        <h2 class="mt-1" style="color: #002F6C;"> {{ $t('inhibit.inhibitSchedules') }}</h2>
      </fcs-col>
      <fcs-col class="adHocInhibitButtons">
        <div>
          <fcs-button
            @click="handleSelectAdHocInhibit('START')"
            v-b-modal.triggerInhibitModal
            data-qa="start-inhibit-button"
            class="mt-3"
            size="md"
            :variant="!hasFsiEnrolledVehicles ? 'primary' : 'outline-primary'"
            :disabled="!hasFsiEnrolledVehicles">
            {{ $t('inhibit.buttons.startInhibit') }}
          </fcs-button>
          <fcs-button
            class="mt-3"
            size="md"
            @click="handleAdhocDeInhibit"
            v-b-modal.triggerDeInhibitModal
            :variant="!hasFsiEnrolledVehicles ? 'primary' : 'outline-primary'"
            data-qa="adHoc-deInhibit-button"
            :disabled="!hasFsiEnrolledVehicles">
            {{ $t('inhibit.buttons.deInhibit') }}
          </fcs-button>
        </div>
      </fcs-col>
    </fcs-row>
    <fcs-row>
      <fcs-col style="align-self: center;" class="col-md-12"><hr></fcs-col>
    </fcs-row>
    <fcs-row style=" padding-left: 0.5%;">
      <fcs-col class="col-1" style="max-width: 20px"><b-icon icon="exclamation-triangle" scale="1.5" style="color:gold;" class="mr-3"></b-icon></fcs-col>
      <fcs-col><p>{{ $t('inhibit.connectivityWarning') }}</p></fcs-col>
    </fcs-row>
    <fcs-row class="d-flex">
      <fcs-col>
        <div class="mobile-friendly">
          <p class="title">{{ $t('inhibit.startInhibitSchedule') }}</p>
          <p class="description">{{ $t('inhibit.start.description') }}</p>
          <fcs-button
            variant="primary"
            data-qa="schedule-start-inhibit"
            color="#00a6ba"
            @click="openScheduleInhibit('START')"
            :disabled="!hasFsiEnrolledVehicles">
            {{ $t('global.create') }}
          </fcs-button>
          <existing-schedules inhibit-type="INHIBIT_CRANK"/>
        </div>
      </fcs-col>
    </fcs-row>
    <AdHocInhibitModal ref="adhocInhibitModal" v-bind:inhibit-type="inhibitType"/>
    <AdHocDeInhibitModal ref="adhocDeinhibitModal" />
    <AdHocWarnModal ref="adhoc-warn-modal"/>
  </fcs-container>
  </fcs-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ExistingSchedules from '@/fleetstartinhibit/components/ExistingSchedules';
import AdHocInhibitModal from '@/fleetstartinhibit/components/AdHocInhibitModal';
import AdHocWarnModal from '@/fleetstartinhibit/components/AdHocWarnModal';
import Banner from '@/components/common/Banner';
import AdHocDeInhibitModal from '@/fleetstartinhibit/components/AdHocDeInhibitModal';

export default {
  name: 'Inhibit',
  components: {
    AdHocDeInhibitModal,
    Banner,
    AdHocInhibitModal,
    AdHocWarnModal,
    ExistingSchedules
  },
  async mounted () {
    this.getAllExistingSchedules();
    this.$store.dispatch('vehicles/checkIfUserHasFsiVehicles');
    this.$store.dispatch('vehicles/fetchUserPreferences');
    if (!this.hasFsiEnrolledVehicles) {
      this.showNoFsiEnrolledVehiclesAlert();
    }
    if (this.displayScheduleInhibitAlerts) {
      this.showScheduleInhibitAlert();
    }
    if (this.responseStatus != null) {
      this.$refs.updateVehicles.showAlert();
    }
    this.getScheduleNames();
  },
  provide: function () {
    return {
      showAdHocInhibitSuccess: this.showAdHocInhibitSuccessAlert,
      showAdHocInhibitFailure: this.showAdHocInhibitFailureAlert,
      showAdhocDeInhibitFailure: this.showAdHocDeInhibitFailureAlert,
      showAdhocDeInhibitSuccess: this.showAdHocDeInhibitSuccessAlert
    };
  },
  data () {
    return {
      showButton: process.env.VUE_APP_HIDE_FEATURE === 'false',
      inhibitType: 'START',
      showAdHocModal: false,
      showAdHocDeInhibitModal: false
    };
  },
  watch: {
    hasFsiEnrolledVehicles: function (currVal) {
      if (currVal) {
        this.closeNoFsiEnrolledVehiclesAlert();
      } else {
        this.showNoFsiEnrolledVehiclesAlert();
      }
    }
  },
  props: {
    buttonName: String,
    modalType: String,
    displayScheduleInhibitAlerts: Boolean,
    displayAdHocInhibitAlerts: Boolean,
    responseStatus: {
      type: Number,
      default: null
    },
    responseStyle: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    inhibitAlertType: {
      type: String,
      default: '',
      required: false
    }
  },
  emits: ['command'],
  methods: {
    ...mapMutations(['toggleModal']),
    ...mapActions(['clearWizard', 'sendCommand']),
    ...mapActions('vehicles', [
      'inhibitFirst', 'getAllExistingSchedules'
    ]),
    ...mapGetters('vehicles', ['getScheduleNames']),
    showNoFsiEnrolledVehiclesAlert () {
      const alert = this.$refs['no-fsi-enrolled-vehicles-alert'];
      if (alert) {
        alert.showAlert();
      }
    },
    closeNoFsiEnrolledVehiclesAlert () {
      const alert = this.$refs['no-fsi-enrolled-vehicles-alert'];
      if (alert) {
        alert.closeAlert();
      }
    },
    showAdHocInhibitSuccessAlert () {
      const alert = this.$refs['fsi-ad-hoc-inhibit-succeeded'];
      if (alert) {
        alert.showAlert();
      }
    },
    showAdHocInhibitFailureAlert () {
      const alert = this.$refs['fsi-ad-hoc-inhibit-failed'];
      if (alert) {
        alert.showAlert();
      }
    },
    showAdHocDeInhibitSuccessAlert () {
      const alert = this.$refs['fsi-adhoc-deinhibit-succeeded'];
      if (alert) {
        alert.showAlert();
      }
    },
    showAdHocDeInhibitFailureAlert () {
      const alert = this.$refs['fsi-adhoc-deinhibit-failed'];
      if (alert) {
        alert.showAlert();
      }
    },
    showScheduleInhibitAlert () {
      let alert;
      if (this.isScheduleInhibitFailure) {
        alert = this.$refs['fsi-schedule-inhibit-failed'];
        if (alert) {
          alert.showAlert();
        }
      } else {
        alert = this.$refs['fsi-schedule-inhibit-succeeded'];
        alert.showAlert();
      }
    },
    handleAdhocDeInhibit () {
      this.closeAllAlerts();
      this.$refs.adhocDeinhibitModal.setFilter('On');
      this.$refs.adhocDeinhibitModal.$refs['deInhibit-Modal'].showModal();
    },
    handleSelectAdHocInhibit (inhibitType) {
      this.$store.dispatch('clearSupportNumber');
      this.inhibitType = inhibitType;
      this.closeAllAlerts();
      this.$refs.adhocInhibitModal.setFilter('Off');
      this.$refs['adhoc-warn-modal'].$refs['adhoc-warn-modal'].showModal();
      this.toggleModal();
    },
    closeAllAlerts () {
      const adHocDeInhibitSuccessAlert = this.$refs['fsi-adhoc-deinhibit-succeeded'];
      if (adHocDeInhibitSuccessAlert) {
        adHocDeInhibitSuccessAlert.closeAlert();
      }
      const adHocDeInhibitFailureAlert = this.$refs['fsi-adhoc-deinhibit-failed'];
      if (adHocDeInhibitFailureAlert) {
        adHocDeInhibitFailureAlert.closeAlert();
      }

      const adHocInhibitFailureAlert = this.$refs['fsi-ad-hoc-inhibit-failed'];
      if (adHocInhibitFailureAlert) {
        adHocInhibitFailureAlert.closeAlert();
      }
      const adHocInhibitSuccessAlert = this.$refs['fsi-ad-hoc-inhibit-succeeded'];
      if (adHocInhibitSuccessAlert) {
        adHocInhibitSuccessAlert.closeAlert();
      }
    },
    openScheduleInhibit (type) {
      if (type === 'START') {
        this.$router.push({ name: 'ScheduleInhibit', params: { inhibitType: 'START', title: this.$t('inhibit.start.headerTitle') } });
      }
      if (type === 'DRIVE') {
        this.$router.push({ name: 'ScheduleInhibit', params: { inhibitType: 'DRIVE', title: this.$t('inhibit.drive.headerTitle') } });
      }
    }
  },
  created () {
    this.clearWizard();
  },
  computed: {
    ...mapState({ getVins: state => state.vehicles.selection }),
    ...mapGetters(['isValidSelection', 'getCheckedItem']),
    ...mapGetters('vehicles', [
      'getFsiVehicles',
      'getScheduledPostedResponse',
      'getAdHocInhibitResponse',
      'getAdhocDeInhibitResponse',
      'hasFsiEnrolledVehicles'
    ]),
    isScheduleInhibitFailure () {
      const vins = this.getScheduledPostedResponse ? this.getScheduledPostedResponse.vins : [];
      return vins ? vins.length === 0 : false;
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 640px) {
  .mobile-friendly {
    display: flex;
    flex-direction: column;
  }
}

.header{
  text-transform: uppercase;
  font-weight: normal;
  color: #002F6C;
  letter-spacing: 1.8px;
  font-size: 24px;
  height:36px;
}
.dropDownButtons {
  text-transform: uppercase;
  font-weight: normal;
  color: #002F6C;
  letter-spacing: 1.8px;
  font-size: 24px;
  width: 212px;
  height: auto;
  margin-left: 4px;
  flex-wrap: wrap;
}

.text {
  background-color: white !important;
}

::v-deep .fcs-alert-general .alert {
  margin-bottom: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}

.scrollableContainer {
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-menu.show {
  display: block;
  width: 80px !important;
}

.title{
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1.0px;
  color: #18191E;
  margin-bottom: 0.1rem;
  font-weight: 400;
}

.description {
  color: var(--black-500);
  margin-bottom: 2px;
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 16px;
}

.adHocInhibitButtons {
  .btn {
    margin-right:15px;
    height: fit-content !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
