<template>
  <fcs-container fluid class="body overflow-auto vh-100 d-flex flex-column" align-v="center">
    <Header class="mt-3" :title="$t('securityAlert.title')"></Header>
    <div class="mt-2"></div>
    <fcs-row class="step1" v-if="activeIndex===0">
      <fcs-col class="mx-auto" lg="9" xl="10">
        <fcs-progress-tracker
          :currentValue="$t('global.progressBar.setParam')"
          :steps="$t('global.progressBarElements')"
          :all-steps-completed="false"
          :small-version="false"
          class="progressTrackerStyles"
        />
      </fcs-col>
      <fcs-col class="mx-auto" lg="8">
        <SetParameters :activeIndex='0' :cardTitle="$t('securityAlert.cardInfo.setParams.main')"></SetParameters>
      </fcs-col>
    </fcs-row>
    <fcs-row class="step2" v-if="activeIndex===1">
      <fcs-col class="mx-auto" lg="9" xl="10">
        <fcs-progress-tracker
          :currentValue="$t('global.progressBar.createSchedule')"
          :steps="$t('global.progressBarElements')"
          :all-steps-completed="false"
          :small-version="false"
          class="progressTrackerStyles"
        />
      </fcs-col>
      <fcs-col class="mx-auto" lg="8">
         <ChooseRecurringSchedule schedule-type="securityAlert"></ChooseRecurringSchedule>
      </fcs-col>
    </fcs-row>
    <div v-if="activeIndex===2">
      <fcs-row class="step3">
        <fcs-col class="mx-auto" lg="9" xl="10">
          <fcs-progress-tracker
            :currentValue="$t('global.progressBar.assignVehicle')"
            :steps="$t('global.progressBarElements')"
            :all-steps-completed="false"
            :small-version="false"
            class="progressTrackerStyles"
          />
        </fcs-col>
      </fcs-row>
      <AssignVehicle :cardTitle="$t('global.assignVehicles')" ></AssignVehicle>
    </div>
  </fcs-container>
</template>

<script>
import SetParameters from '@/components/common/SetParameters';
import Header from '@/components/common/Header';
import { mapActions, mapState } from 'vuex';
import AssignVehicle from '@/components/common/AssignVehicle';
import ChooseRecurringSchedule from '@/components/common/ChooseRecurringSchedule';

export default {
  name: 'CreateSecurityAlert',
  components: { SetParameters, Header, ChooseRecurringSchedule, AssignVehicle },
  mounted () {
    this.clearWizard();
    this.assignAlertType(this.$route.params.alertType);
  },
  methods: {
    ...mapActions({ clearWizard: 'clearWizard', assignAlertType: 'assignAlertType' })
  },
  computed: {
    ...mapState(['activeIndex'])
  }
};
</script>

<style scoped lang="scss">
@import '../assets/css/vue-multiselect.min.css';
</style>
<style scoped lang="scss">
.progressTrackerStyles {
  font-size: 12px;
}

.body{
  overflow-x: hidden !important;
}

h3 {
  font-weight: bold;
}
</style>
