<template>
  <div>
    <fcs-row class="filter-row">
      <SearchField class="mt-2 ml-3" @change="setQueryAndUpdate"/>
      <fcs-col class="mobile-filter">
        <fcs-table-filter-bar
          v-for="filterBox in filterBoxes"
          v-bind:key="filterBox.key"
          :columnLabel="filterBox.label"
          :columnName="filterBox.key"
          :filterOptions="filterBox.selectedFilterItems"
          :all-options-text="filterBox.allSelectedLabel"
          @input="filterInputEvent($event)">
        </fcs-table-filter-bar>
      </fcs-col>
    </fcs-row>
  <div>
    <fcs-table responsive selectable
               thead-class="table-header"
               tbody-tr-class="cell-styling"
               :fields = fields
               :per-page="pageRequestSize"
               :busy="loading"
               :items="items"
               :select-mode="selectMode"
               :selected-variant="''">
      <template v-slot:head(select)>
        <fcs-form-checkbox
             :checked="pageSelected && !loading"
             data-qa="select-all-checkbox"
             @change="togglePageSelected">
        </fcs-form-checkbox>

      </template>
      <template  v-slot:cell(select)="data">
        <fcs-form-checkbox v-if="!isAModal"
                           :checked="isSelected(data.item.vin)"
                           :data-qa="`vehicle-select-${data.item.vin}`"
                           class="py-0 my-auto"
                           hide-details
                           primary
                           @change="toggleSelect(data.item)"
        />
        <fcs-form-checkbox v-if="isAModal"
                           @input.native="singleSelect(data.item, $event)"
                           :data-qa="`vehicle-select-${data.item.vin}`"
                           class="py-0 my-auto singleSelect"
                           hide-details
                           primary
        />

      </template>
      <template v-slot:cell(name)="data">
        <span class="vehicle-name">{{data.item.name}}</span><br>
        <span class="vehicle-details">{{data.item.year}} </span>
        <span class="vehicle-details">{{ data.item.make}} </span>
        <span class="vehicle-details">{{data.item.model}}</span>
      </template>
      <template v-slot:cell(alert)="data">
        {{ displayAssignedAlerts(data.item.vin) }}
      </template>
      <template #table-busy>
        <div class="text-center text-dark my-2">
          <fcs-spinner class="align-middle mr-2"></fcs-spinner>
          <strong>{{ $t('global.loading') }} </strong>
        </div>
      </template>

    </fcs-table>
    <fcs-container>
      <fcs-row>
        <fcs-col class="desktopContainer">
          <span v-if="areAnySelected && !isAModal" class="areAnySelected" style="padding: 0.5rem;">{{ this.getSelectedLength }} Selected</span>
          <span :class="getModalClasses()" style="padding: 0.5rem;">{{ $t('vehicleList.total') }}: {{ totalResultSize }}</span>
          <fcs-pagination-size-selector
            style="padding: 0.5rem;"
            v-model="requestSize"
            data-qa="service-rule-vehicle-table-pagination-size"
            :option-text="$t('perPage')"
            @input="onPaginationSizeChange"/>
          <fcs-pagination
            style="padding: 0.5rem;"
            v-model="pageRequestPageNumber"
            :per-page="pageRequestSize"
            :total-rows="totalResultSize"
            data-qa="service-rule-vehicle-table-pagination-arrows"
            @change="onPaginationPageChange"/>
        </fcs-col>
      </fcs-row>
    </fcs-container>
    <fcs-container class="mobileContainer" v-if="!loading">
      <fcs-row v-if="!isAModal" class="selectedLength">{{ this.getSelectedLength }} Selected</fcs-row>
      <fcs-row class="paginationControls">
        <span :class="getModalClasses()">{{ $t('vehicleList.total') }}: {{ totalResultSize }}</span>
        <fcs-pagination-size-selector v-model="requestSize"
                                      data-qa="service-rule-vehicle-table-pagination-size"
                                      @input="onPaginationSizeChange"/>

        <fcs-pagination
          class="mobilePagination"
          v-model="pageRequestPageNumber"
          :per-page="pageRequestSize"
          size="md"
          :total-rows="totalResultSize"
          hide-goto-end-buttons
          data-qa="service-rule-vehicle-table-pagination-arrows"
          @change="onPaginationPageChange"/>
      </fcs-row>
    </fcs-container>
  </div>
  </div>
</template>
<script>
import { isEmpty, debounce } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { FcsTableFilterBar } from 'fcsui';
import SearchField from '@/components/common/SearchField';
import { i18n } from '@/i18n';

export default {
  name: 'VehiclePaginatedTable',
  components: {
    'fcs-table-filter-bar': FcsTableFilterBar, SearchField
  },
  data () {
    return {
      emDash: '—',
      selectedVehicle: {},
      selectMode: 'multi',
      selected: [],
      perPage: this.pageRequestSize,
      filterLoaded: false
    };
  },
  async mounted () {
    const pageDetails = {
      options: {}
    };
    this.changePageSize({ skipReload: true, ...pageDetails });
    this.fetchAvailableFilters().finally(() => {
      this.first(pageDetails);
      this.filterLoaded = true;
    });
    this.mapAssignedAlerts();
  },
  computed: {
    ...mapGetters('vehicles', [
      'getVehicles',
      'pageRequestSize',
      'loading',
      'loaded',
      'error',
      'pageSelected',
      'resultSize',
      'totalResultSize',
      'getSelectedLength',
      'getTotalPages',
      'pageRequestPageNumber',
      'isSelected',
      'errorMessage',
      'getAvailableVehicleFilters',
      'getVehicleFilters'
    ]),
    filterBoxes () {
      const availableFilters = this.getAvailableVehicleFilters;

      if (isEmpty(availableFilters)) {
        return [];
      } else {
        const models = availableFilters.models.map((item) => ({ key: item, label: item })).filter((e) => e.key !== null);
        const years = availableFilters.years.map((item) => ({ key: item, label: item })).filter((e) => e.key !== null);

        return [
          {
            key: 'models',
            label: i18n.t('vehicleList.headers.model'),
            selectedFilterItems: [{ options: models }]
          },
          {
            key: 'years',
            label: i18n.t('vehicleList.headers.year'),
            selectedFilterItems: [{ options: years }]
          }
        ];
      }
    },
    fields () {
      return this.isAModal ? [{ key: 'select' },
        { key: 'name', label: i18n.t('global.assignVehicleTable.header.vehicle'), sortable: true }]
        : [{ key: 'select' },
          { key: 'name', label: i18n.t('global.assignVehicleTable.header.vehicle'), sortable: true },
          { key: 'alert', label: i18n.t('global.assignVehicleTable.header.alert'), sortable: true }];
    },
    requestSize: {
      set (size) {
        this.$store.commit('vehicles/CHANGE_PAGE_SIZE', { size });
      },
      get () {
        return this.pageRequestSize;
      }
    },
    areAnySelected () {
      return this.getSelectedLength > 0;
    },
    isAModal () {
      return this.$store.state.displayModal;
    },
    items () {
      return this.getVehicles;
    }
  },
  methods: {
    getModalClasses () {
      if (this.isAModal) {
        return 'modal';
      } else {
        return 'mobile';
      }
    },
    filterInputEvent (e) {
      if (this.filterLoaded) {
        this.debounceFilterSelected(e);
      }
    },
    debounceFilterSelected: debounce(function (e) {
      this.updateFilter(e);
    }, 1500),

    setQueryAndUpdate (query) {
      this.setVehicleSearchQuery(query.trim()).then(() => {
        this.reset();
      });
    },

    updateFilter (e) {
      const key = e.filteredColumnName;
      const value = e.selectedFilterColumnOptions.map((item) => item.key);

      this.setVehicleFilterByKey({ key, data: value });

      if (!isEmpty(this.getVehicleFilters)) {
        this.reload({ filterChange: true });
      }
    },
    singleSelect (vehicle, event) {
      const isSelected = !isEmpty(this.selectedVehicle);
      if (isSelected && !event.target.checked) { // if one item already in the list, and user unchecks that checkbox
        this.selectedVehicle = {};
        this.removeSelected(vehicle.vin);
        return this.setCheckedItem({});
      }
      if (isSelected && event.target.checked) { // if one item already in the list, and user tries to select another checkbox
        this.selectedVehicle.event.target.checked = false; // set the previous checked state to false
        this.removeSelected(this.selectedVehicle.vin);
      }
      this.selectedVehicle = { id: vehicle.id, event, vin: vehicle.vin }; // add the checked vehicle to our list
      this.$store.commit('vehicleName', vehicle.make + ' ' + vehicle.year);
      this.setCheckedItem({ id: vehicle.id, name: vehicle.name, vin: vehicle.vin });
      this.addSelected(vehicle.vin);
    },
    validate () {
      return true;
    },
    toggleSelect (vehicle) {
      this.isSelected(vehicle.vin) ? this.removeSelected(vehicle.vin) : this.addSelected(vehicle.vin);
    },
    getPageDetails () {
      return {
        options: {}
      };
    },
    reloadVehicleTableData () {
      this.reload(this.getPageDetails());
    },
    onPaginationPageChange (value) {
      const payload = {
        pageRequested: value,
        ...this.getPageDetails()
      };
      this.goToPage(payload);
    },
    onPaginationSizeChange (value) {
      const payload = {
        size: value,
        ...this.getPageDetails()
      };
      this.changePageSize(payload);
    },
    async togglePageSelected () {
      if (this.pageSelected) {
        await this.clearSelected();
      } else {
        if (!this.isAModal) {
          await Promise.all(this.getVehicles.map((item) => this.addSelected(item.vin)));
        }
      }
    },
    displayAssignedAlerts (vin) {
      const mapping = this.vinAlertMap();
      if (vin in mapping) {
        return mapping[vin].map((item) => item.name).join(', ');
      }
      return i18n.t('global.none');
    },

    ...mapActions('vehicles', [
      'goToPage',
      'clearSelected',
      'addSelected',
      'removeSelected',
      'selectPage',
      'first',
      'changePageSize',
      'fetchAvailableFilters',
      'setVehicleFilterByKey',
      'reload',
      'setVehicleSearchQuery',
      'reset'
    ]),
    ...mapActions(['setCheckedItem', 'mapAssignedAlerts']),
    ...mapState(['vinAlertMap'])
  }

};
</script>

<style lang="scss" scoped>
td.fitwidth {
  width: 1px;
  white-space: nowrap;
}

.vehicle-paginated-table {

  .inner-table-row {
    flex: 1;
    height: 100%;
    overflow-y: auto;
  }
}

.footer-row {
  flex: 0 1 auto;
  min-height: 20px;
}

[disabled="disabled"] {
  pointer-events: none;
  color: grey;
}

.fcs-pagination button {
  min-width: 0;
}

table.fcs-backend-table {
  th {
    border-top: 0;
    border-bottom: 1px solid black;
  }
}

.select-all-checkbox-tableheader {
  padding: 12px !important;
}

.icon- {
  &Pending {
    color: #ffc400;
  }

  &Confirmation {
    color: green;
  }

  &Alert {
    color: red;
  }
}

.status-text {
  font-size: large;
}

.vehicle-name-text {
  color: #292E35 !important;
  font-size: large;
}

.vehicle-name-subtext {
  color: #8A8A8A !important;
}

@media only screen and (max-width: 640px) {
  .mobile {
    display: none;
  }

  .desktopContainer {
    display: none;
  }

  .desktopPagination {
    display: none;
  }

  .paginationControls {
    display: flex;
    justify-content: space-evenly;
  }

  .selectedLength {
    display: flex;
    justify-content: center;
    color: #007bff;
    font-weight: 550;
    letter-spacing: 1px;
    padding-bottom: 0.65rem;
  }

  .mobile-filter {
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 641px) {
  .modal {
    margin-left: 3rem;
  }

  .desktopContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }

  .areAnySelected {
    color: #007bff;
  }

  .mobileContainer {
    display: none;
  }

  .paginationControls {
    display: flex;
    justify-content: flex-end;
    justify-items: flex-end;
    margin-right: 1rem;
  }

  .mobilePagination {
    display: none;
  }

  .filter-row {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
  }
}

</style>
