<template>
  <fcs-customizable-dialog
    :dialogTitle="$t('inhibit.adhoc.' + this.inhibitType.toLowerCase() + 'InhibitModalTitle')"
    ref="inhibit-modal"
    id="triggerInhibitModal"
    no-close-on-esc
    no-close-on-backdrop
    :submit-disabled="!isSubmittable()"
    :submit-button-text="$t('inhibit.adhoc.inhibit')"
    :cancel-button-text="$t('inhibit.adhoc.cancel')"
    :submit-button-visible="true"
    :cancel-button-visible="true"
    :close-button-handler="closeModal"
    :cancel-button-handler="closeModal"
    :submit-button-handler="handleSubmit"
    :append-to-body="appendToBody"
    submitButtonType="primary"
  >
    <InhibitVehiclePaginatedTable
      :is-modal="true"
      :inhibit-status-filter="'Off'"
      :should-run-vehicles-refresh=false
    />
  </fcs-customizable-dialog>
</template>

<script>
import InhibitVehiclePaginatedTable from '@/fleetstartinhibit/components/InhibitVehiclePaginatedTable';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AdHocInhibitModal',
  components: {
    InhibitVehiclePaginatedTable
  },
  data () {
    return {
      isValidated: false,
      filtered: ''
    };
  },
  props: {
    inhibitType: {
      type: String,
      required: true
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  inject: [
    'showAdHocInhibitSuccess',
    'showAdHocInhibitFailure'
  ],
  methods: {
    ...mapActions([
      'clearSupportNumber'
    ]),
    ...mapActions('vehicles', [
      'resetVehicleFilter'
    ]),
    isSubmittable () {
      return this.getSelectedVins.length > 0;
    },
    convertInhibitType (inhibitType) {
      if (inhibitType === 'START') {
        return 'INHIBIT_CRANK';
      } else {
        return 'INHIBIT_DRIVING';
      }
    },
    async handleSubmit () {
      const applyAdHocInhibitRequest = {
        vins: this.getSelectedVins,
        inhibitType: this.convertInhibitType(this.inhibitType)
      };

      await this.$store.dispatch('vehicles/sendAdHocInhibitApplyRequest', applyAdHocInhibitRequest);

      const response = this.getAdHocInhibitResponse;
      if (response.isSuccess && !response.allFailed) {
        this.showAdHocInhibitSuccess();
      }
      if (!response.isSuccess || response.errors.length > 0) {
        this.showAdHocInhibitFailure();
      }

      await this.closeModal();
    },
    async closeModal () {
      this.setFilter('');
      this.$refs['inhibit-modal'].hideModal();
      this.$store.commit('toggleModal');
      this.clearSupportNumber();
      this.resetVehicleFilter();
      this.isValidated = false;
    },
    setFilter (filter) {
      this.filtered = filter;
    }
  },
  computed: {
    ...mapGetters([
      'getSupportNumber'
    ]),
    ...mapGetters('vehicles', [
      'getSelectedVins',
      'getAdHocInhibitResponse'
    ])
  }
};
</script>

<style scoped>
  #triggerInhibitModal___BV_modal_outer_ {
    z-index: 3000 !important;
  }
</style>
