import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

export default new Vue({
  methods: {
    getFlags () {
      return this.$http.get(`${process.env.VUE_APP_SMA_BASE_URL}/feature-flags`);
    }
  }
});
