<template>
  <fcs-row>
    <fcs-form-select
      v-model="selected"
      :options="options"
    />
  </fcs-row>
</template>

<script>
import { i18n } from '@/i18n';
import { mapGetters } from 'vuex';

export default {
  name: 'TimezoneSelector',
  data () {
    return {
      options: [
        { text: `${i18n.t('timeZones.easternTime')}`, value: 'US/Eastern' },
        { text: `${i18n.t('timeZones.centralTime')}`, value: 'US/Central' },
        { text: `${i18n.t('timeZones.mountainTime')}`, value: 'US/Mountain' },
        { text: `${i18n.t('timeZones.pacificTime')}`, value: 'US/Pacific' },
        { text: `${i18n.t('timeZones.alaskaTIme')}`, value: 'US/Alaska' },
        { text: `${i18n.t('timeZones.hawaiiTime')}`, value: 'US/Hawaii' },
        { text: `${i18n.t('timeZones.westernEuropeanTime')}`, value: 'WET' },
        { text: `${i18n.t('timeZones.centralEuropeanTIme')}`, value: 'CET' },
        { text: `${i18n.t('timeZones.easternEuropeanTIme')}`, value: 'EET' }
      ]
    };
  },
  computed: {
    ...mapGetters(['getFsiTimeZone']),
    selected: {
      get () {
        return this.getFsiTimeZone;
      },
      set (timeZone) {
        this.$store.commit('saveFsiTimeZone', timeZone);
      }
    }
  }
};
</script>
