<template>
  <fcs-container>
    <fcs-row class="headerRow">
      <span class="title">{{ title }}</span>
      <i class="mobileNoIcon pr-3 mdi mdi-window-close" @click="closeWizard()"></i>
    </fcs-row>
    <fcs-divider color="medium"/>
  </fcs-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'Header',
  props: {
    title: String
  },
  methods: {
    closeWizard () {
      let path = '/';
      if (this.$route.name === 'ScheduleInhibit') {
        path = '/inhibit';
      }
      this.clearWizard();
      this.clearSupportNumber();
      this.setScheduleStatus('');
      this.$router.push(path);
    },
    ...mapActions(['clearWizard', 'clearSupportNumber']),
    ...mapMutations(['setScheduleStatus'])
  }

};
</script>

<style scoped lang="scss">
.title {
  text-transform: uppercase;
  font-weight: normal !important;
  font-size:24px !important;
  color: #002F6C !important;
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

i{
  font-size:1.5rem;
  &:hover{
    cursor: pointer;
  }
}
@media only screen and (max-width: 640px) {
  .mobileNoIcon {
    display: none;
  }
}

</style>
