<template>
  <fcs-row class="mt-4 d-flex flex-grow-1 overflow-hidden" lg="8" id="assign-vehicle-comp" align-h="center" align-self="center">
    <fcs-col class="h-100 mobile-friendly" lg="8">
      <div class="options mt-5"></div>
        <VehiclePaginatedTable/>
        <fcs-row class="mt-3 mb-3 divider"></fcs-row>
        <fcs-button
          :disabled="!isValidated()"
          size="lg"
          class="mobile-friendly-buttons"
          data-qa="finish-button"
          variant="primary"
          @click="handleFinishClick">
          {{ $t('global.finish') }}
        </fcs-button>
        <fcs-button
          size="lg"
          class="mobile-friendly-buttons"
          v-if="activeIndex>0 && !isEditWindow"
          data-qa="previous-button"
          variant="outline-primary"
          @click="handlePrevClick">
          {{ $t('global.previous') }}
        </fcs-button>
      <fcs-button
        size="lg"
        class="mobile-friendly-buttons"
        v-if="isEditWindow"
        data-qa="cancel-button"
        variant="outline-primary"
        @click="handleCancel">
        {{ $t('global.cancel') }}
      </fcs-button>
        <fcs-row class="mr-3 mobile-friendly-buttons" align-h="end">
          <fcs-button
            size="lg"
            class="mr-4 text-center"
            v-if="activeIndex>0 && !isEditWindow"
            data-qa="previous-button"
            variant="outline-primary"
            @click="handlePrevClick">
            {{ $t('global.previous') }}
          </fcs-button>
          <fcs-button
            :disabled="!isValidated()"
            size="lg"
            class="text-center"
            data-qa="finish-button"
            variant="primary"
            @click="handleFinishClick">
            {{ $t('global.finish') }}
          </fcs-button>
        </fcs-row>
    </fcs-col>
  </fcs-row>
</template>
<script>
import VehiclePaginatedTable from '@/components/VehiclePaginatedTable';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'AssignVehicle',
  components: { VehiclePaginatedTable },
  props: {
    cardTitle: String,
    isEditWindow: Boolean,
    card: Object
  },
  methods: {
    ...mapActions(['saveInputData', 'updateVehicles', 'clearWizard']),
    ...mapMutations(['setScheduleStatus']),
    handlePrevClick (e) {
      this.$store.commit('decrementProgressbarIndex');
      this.$store.commit('vehicles/SET_SELECTED', this.getSelected);
    },
    isValidated () {
      return this.getSelectedLength > 0;
    },
    areSomeSelected () {
      if (this.allSelected) {
        return false;
      }
      return this.getSelectedLength > 0;
    },
    allSelected () {
      return this.vehiclePagination.totalResultSize === this.getSelectedLength;
    },
    formatType (type) {
      switch (type) {
      case `${i18n.t('global.alertHistoryTable.type.incline')}`:
        return `${i18n.t('global.alertHistoryTable.filterItems.inclineAlert')}`;
      case `${i18n.t('global.alertHistoryTable.type.impact')}`:
        return `${i18n.t('global.alertHistoryTable.filterItems.impactAlert')}`;
      case `${i18n.t('global.alertHistoryTable.type.security')}`:
        return `${i18n.t('global.alertHistoryTable.filterItems.securityAlert')}`;
      default:
        return `${i18n.t('global.alertHistoryTable.filterItems.inconsistentLocationAlert')}`;
      }
    },
    async handleFinishClick () {
      let message = '';
      const numberOfVehicles = this.getSelectedLength;
      const selectedVehicles = this.getSelected;
      const { alertRecipients: recipients, startTime, endTime, timeZone, selected: days, scheduleName, alertType, selectedText, selectedEmail } = this.$store.state;
      this.$store.commit('vehicles/SET_SELECTED', this.getSelected);
      if (this.isEditWindow) {
        await this.updateVehicles({ originalCardInfo: this.card, updatedSelectedLength: numberOfVehicles, updateSelected: this.selection });
        message = this.isErrorStatus ? i18n.t('global.editErrorMessage') : i18n.t('global.editConfirmation', { name: this.card.scheduleName });
      } else {
        const payload = {
          recipients,
          startTime,
          endTime,
          timeZone,
          days,
          numberOfVehicles,
          scheduleName,
          alertType,
          selectedText,
          selectedEmail,
          selected: selectedVehicles
        };

        await this.saveInputData(payload);
        message = this.isErrorStatus ? i18n.t('global.createErrorMessage') : i18n.t('global.createConfirmation', { alertType: this.formatType(alertType) });
      }
      // TODO: handle error in case of promise rejection
      return this.$router.push({ name: 'Home', params: { message } }).catch(() => ({}));
    },
    handleCancel () {
      this.clearWizard();
      this.$router.push('/');
    }
  },
  computed: {
    ...mapState(['activeIndex']),
    ...mapState('vehicles', ['selection']),
    ...mapGetters('vehicles', [
      'getSelectedLength',
      'getSelected'
    ]
    ),
    ...mapGetters(['isErrorStatus'])
  },
  mounted () {
    this.setScheduleStatus('');
  }
};
</script>

<style scoped lang="scss">
.filter {
  height: 50px;
  border: 1px solid #ced4da;
  border-radius: 25px;
  font-size: 22px;
  padding-top: 8px;
  cursor: pointer;
}
 .vehicle-paginated-table {
   overflow-x: hidden;
 }
 .table thead th{
   font-size: 1rem !important;
 }
 .vehicle-name-subtext{
   font-size: 0.6rem !important;
 }

 .divider{
   border-top: 1px solid #e7e7e7;
   width: 98%;
   margin:auto;
 }

@media only screen and (min-width: 641px) {
  button.mobile-friendly-buttons {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .mobile-friendly {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  button.mobile-friendly-buttons {
    margin-bottom: .5rem;
  }
  div.mobile-friendly-buttons {
    display: none;
  }
}

</style>
