<template>
  <fcs-customizable-dialog
    :dialogTitle="$t('inhibit.adhoc.deInhibitModalTitle')"
    ref="deInhibit-Modal"
    id="triggerDeInhibitModal"
    no-close-on-esc
    no-close-on-backdrop
    :submit-disabled="!isSubmittable()"
    :submit-button-text="$t('inhibit.adhoc.deInhibit')"
    :cancel-button-text="$t('inhibit.adhoc.cancel')"
    :submit-button-visible="true"
    :cancel-button-visible="true"
    :close-button-handler="closeModal"
    :cancel-button-handler="closeModal"
    :submit-button-handler="handleSubmit"
    :append-to-body="appendToBody"
    submitButtonType="primary"
  >
    <InhibitVehiclePaginatedTable
      :is-deinhibit-modal="true"
      :is-modal="true"
      :inhibit-status-filter="'On'"
      :should-run-vehicles-refresh=false
    />
  </fcs-customizable-dialog>
</template>

<script>
import InhibitVehiclePaginatedTable from '@/fleetstartinhibit/components/InhibitVehiclePaginatedTable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdHocDeInhibitModal',
  components: { InhibitVehiclePaginatedTable },
  props: {
    appendToBody: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      filtered: ''
    };
  },
  inject: [
    'showAdhocDeInhibitFailure',
    'showAdhocDeInhibitSuccess'
  ],
  methods: {
    ...mapActions('vehicles', [
      'removeVehicleInhibit',
      'resetVehicleFilter'
    ]),
    isSubmittable () {
      return this.getSelectedVins.length > 0;
    },
    closeModal () {
      this.setFilter('');
      this.$refs['deInhibit-Modal'].hideModal();
      this.resetVehicleFilter();
    },
    async handleSubmit () {
      await this.removeVehicleInhibit({ vins: this.getSelectedVins });
      const response = this.getAdhocDeInhibitResponse;
      if (!response.allFailed) {
        this.showAdhocDeInhibitSuccess();
      }
      if (response.errors.length > 0) {
        this.showAdhocDeInhibitFailure();
      }
      this.closeModal();
    },
    setFilter (filter) {
      this.filtered = filter;
    }
  },
  computed: {
    ...mapGetters('vehicles', [
      'getSelectedVins',
      'getAdhocDeInhibitResponse'
    ])
  }
};
</script>

<style scoped>
  #triggerDeInhibitModal___BV_modal_outer_ {
    z-index: 3000 !important;
  }
</style>
