import { AxiosRequestConfig } from 'axios';

export function csrfInterceptor (config: AxiosRequestConfig) {
  if (config.method !== 'GET') {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const serviceName: string = config.baseURL.split('/')[3];
      const cookieName = `csrf-${serviceName}`;
      const csrfToken = getCookie(cookieName);
      if (csrfToken) {
        config.headers.common['X-XSRF-TOKEN'] = csrfToken;
      }
    } catch (ex) {
      console.error('Could not set the CSRF header.', ex);
    }
  }
  return config;
}

function getCookie (name: string) {
  const cookieString = document.cookie;
  let cookieValue = null;
  if (cookieString && cookieString.includes(name)) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cookieValue = cookieString
        .split('; ')
        .find(cookiePair => cookiePair.startsWith(name + '='))
        .split('=')[1];
    } catch (ex) {
      /* eslint-disable no-console */
      console.error(`Could not find the cookie ${name}.`, ex);
      /* eslint-enable no-console */
    }
  }
  return cookieValue;
}
