var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fcs-row',{staticClass:"mt-4",attrs:{"align-h":"center","align-self":"center"}},[_c('fcs-col',{staticClass:"mobile-friendly"},[_c('p',{staticClass:"mb-1 title"},[_vm._v(_vm._s(_vm.cardTitle))]),_c('fcs-form-input-text',{staticClass:"alertName",attrs:{"id":"alertName","placeholder":_vm.$t(_vm.placeholderNameValue),"error-state":_vm.isInvalid,"errorText":_vm.errorText,"disabled":_vm.isEditWindow,"data-qa":"scheduleName"},model:{value:(_vm.scheduleName),callback:function ($$v) {_vm.scheduleName=$$v},expression:"scheduleName"}}),(_vm.hideRecipients)?_c('div',{staticClass:"multi",attrs:{"data-qa":"RecipientSelect"}},[_c('p',{staticClass:"mb-1 title"},[_vm._v(" "+_vm._s(_vm.$t('global.alertCardInfo.recipientTitle'))+" ")]),_c('multiselect',{staticClass:"mb-1",attrs:{"data-qa":"makeRecipientSelect","options":_vm.options,"multiple":true,"label":"name","track-by":"name","searchable":true,"open-direction":"bottom","close-on-select":false,"clear-on-select":true,"placeholder":_vm.$t('global.selectUsers'),"show-labels":false},on:{"remove":function($event){return _vm.onRemove($event)},"select":_vm.toggleSelected},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_c('p',[_vm._v(_vm._s(values.map(function (ref) {
	var name = ref.name;

	return name;
}).join(', ')))])]):_vm._e()]}},{key:"option",fn:function(scope){return _c('span',{staticClass:"checkbox-label multiselect__element",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.select(scope.option)}}},[_c('fcs-form-checkbox',{attrs:{"checked":scope.option.checked},nativeOn:{"click":function($event){$event.preventDefault();}}},[_vm._v(_vm._s(scope.option.name))])],1)}}],null,false,1815432949),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('template',{slot:"tag"})],2)],1):_vm._e(),_c('hr'),(!_vm.isEditWindow)?_c('fcs-button',{staticClass:"mobile-friendly-buttons",attrs:{"disabled":!_vm.isComplete(),"data-qa":"next-button","variant":"primary"},on:{"click":function($event){return _vm.handleNextClick()}}},[_vm._v(" "+_vm._s(_vm.$t('global.next'))+" ")]):_vm._e(),(_vm.isEditWindow)?_c('fcs-button',{staticClass:"mobile-friendly-buttons",attrs:{"disabled":!_vm.isComplete(),"data-qa":"finish-button","variant":"primary"},on:{"click":function($event){return _vm.update()}}},[_vm._v(" "+_vm._s(_vm.$t('global.finish'))+" ")]):_vm._e(),_c('fcs-button',{staticClass:"mobile-friendly-buttons cancel-button",attrs:{"data-qa":"cancel-button","variant":"outline-primary"},on:{"click":function($event){return _vm.handleCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }