import axios, { AxiosInstance } from 'axios';
import { alertHistoryResultType } from '@/store/types';
import { csrfInterceptor } from '@/services/CsrfInterceptor';

class AlertService {
  private readonly client: AxiosInstance;

  constructor (client?: AxiosInstance) {
    if (client) {
      this.client = client;
    } else {
      this.client = axios.create({
        baseURL: process.env.VUE_APP_SMA_BASE_URL,
        withCredentials: true
      });
    }
    this.client.interceptors.request.use(csrfInterceptor);
  }

  async getAlertHistory (page: number, pageSize: number, sort: string, alertTypes?: Array<string>, searchKey?: string): Promise <void | {totalSize: number, results: Array<alertHistoryResultType>} > {
    try {
      const queryParams = { page, pageSize, sort };

      if (alertTypes && alertTypes.length > 0) {
        Object.assign(queryParams, { alertTypes: alertTypes.join(',') });
      }

      if (searchKey) {
        Object.assign(queryParams, { searchBy: searchKey });
      }

      const response = await this.client.get('v1/alerts', { params: queryParams });
      return response.data;
    } catch (error) {
      // TODO: handle error
      console.log(`Error retrieving alert history from vehicle-security-alerts api: ${error}`);
    }
  }
}

export default new AlertService();
export { AlertService };
