import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import {
  ApplyAdHocInhibitRequest,
  RemoveAdHocInhibitRequest,
  scheduleUpdateRequest
} from '@/store/types';
import { csrfInterceptor } from '@/services/CsrfInterceptor';

class FSIVehicleService {
  private readonly client: AxiosInstance;
  private cancelTokenSource: CancelTokenSource | undefined;

  constructor (client?: AxiosInstance) {
    if (client) {
      this.client = client;
    } else {
      this.client = axios.create({
        baseURL: process.env.VUE_APP_FSI_BASE_URL,
        withCredentials: true
      });
    }
    this.client.interceptors.request.use(csrfInterceptor);
  }

  async getFsiVehicles (urlParams: any) {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('getFsiVehicles call cancelled');
    }
    this.cancelTokenSource = axios.CancelToken.source();
    return await this.client.get('/v2/vehicles', {
      params: urlParams,
      cancelToken: this.cancelTokenSource.token
    }).then(response => {
      return response.data;
    }).catch(e => {
      if (axios.isCancel(e)) {
        return null;
      } else {
        console.log('FSIVehicleService.getFsiVehicles: ', e);
        throw e;
      }
    });
  }

  async getFsiVehiclesWithoutCancel (urlParams: any) {
    return await this.client.get('/v2/vehicles', {
      params: urlParams
    }).then(response => {
      return response.data;
    }).catch(e => {
      console.log('FSIVehicleService.getFsiVehicles: ', e);
      throw e;
    });
  }

  applyAdHocInhibit (applyAdHocInhibitRequest: ApplyAdHocInhibitRequest) {
    return this.client.post('api/v1/inhibit/applyInhibit', applyAdHocInhibitRequest);
  }

  removeAdHocInhibit (removeAdHocInhibitRequest: RemoveAdHocInhibitRequest) {
    return this.client.post('api/v1/inhibit/removeInhibit', removeAdHocInhibitRequest);
  }

  async postScheduledInhibit (scheduledRequest: any) {
    try {
      const response = await this.client.post('api/v1/inhibit/scheduleInhibit', scheduledRequest);
      return response.data;
    } catch (e) {
      console.log('error ' + e);
      return null;
    }
  }

  async checkForOverlappingSchedules (scheduledRequest: any) {
    try {
      const response = await this.client.post('api/v1/validateSchedule', scheduledRequest);
      return response.data;
    } catch (e) {
      console.log('error ' + e);
      return null;
    }
  }

  async checkForOverlappingSchedulesByID (scheduledRequest: any) {
    try {
      const response = await this.client.post('api/v1/validateScheduleById', scheduledRequest);
      return response.data;
    } catch (e) {
      console.log('error ' + e);
      return null;
    }
  }

  async checkForActiveSchedules (requestId: any) {
    try {
      const response = await this.client.get('api/v1/isScheduleActive/' + requestId);
      return response.data;
    } catch (e) {
      console.log('error ' + e);
      return false;
    }
  }

  async deleteScheduledInhibit (requestId: any) {
    try {
      const response = await this.client.post('api/v1/inhibit/deleteSchedule', requestId);
      if (response.data.errors != null && response.data.errors.length > 0) {
        return response.data.errors[0].code;
      }
      return response.status;
    } catch (e) {
      console.log('error ' + e);
      return 400;
    }
  }

  async getAllFsIVehicleFilters () {
    try {
      const response = await this.client.get('api/v1/vehicles/filters');
      return response.data;
    } catch (e) {
      console.log('error ' + e);
      return null;
    }
  }

  async getAllInhibitSchedules () {
    try {
      const response = await this.client.get('api/v1/inhibit/schedules');
      return response.data;
    } catch (e) {
      console.log('Unable to get schedules');
    }
  }

  async updateScheduledRequest (updateRequest: scheduleUpdateRequest) {
    try {
      const response = await this.client.put('api/v1/inhibit/updateSchedule', updateRequest);
      if (response.data.errors != null && response.data.errors.length > 0) {
        return response.data.errors[0].code;
      }
      return response.status;
    } catch (e) {
      console.log('Error while updating vehicles for current schedule');
      return 400;
    }
  }

  async getUserPreferences () {
    try {
      const response = await this.client.get('api/v1/userPreferences');
      return response;
    } catch (e) {
      console.log('error ' + e);
      return null;
    }
  }
}

export default new FSIVehicleService();
export { FSIVehicleService };
