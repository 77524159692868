import vehicleService from '@/services/VehicleService';
import fsiVehicleService from '@/fleetstartinhibit/services/FSIVehicleService';
import { isEmpty } from 'lodash';
import { DEFAULT_VEHICLE_SORT, STATES } from '@/constants';
import { ApplyAdHocInhibitRequest, vehicleUrlParams } from '@/store/types';

async function fetch ({ commit, state, payload, dispatch, getters, rootGetters }: any) {
  commit('SET_STATE', STATES.LOADING);
  const request: vehicleUrlParams = { type: '', page: 0, size: 25 };

  type filterParamDict = { [key:string] :string}
  const filterParams: filterParamDict = {};

  const vehicleFilters:{[key:string]:Array<string>} = state.vehicleFilters;

  const { vehicleSearch, ...filters } = vehicleFilters;

  for (const [key, value] of Object.entries(filters)) {
    filterParams[key] = value.join(',');
  }

  if (!isEmpty(state.vehicleFilters)) {
    commit('SET_PREV_VEHICLE_FILTER');
    Object.assign(request, filterParams);
  }
  Object.assign(request, state.pageRequest);
  Object.assign(request, payload.options);
  Object.assign(request, { search: vehicleSearch });
  Object.assign(request, { type: rootGetters.getAlertType });

  try {
    const vehiclesResponse = await vehicleService.getVehicles(request);
    commit('LOADED_STATE', { response: vehiclesResponse });
    return vehiclesResponse;
  } catch (e) {
    commit('ERROR_STATE', { message: e });
  }
}

async function fetchFsiVehicles ({ commit, state, payload, dispatch, getters, rootGetters }: any) {
  if (!payload.ignoreLoadingState) {
    commit('SET_STATE', STATES.LOADING);
  }
  const request: vehicleUrlParams = { ...state.pageRequest };

  if (state.fsiSearch) {
    request.search = state.fsiSearch;
  }

  if (state.vehicleFilters) {
    for (const [filterName, filters] of Object.entries(state.vehicleFilters)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (const filter of filters) {
        if (filterName in request) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          request[filterName] += `,${filter}`;
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          request[filterName] = filter;
        }
      }
    }
  }

  Object.assign(request, payload.options);

  try {
    const vehiclesResponse = await fsiVehicleService.getFsiVehicles(request);
    if (!vehiclesResponse) {
      return;
    }
    commit('FSI_LOADED_STATE', { response: vehiclesResponse });
    return vehiclesResponse;
  } catch (e) {
    commit('FSI_ERROR_STATE', { message: e });
  }
}

async function fetchFsiFfmFilters ({ commit, payload }: any) {
  commit('SET_FILTER_STATE', STATES.LOADING);
  try {
    const ffmFilterResponse = await fsiVehicleService.getAllFsIVehicleFilters();
    commit('FILTER_LOADED_STATE', { response: ffmFilterResponse });
    return ffmFilterResponse;
  } catch (e) {
    commit('FILTER_ERROR_STATE', { message: e });
  }
}

async function fetchExistingSchedules ({ commit, payload }: any) {
  try {
    return await fsiVehicleService.getAllInhibitSchedules();
  } catch (e) {
    commit('FSI_ERROR_STATE', { message: e });
  }
}

const actions = {
  async checkIfUserHasFsiVehicles ({ commit, state }: any) {
    try {
      const request = { type: '', page: 0, size: 1, sort: DEFAULT_VEHICLE_SORT };
      const response = await fsiVehicleService.getFsiVehiclesWithoutCancel(request);
      if (response.results && response.results[0].vehicles) {
        const hasFsiEnrolledVehicles = response.results[0].vehicles.length > 0;
        commit('SET_HAS_FSI_ENROLLED_VEHICLES', { hasFsiEnrolledVehicles });
      }
    } catch (e) {
      commit('SET_HAS_FSI_ENROLLED_VEHICLES', { hasFsiEnrolledVehicles: false });
    }
  },
  resetFsiSearch ({ commit }: any) {
    commit('SET_FSI_SEARCH', '');
  },
  async editSchedule ({ commit }: any, request: any) {
    if (request && request.schedule) {
      if (request.schedule.vehicles) {
        commit('SET_SELECTED_VINS', { selectedVins: request.schedule.vehicles });
      }
      commit('SET_REQUEST_ID', request.schedule.requestId);
      commit('SET_EDIT_SCHEDULE', { schedule: request.schedule });
    }
  },
  async resetSelectedVins ({ commit }: any) {
    commit('SET_SELECTED_VINS', { selectedVins: [] });
  },
  async sendAdHocInhibitApplyRequest ({ commit, state, payload }: any, adHocInhibitRequest: ApplyAdHocInhibitRequest) {
    const inhibitionData = { vins: adHocInhibitRequest.vins, errors: [], isSuccess: false, allFailed: false };
    try {
      const resp = await fsiVehicleService.applyAdHocInhibit(adHocInhibitRequest);

      inhibitionData.errors = resp.data.errors;
      inhibitionData.isSuccess = true;

      if (resp.data.errors.length > 0) {
        if (inhibitionData.vins.length === resp.data.errors[0].innererror.vins.length) {
          inhibitionData.allFailed = true;
        }
      }
    } catch (e) {
      console.log('sendAdHocInhibitApplyRequest', e);
    } finally {
      commit('UPDATE_AD_HOC_INHIBIT_RESPONSE', inhibitionData);
    }
  },
  async sendScheduleRequest ({ commit, state, payload, dispatch, getters }: any, scheduledRequest: any) {
    try {
      const response = await fsiVehicleService.postScheduledInhibit(scheduledRequest);
      commit('SCHEDULED_INHIBIT_RESPONSE', { response: response });

      return response;
    } catch (e) {
      commit('FSI_ERROR_STATE', { message: e });
    }
  },
  async deleteScheduledRequest ({ commit, state, payload, dispatch, getters }: any, requestId: any) {
    try {
      return await fsiVehicleService.deleteScheduledInhibit(requestId);
    } catch (e) {
      commit('FSI_ERROR_STATE', { message: e });
    }
  },
  async getAllExistingSchedules ({ commit, state, payload, dispatch, getters }: any) {
    try {
      const response = await fetchExistingSchedules({ commit, state, payload, getters, dispatch });
      commit('SET_EXISTING_SCHEDULES', response);
    } catch (e) {
      commit('FSI_ERROR_STATE', { message: e });
    }
  },
  setFsiSearch ({ commit }: any, payload: any) {
    return commit('SET_FSI_SEARCH', payload);
  },
  setFsiVehicleFilters ({ commit }:any, payload:any) {
    return commit('SET_FSI_VEHICLE_FILTERS', payload);
  },
  setVehicleSearchQuery ({ commit }:any, payload:any) {
    return commit('SET_VEHICLE_SEARCH_QUERY', payload);
  },
  toggleSelect ({ commit }:any, payload:any) {
    commit('TOGGLE_SELECT_ROW', payload);
  },
  async resetSort ({ commit, state, getters, dispatch }:any, payload:any) {
    commit('RESET_SORT');
    return fetch({ commit, state, payload, getters, dispatch });
  },
  async toggleSort ({ commit, state, getters, dispatch }:any, payload:any) {
    const { columnName } = payload;
    if (state.sorting.key !== columnName) {
      commit('SET_SORT', { key: columnName, direction: 'asc' });
    } else if (state.sorting.key === columnName && state.sorting.direction === 'asc') {
      commit('SET_SORT', { key: columnName, direction: 'desc' });
    } else {
      commit('RESET_SORT');
    }
    if (!payload.skipReload) {
      return fetch({ commit, state, payload, getters, dispatch });
    }
  },
  async goToPage ({ commit, state, getters, dispatch, rootGetters }: any, payload:any) {
    commit('GO_TO_PAGE', payload);
    return fetch({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async inhibitGoToPage ({ commit, state, getters, dispatch, rootGetters }: any, payload:any) {
    commit('GO_TO_PAGE', payload);
    return await fetchFsiVehicles({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async next ({ commit, state, getters, dispatch, payload, rootGetters }:any) {
    commit('NEXT_PAGE');
    return fetch({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async previous ({ commit, state, getters, dispatch, rootGetters }:any, payload:any) {
    commit('PREV_PAGE');
    return fetch({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async first ({ commit, state, getters, dispatch, rootGetters }:any, payload:any) {
    commit('FIRST_PAGE');
    return fetch({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async inhibitFirst ({ commit, state, getters, dispatch, rootGetters }:any, payload:any) {
    commit('FIRST_PAGE');
    return fetchFsiVehicles({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async last ({ commit, state, getters, dispatch, rootGetters }:any, payload:any) {
    commit('LAST_PAGE');
    return fetch({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async reload ({ commit, state, getters, dispatch, rootGetters }:any, payload = {}) {
    commit('FIRST_PAGE');
    return fetch({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async reset ({ commit, state, getters, dispatch, rootGetters }:any, payload = {}) {
    commit('RESET', payload);
    commit('FIRST_PAGE');
    return fetch({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async inhibitReset ({ commit, state, getters, dispatch, rootGetters }:any, payload = {}) {
    commit('RESET', payload);
    commit('FIRST_PAGE');
    return fetchFsiVehicles({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async changePageSize ({ commit, state, getters, dispatch, rootGetters }: any, payload:any) {
    commit('RESET', payload);
    commit('CHANGE_PAGE_SIZE', payload);
    if (!payload.skipReload) {
      return fetch({ commit, state, payload, getters, dispatch, rootGetters });
    }
  },
  async changeInhibitPageSize ({ commit, state, getters, dispatch, rootGetters }: any, payload:any) {
    commit('CHANGE_PAGE_SIZE', payload);
    return fetchFsiVehicles({ commit, state, payload, getters, dispatch, rootGetters });
  },
  async setSelected ({ commit }:any, payload: any) {
    commit('SET_SELECTED', payload);
  },
  async addSelected ({ commit }:any, vin: string) {
    const newSelected: {[key: string]: any} = {};
    newSelected[vin] = { vin: vin };
    commit('ADD_SELECTED', newSelected);
  },
  async removeSelected ({ commit } : any, vin: string) {
    commit('REMOVE_SELECTED', vin);
  },
  async clearSelected ({ commit }:any) {
    commit('CLEAR_SELECTED');
  },
  async fetchAvailableFilters ({ commit, rootGetters }: any) {
    const urlParams = { verified: true, type: rootGetters.getAlertType };
    const response = await vehicleService.getVehicleFilters(urlParams);
    commit('SET_AVAILABLE_FILTERS', response);
  },
  setVehicleFilterByKey ({ commit }:any, payload:any) {
    commit('SET_VEHICLE_FILTER_BY_KEY', payload);
  },
  resetVehicleFilter ({ commit }:any) {
    commit('RESET_VEHICLE_FILTER');
  },
  async fetchAllVehicles ({ commit, state }: any) {
    const currentPageSize = state.pageRequest.size;
    commit('SET_MAX_RESULTS');

    const request: vehicleUrlParams = { type: '', page: 0, size: state.pageRequest.size };
    Object.assign(request, state.pageRequest);
    try {
      const inhibitStatusResponse = await fsiVehicleService.getFsiVehicles(request);
      commit('RESET_PAGE_REQUEST_SIZE', currentPageSize);

      commit('SET_FLEET_BATTERY_VIN_MAP', { response: inhibitStatusResponse });
    } catch (e) {
      commit('ERROR_STATE', { message: e });
    }
  },
  async validateSchedule ({ commit }: any, scheduledRequest: any) {
    try {
      const vinsWithOverlappingScheduleSize = await fsiVehicleService.checkForOverlappingSchedules(scheduledRequest);
      commit('SET_VINS_WITH_OVERLAPPING_SCHEDULES_SIZE', { response: vinsWithOverlappingScheduleSize });
      return vinsWithOverlappingScheduleSize;
    } catch (e) {
      commit('ERROR_STATE', { message: e });
    }
  },
  async validateScheduleByID ({ commit }: any, scheduledRequest: any) {
    try {
      const vinsWithOverlappingScheduleSize = await fsiVehicleService.checkForOverlappingSchedulesByID(scheduledRequest);
      commit('SET_VINS_WITH_OVERLAPPING_SCHEDULES_SIZE', { response: vinsWithOverlappingScheduleSize });
      return vinsWithOverlappingScheduleSize;
    } catch (e) {
      commit('ERROR_STATE', { message: e });
    }
  },
  async ffmFilterFirst ({ commit, state, getters, dispatch, rootGetters }:any, payload:any) {
    return fetchFsiFfmFilters({ commit, payload });
  },
  async updateCurrentSchedule ({ commit, dispatch, state }:any, payload: any) {
    try {
      return await fsiVehicleService.updateScheduledRequest(payload);
    } catch (e) {
      console.log(e);
      return 400;
    }
  },
  async removeVehicleInhibit ({ commit, dispatch, state }:any, payload: any) {
    try {
      const response = await fsiVehicleService.removeAdHocInhibit(payload);
      const responseObject = {
        vins: response.data.vins,
        errors: response.data.errors,
        allFailed: false
      };
      if (response.data.errors.length > 0) {
        if (response.data.vins.length === response.data.errors[0].innererror.vins.length) {
          responseObject.allFailed = true;
        }
      }
      commit('UPDATE_ADHOC_DEINHIBIT_RESPONSE', responseObject);
    } catch (e) {
      commit('UPDATE_ADHOC_DEINHIBIT_RESPONSE', {
        vins: payload.vins,
        errors: e,
        allFailed: true
      });
    }
  },
  async isScheduleActive ({ commit, dispatch, state }: any, requestId: any) {
    return await fsiVehicleService.checkForActiveSchedules(requestId);
  },
  async fetchUserPreferences ({ commit }: any) {
    try {
      const userPreferences = await fsiVehicleService.getUserPreferences();
      const status = userPreferences ? userPreferences.status : 0;
      commit('SET_USER_PREFERENCES_CALL_STATUS', { status });
      commit('SET_ALERT_PREFERENCES', { response: userPreferences ? userPreferences.data : {} });
      return userPreferences;
    } catch (e) {
      commit('ERROR_STATE', { message: e });
    }
  }
};

export { actions };
