import { state, mockState } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export { state, mockState, mutations, actions, getters };
