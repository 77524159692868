<template>
  <fcs-search
      id="search-field"
      @input="debounceQueryAndUpdate"
      :placeholder="$t('searchField.label')"
      data-qa="search-field"
      type="search" />
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'SearchField',
  methods: {
    ...mapActions('vehicles', [
      'setFsiSearch'
    ]),
    emitToParent (query) {
      this.$emit('change', query.trim());
    },
    debounceQueryAndUpdate: _.debounce(function (query) {
      this.setQueryAndUpdate(query);
      this.setFsiSearch(query.trim());
    }, 1000),
    setQueryAndUpdate (query) {
      this.emitToParent(query);
    }
  }
};
</script>

<style lang="scss" scoped>

.icon-Close {
  display: inline-block;
  position: absolute;
  right: 2px;
  top: 13px;
}

#search-field {
  font-size: x-small;
}

</style>
