import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import authService from '@/services/AuthService';

Vue.use(Router);

const router = new Router({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
});

router.beforeEach(async (to, from, next) => {
  if (to.meta?.isUnprotected) {
    next();
  } else {
    const isAuthenticated = await authService.isAuthenticated();

    if (isAuthenticated) {
      next();
    } else {
      authService.login(to.path);
    }
  }
});

export default router;
