<template>
  <div class="fcs-filter-bar">
    <div class="filterChips">
      <fcs-chip
        class="filter-button"
        @click="toggleDrawer"
        :closable="false"
        icon="icon-filter"
        :label="filterText"
        data-qa="fcs-chip"
      />
      <a style="padding-top: 3.75%" class="link" v-if="checkedFilters.length > 0" @click="clearFilters" aria-label="clear-selected-filter-button">{{ clearAllText }}</a>
      <div class="row">
        <fcs-chip
          class="filter-chip"
          v-for="(filter, index) in appliedFilters"
          :key="index"
          :icon="filter.icon"
          :label="filter.label ? filter.label : filter.value"
          @click="removeFilter(filter)"
        />
      </div>
    </div>
    <fcs-side-drawer class="drawer" :title="filterHeader" :show-drawer="showDrawer" @close-drawer="showDrawer = false" refs="fcs-side-drawer" data-qa="fcs-side-drawer">
      <div class="drawer-main">
        <div class="clear-all-drawer-wrapper" style="text-align: right;">
          <a class="link" @click="clearFilters" aria-label="clear-selected-filter-button">{{ clearAllText }}</a>
        </div>
        <fcs-accordion class="drawer-section"
                       v-for="(category, index) in allCategories"
                       :key="category"
                       :id="index"
                       :headerText="$i18n.t(mapCategoryToLabel[category])">
          <div class="filter-checkbox" v-for="(filter, index) in getFiltersByCategory(category).slice(0, 5)"
               :key="index">
            <input type="checkbox" :id="filter.value" :value="filter" v-model="checkedFilters" @change="applyFilters" :aria-label="'checkbox-' + filter.value"/>
            <label :for="filter.value">{{ filter.label ? filter.label : filter.value }}</label>
          </div>
          <fcs-collapse class="show-more-collapse" :id="'show-more-filters-' + index">
            <div class="filter-checkbox" v-for="(filter, index) in getFiltersByCategory(category).slice(5)"
                 :key="index">
              <input type="checkbox" :id="filter.value" :value="filter" v-model="checkedFilters"
                     @change="applyFilters"/>
              <label :for="filter.value">{{ filter.label ? filter.label : filter.value }}</label>
            </div>
          </fcs-collapse>
          <a class="link show-more"
             v-if="getFiltersByCategory(category).length > 5"
             v-b-toggle="'show-more-filters-' + index">
            <span class="when-collapsed">{{ showMoreText }}</span>
            <span class="when-not-collapsed">{{ showLessText }}</span>
          </a>
        </fcs-accordion>
      </div>
    </fcs-side-drawer>
  </div>
</template>
<script>

export default {
  name: 'InhibitFilter',
  props: {
    clearAllText: {
      type: String,
      required: true
    },
    filterText: {
      type: String,
      required: true
    },
    filterHeader: {
      type: String,
      required: true
    },
    showMoreText: {
      type: String,
      required: true
    },
    showLessText: {
      type: String,
      required: true
    },
    filters: {
      type: Array,
      required: true
    },
    categoryLabels: {
      type: Array,
      required: true
    },
    originalFilters: {
      type: Object,
      required: true
    },
    inhibitStatusFilter: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      appliedFilters: [],
      showDrawer: false,
      checkedFilters: [],
      allCategories: [],
      isVisible: false,
      newFilters: [],
      mapCategoryToLabel: {
        years: 'global.filters.year',
        makes: 'global.filters.make',
        models: 'global.filters.model',
        inhibitStatuses: 'global.filters.inhibitStatus',
        batteryLevel: 'global.filters.batteryLevel',
        scheduleName: 'global.filters.scheduleName'
      }
    };
  },
  methods: {
    grabKeyValueInfo () {
      if (this.originalFilters.makes) {
        for (const i in this.originalFilters.makes) {
          this.newFilters.push({
            category: 'makes',
            value: this.originalFilters.makes[i]
          });
        }
      }
      if (this.originalFilters.models) {
        for (const i in this.originalFilters.models) {
          this.newFilters.push({
            category: 'models',
            value: this.originalFilters.models[i]
          });
        }
      }
      if (this.originalFilters.years) {
        for (const i in this.originalFilters.years) {
          this.newFilters.push({
            category: 'years',
            value: this.originalFilters.years[i]
          });
        }
      }
      if (this.originalFilters.inhibitStatus) {
        for (const i in this.originalFilters.inhibitStatus) {
          this.newFilters.push({
            category: 'inhibitStatuses',
            value: this.originalFilters.inhibitStatus[i]
          });
        }
      }
      this.$emit('create-filters', this.newFilters);
    },
    toggleDrawer () {
      this.showDrawer = !this.showDrawer;
    },
    setCategories () {
      for (const filter of this.filters) {
        if (!this.allCategories.includes(filter.category)) {
          this.allCategories.push(filter.category);
        }
      }
    },
    getFiltersByCategory (category) {
      return this.filters.filter((filter) => {
        return category === filter.category;
      });
    },
    updateAppliedFilters () {
      this.appliedFilters.splice(0);
      for (const filter of this.checkedFilters) {
        this.appliedFilters.push(filter);
      }
    },
    applyFilters () {
      this.updateAppliedFilters();
      this.$emit('update-list', this.appliedFilters);
    },
    clearFilters () {
      this.checkedFilters = [];
      this.applyFilters();
    },
    removeFilter (filter) {
      const index = this.checkedFilters.indexOf(filter);
      this.checkedFilters.splice(index, 1);
      this.applyFilters();
    }
  },
  mounted () {
    this.grabKeyValueInfo();
    if (this.inhibitStatusFilter) {
      this.checkedFilters.push({ category: 'inhibitStatuses', value: this.inhibitStatusFilter });
      this.applyFilters();
    }
  },
  watch: {
    filters: {
      handler () {
        this.setCategories();
      }
    }
  }
};
</script>

<style scoped>
.filterChips {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.filter-chip {
  display: flow;
  justify-content: flex-end;
  margin-left: 20px;
}
</style>
