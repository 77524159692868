<template>
  <div>
    <div v-for="item in schedules" :key="item.scheduleName" :id="item.scheduleName">
      <fcs-card no-body class="scheduleCardStyle">
        <fcs-card-body class="flexCardLayout">
          <div class="scheduleNameContainer">
            <fcs-editable-text
              :defaultErrorText="translatedStrings.validationDefault"
              id="fcs-editable-text-1"
              :saveText="translatedStrings.saveButtonText"
              :cancelText="translatedStrings.cancelButtonText"
              :ariaHidden="false"
              :value="item.scheduleName"
              :validationRules='validationRules'
              :validatesOn='["input","blur","change"]'
              class="editableScheduleName"
              @edit="ignoreCurrentScheduleName($event)"
              @save="updateScheduleName(item, ...arguments)"
              @cancel="enableEditButtons()"
            />
            <fcs-divider color="medium" />
          </div>
          <div class="scheduleTimeContainer">
            <div>
              {{$t('inhibit.existingSchedule.scheduleLabel')}}
              <br>
              {{ item.scheduleTime }}
              <br>
              <span class="weekday-text" v-if="item.daysActive">{{ item.daysActive }}</span>
            </div>
          </div>
          <div><fcs-divider color="medium"/></div>
          <div class="vehiclesContainer">
            <div>
            {{ $t('inhibit.existingSchedule.vehiclesLabel') }}
              <br>
              <span class="titleVehicles">{{ item.vehicles.length }} {{ $t('inhibit.cardVehicles')}}</span>
            </div>
            <div @click="addRemoveVehicles(item)" class="addRemoveVehicles">
              <b-iconstack>
                <b-icon-plus stacked style="color: #0064D4; font-size: 1.5rem;" scale="1.75"/>
                <b-icon-dash stacked style="color: #0064D4; font-size: 1.5rem;" scale="1.75" shift-v="-9"/>
              </b-iconstack>
              {{ $t('inhibit.addRemoveVehicles') }}
            </div>
          </div>
          <div><fcs-divider color="medium" /></div>
          <div class="delete">
            <span
                  v-b-modal="'triggerDeleteModal-' + item.scheduleName">
              <b-icon-trash style="color: red;"/> {{ $t('global.delete') }}
            </span>
          </div>
        </fcs-card-body>
      </fcs-card>
      <fcs-modal
        no-close-on-esc
        no-close-on-backdrop
        :ref="`triggerDeleteModal-${item.scheduleName}`"
        :id="'triggerDeleteModal-' + item.scheduleName"
        size="xl"
        centered
        :title="translatedStrings.deleteModalTitle"
        :cancel-title="translatedStrings.cancelButtonText"
        :ok-title="translatedStrings.deleteOkButton"
        ok-variant="danger"
        cancel-variant="outline-primary"
        :static="true"
        @ok="handleDelete(item)"
        :hide-footer=false>
        <div>
          "{{ item.scheduleName }}": {{$t('inhibit.deleteSchedule.confirmDeletion')}}
        </div>
      </fcs-modal>
      <fcs-modal
        no-close-on-esc
        no-close-on-backdrop
        :id="'deinhibitVehicles-' + item.scheduleName"
        :ref="'deinhibitVehicles-' + item.scheduleName"
        size="xl"
        centered
        :title="translatedStrings.deInhibitText"
        cancel-variant="primary"
        :cancel-title-html="htmlDivs.cancelButton"
        :ok-title-html="htmlDivs.okButton"
        @ok="deInhibitAndDelete(item)"
        @cancel="deleteSchedule(item)"
        :static="true"
        :hide-footer=false>
        <div>
          {{$t('inhibit.deleteSchedule.deInhibitConfirmation', {numVehicles: item.vehicles.length})}}
        </div>
      </fcs-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { VBModal } from 'bootstrap-vue';
import { i18n } from '@/i18n';

const specialChar = function (value) {
  return !/[^A-Z a-z0-9]/.test(value);
};
const maxLength = function (value) {
  return value.length <= 30;
};

export default {
  name: 'ExistingSchedules',
  data () {
    return {
      validationRules: [
        {
          isValid: specialChar,
          error: `${i18n.t('inhibit.editableText.validationSpecialCharacters')}`
        },
        {
          isValid: maxLength,
          error: `${i18n.t('inhibit.editableText.validationMaxLength')}`
        },
        {
          isValid: this.duplicateNames,
          error: `${i18n.t('inhibit.editableText.validationDuplicateName')}`
        }
      ],
      currentScheduleName: '',
      currentDuplicateName: ''
    };
  },
  props: {
    inhibitType: String
  },
  directives: {
    'b-modal': VBModal
  },
  computed: {
    ...mapGetters('vehicles', [
      'getExistingSchedules',
      'getSelectedVins',
      'getScheduleNames'
    ]),
    schedules () {
      const schedules = [];
      for (let i = 0; i < this.getExistingSchedules.length; i++) {
        if (this.getExistingSchedules[i].inhibitType === this.inhibitType) {
          schedules.push(this.getExistingSchedules[i]);
        }
      }
      return schedules;
    },
    translatedStrings () {
      return {
        validationDefault: `${i18n.t('inhibit.editableText.validationDefault')}`,
        saveButtonText: `${i18n.t('global.save')}`,
        cancelButtonText: `${i18n.t('global.cancel')}`,
        deleteModalTitle: `${i18n.t('inhibit.deleteSchedule.confirmTitle')}`,
        deleteOkButton: `${i18n.t('global.delete')}`,
        deInhibitText: `${i18n.t('inhibit.deleteSchedule.deInhibitText')}`,
        leaveInhibitedButtonText: `${i18n.t('inhibit.deleteSchedule.leaveInhibitedButtonText')}`
      };
    },
    htmlDivs () {
      return {
        okButton: "<div id='okDeInhibit'>" + this.translatedStrings.deInhibitText + '</div>',
        cancelButton: "<div id='leaveInhibited'>" + this.translatedStrings.leaveInhibitedButtonText + '</div>'
      };
    }
  },
  methods: {
    ...mapActions('vehicles', [
      'getAllExistingSchedules',
      'deleteScheduledRequest',
      'addSelected',
      'updateCurrentSchedule',
      'removeVehicleInhibit',
      'isScheduleActive',
      'editSchedule'
    ]),
    ...mapMutations('vehicles', [
      'SET_REQUEST_ID',
      'SET_SELECTED_VINS'
    ]),
    duplicateNames (value) {
      const allowedScheduleNames = this.getScheduleNames.filter(schedule => schedule.trim() !== this.currentDuplicateName.toLowerCase().trim());
      return !allowedScheduleNames.includes(value.toString().toLowerCase().trim());
    },
    ignoreCurrentScheduleName (event) {
      this.currentDuplicateName = event.trim();
      this.disableEditButtons();
    },
    disableEditButtons () {
      const allButtons = document.querySelectorAll('button[data-qa="fcs-editable-text-edit-button"]');
      allButtons.forEach(button => {
        button.setAttribute('disabled', 'disabled');
      });
    },
    enableEditButtons () {
      const allButtons = document.querySelectorAll('button[data-qa="fcs-editable-text-edit-button"]');
      allButtons.forEach(button => {
        button.removeAttribute('disabled');
      });
    },
    async updateScheduleName (schedule, e, input) {
      const requestObj = {
        requestId: schedule.requestId,
        scheduleName: input.trim(),
        vins: schedule.vehicles
      };
      const updateScheduleResponse = await this.updateCurrentSchedule(requestObj);
      if (updateScheduleResponse >= 200 && updateScheduleResponse < 300) {
        this.$parent.$refs.updateScheduleName.success({
          message: `${i18n.t('inhibit.editScheduleNameSuccessMessage')}`
        });
        this.$store.commit('vehicles/UPDATE_SCHEDULE_NAME', requestObj);
      } else {
        this.$parent.$refs.updateScheduleName.error(
          {
            message: `${i18n.t('inhibit.editScheduleNameErrorMessage')}`
          }
        );
      }
      document.querySelector('[data-qa="fcs-editable-text-content"]').textContent = this.currentDuplicateName;
      this.enableEditButtons();
    },
    addRemoveVehicles (item) {
      this.editSchedule({ schedule: item });
      this.$router.push({ name: 'EditVehicles', params: { scheduleName: item.scheduleName } });
    },
    async handleDelete (item) {
      const response = await this.isScheduleActive(item.requestId);
      if (response) {
        this.$bvModal.show('deinhibitVehicles-' + item.scheduleName);
      } else {
        this.deleteSchedule(item);
      }
    },
    async deInhibitAndDelete (item) {
      await this.removeVehicleInhibit({ vins: item.vehicles });
      await this.deleteSchedule(item);
    },
    async deleteSchedule (item) {
      const responseStatus = await this.deleteScheduledRequest({ requestId: item.requestId });
      if (responseStatus >= 200 && responseStatus < 300) {
        document.getElementById(item.scheduleName).style = 'display: none';
        this.$parent.$refs.deleteScheduleStatus.success(
          {
            message: `${i18n.t('inhibit.deleteSchedule.deleteSuccessMessage')}`
          }
        );
      } else {
        this.$parent.$refs.deleteScheduleStatus.error(
          {
            message: `${i18n.t('inhibit.deleteSchedule.deleteErrorMessage')}`
          }
        );
      }
    }
  }
};
</script>

<style scoped>

.weekday-text {
  color: grey;
}
.scheduleCardStyle {
  margin: 0.5rem 0.5rem 0.5rem 0rem;
}

@media only screen and (min-width:641px) {
  .scheduleCardStyle {
    max-width: 50%;
  }
}

.flexCardLayout {
  display: flex;
  flex-direction: column;
}

.editableScheduleName {
  font-size: large;
}

.delete {
  color: red;
  align-self: flex-end;
  font-weight: lighter;
}

@media only screen and (max-width: 640px) {
  .delete {
    align-self: center;
  }
}

.scheduleTimeContainer {
  display: flex;
  justify-content: space-between;
}
.editIcon {
  align-self: flex-end;
  color: red;
  font-weight: lighter;
}
.vehiclesContainer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .vehiclesContainer {
    flex-direction: column;
  }
}

.addRemoveVehicles {
  color: #0064D4;
  font-weight: lighter;
}

.titleVehicles {
  font-weight: lighter;
}
</style>
