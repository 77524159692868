const actions = {
  async updateFeatures ({ commit, state }:any, payload:any) {
    try {
      const response = await payload.service.getFlags();
      commit('STORE_FEATURE_FLAGS', response.body);
      return response.body;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Could not load feature flags', e);
    }
  }
};

export { actions };
