import { DEFAULT_VEHICLE_SORT, STATES } from '@/constants';

const state = {
  availableFilters: {},
  hasFsiEnrolledVehicles: true,
  vehicleFilters: {},
  fsiSearch: '',
  previousVehicleFilters: {},
  currentState: STATES.LOADING,
  page: {
    totalElements: 0,
    numberOfElements: 0,
    results: []
  },
  filterState: STATES.LOADING,
  filterErrorMessage: '',
  ffmFilters: {
    makes: [],
    models: [],
    years: [],
    inhibitStatus: [],
    batteryLevel: [],
    scheduleName: []
  },
  fsiVehicleFilters: {},
  selection: {},
  currentPageSelection: {},
  pageRequest: {
    page: 0,
    size: 25,
    sort: DEFAULT_VEHICLE_SORT
  },
  scheduleNames: [],
  scheduledPostedResponse: {},
  adhocInhibitResponse: {},
  adhocDeInhibitResponse: {},
  errorMessage: null,
  existingSchedules: [],
  fleetBatteryVinMap: [],
  selectAllVehicles: false,
  vinsWithOverlapSize: null,
  requestId: '',
  textAlertPrefOn: null,
  emailAlertPrefOn: null,
  isUserPrefCallSuccess: null,
  selectedVins: [],
  editSchedule: null
};

export { state };
