<template>
  <div>
<!--    <div v-show="this.$route.name === 'Inhibit' && !this.isDeinhibitModal">-->
<!--      <FSISupportNumber v-on="$listeners"/>-->
<!--    </div>-->
    <div id="headerContainer">
      <fcs-row class="mt-4 pb-0">
        <fcs-col>
          <SearchField style="max-height: 50px;"/>
        </fcs-col>
        <fcs-col id="filterContainer">
          <InhibitFilter
            :filter-text="filterText"
            :filter-header="filterHeader"
            :filters="filters"
            :categoryLabels="categoryLabels"
            :clear-all-text="clearAllText"
            :show-more-text="showMoreText"
            :show-less-text="showLessText"
            @update-list="filteredList = $event"
            :originalFilters="originalFilters"
            @create-filters="makeFfmFilterCall"
            data-qa="filter-button"
            refs="filterbutton"
            :inhibit-status-filter="inhibitStatusFilter"
          />
        </fcs-col>
      </fcs-row>
    </div>
    <div :class="this.getMobileClass()">
      <fcs-table
        ref="fcs-table"
        responsive
        :fields = "fields"
        :per-page="pageRequestSize"
        :busy="loading"
        :items="items"
        selectable
        tbody-tr-class="cell-styling"
        :select-mode="selectMode"
        thead-class="table-header"
        :selected-variant="''">
        <template v-slot:head(select)>
          <fcs-form-checkbox
            v-model="selectedVinsAll"
            :indeterminate="selectedVinsIndeterminate"
            data-qa="select-all-checkbox"
            ref="select-all-checkbox"
            @change="onSelectAllVins">
          </fcs-form-checkbox>
        </template>
        <template #cell(select)="data">
          <fcs-form-checkbox
            :ref="`vehiclesCheckBox-${data.item.vin}`"
            :data-qa="`vehicleSelect-${data.item.vin}`"
            class="py-0 my-auto"
            type="checkbox"
            hide-details
            primary
            v-model="selectedVins"
            :value="`${data.item.vin}`"
          />
        </template>
        <template #cell(name)="data">
          <span class="vehicle-name vehicle-title" :style="{'color':isDeepSleep(data.item.batteryLevel)}" v-if="data.item.vin">{{data.item.vin}}</span>
          <span class="vehicle-name vehicle-title" :style="{'color':isDeepSleep(data.item.batteryLevel)}" v-else>{{data.item.name}}</span>
          <fcs-icon v-if="!isSelectableVehicle(data.item.batteryLevel)"
                  :ref="`exclamation-triangle-${data.item.vin}`"
                  :data-qa="`exclamation-triangle-${data.item.vin}`"
                  name="icon-alert"
                  fill-color="red" >
          </fcs-icon>
          <br>
          <span class="vehicle-details vehicle-name-subtext">{{data.item.year}} </span>
          <span class="vehicle-details vehicle-name-subtext">{{data.item.make}}</span>
          <span class="vehicle-details vehicle-name-subtext"> {{data.item.model}}</span>
        </template>
        <template v-slot:head(batteryLevel) >
          <div class="d-inline-flex" >
            <p class="mr-2">{{ $t('global.assignVehicleTable.header.batteryLevel') }}</p>
            <fcs-icon id="showBatteryInfo" data-qa="show-battery-info" scale="0" fill-color="#0064D4" name="icon-info"></fcs-icon>
            <fcs-tooltip target="showBatteryInfo" triggers="hover click" fill-color="#0064D4" placement="right" id="showBatteryInfoTooltip">
              <p>{{ $t('inhibit.noBatterySignalToolTip') }}</p>
            </fcs-tooltip>
          </div>
        </template>
        <template v-slot:head(inhibitStatus) >
          <div class="d-inline-flex" >
            <p class="mr-2">{{ $t('global.assignVehicleTable.header.inhibitStatus') }}</p>
            <fcs-icon id="showPrivacyInfo" data-qa="show-privacy-info" scale="0" fill-color="#0064D4" name="icon-info"></fcs-icon>
            <fcs-tooltip target="showPrivacyInfo" triggers="hover click" fill-color="#0064D4" placement="right" id="showInfoTooltip">
              <p>{{ $t('inhibit.privacySettingToolTip') }}</p>
            </fcs-tooltip>
          </div>
        </template>
        <template #cell(batteryLevel)="data">
          <fcs-icon
            name="icon-Battery"
            scale="2"
            v-if="data.item.batteryLevel !== -1"
            :ref="`vehicleBatteryIcon-${data.item.vin}`"
            :data-qa="`vehicleBatterySelect-${data.item.vin}`"
            :fill-color="determineBatteryColor(data.item.batteryLevel)">
          </fcs-icon>
          <span v-if="data.item.batteryLevel !== -1" class="vehicle-name" :style="{'color':isDeepSleep(data.item.batteryLevel)}"> {{data.item.batteryLevel}}%</span>
          <span v-else class="vehicle-name" :style="{'color':isDeepSleep(data.item.batteryLevel)}"> - </span>
        </template>
        <template #cell(syncOverrideCode)="data">
          <span>{{ data.item.syncOverrideCode ? data.item.syncOverrideCode : '-' }}</span>
        </template>
        <template #cell(inhibitStatus)="data">
            <fcs-icon
              class="privacyIcon"
              name="icon-AllDataOff"
              v-if="data.item.inhibitStatus === 'All Data Off'">
            </fcs-icon>
            <fcs-icon
              class="privacyIcon"
              name="icon-GPSOff"
              v-if="data.item.inhibitStatus === 'GPS Tracking Off'">
            </fcs-icon>
          <b-icon-circle-fill v-if="isPrivacySet(data.item)" scale="2" :class="getInhibitStatusDisplayColor(data.item)"></b-icon-circle-fill>
          <span class="vehicle-name" :style="{'color':isDeepSleep(data.item.batteryLevel)}">{{getInhibitStatusDisplay(data.item)}}</span>
        </template>
        <template #cell(schedules)="data">
          <span class="vehicle-name" :style="{'color':isDeepSleep(data.item.batteryLevel)}">{{getScheduleNameDisplay(data.item)}}</span>
        </template>
        <template #table-busy>
          <div class="text-center text-dark my-2">
            <fcs-spinner class="align-middle mr-2"></fcs-spinner>
            <strong>{{ $t('global.loading') }} </strong>
          </div>
        </template>
      </fcs-table>
      <div v-if="!loading" class="paginationContainer d-flex">
        <div v-if="selectedVins && selectedVins.length > 0">
          <span id="vehiclesSelected2">{{ selectedVins.length }} Selected </span>
        </div>
        <div v-if="this.isModal === false" id="vehicleTotal2">
          {{ $t('vehicleList.total') }} {{ this.totalResultSize}}
        </div>
        <fcs-pagination-size-selector v-model="requestSize"
                                      data-qa="service-rule-vehicle-table-pagination-size"
                                      :option-text="$t('global.perPage')"
                                      @input="onPaginationSizeChange"/>
        <fcs-pagination
                        v-model="pageRequestPageNumber"
                        :per-page="pageRequestSize"
                        :total-rows="totalResultSize"
                        data-qa="service-rule-vehicle-table-pagination-arrows"
                        @change="onPaginationPageChange"/>
      </div>
    </div>
    <div :class="this.getModalMobileClass()">
      <fcs-table
        ref="fcs-table"
        responsive
        :fields = "fields"
        :per-page="pageRequestSize"
        :busy="loading"
        :items="items"
        class=""
        stacked
        tbody-tr-class="row-styling">
        <template v-slot:cell(name)="data">
          <fcs-table-mobile-header :title="data.item.vin"
                                   :subtitle="data.item.year + ' ' + data.item.make + ' ' + data.item.model"
                                   :checkbox-id="NaN"
                                   :checkbox-option="false"
                                   :change-function="changeSelection"
                                   :selected="false"
                                   :show-details="false">
          </fcs-table-mobile-header>
        </template>
        <template #cell(batteryLevel)="data">
          <div class="mobile-icons">
            <fcs-icon
              name="icon-Battery"
              scale="2"
              v-if="data.item.batteryLevel !== -1"
              :ref="`vehicleBatteryIcon-${data.item.vin}`"
              :data-qa="`vehicleBatterySelect-${data.item.vin}`"
              :fill-color="determineBatteryColor(data.item.batteryLevel)">
            </fcs-icon>
            <span v-if="data.item.batteryLevel !== -1" class="vehicle-name" :style="{'color':isDeepSleep(data.item.batteryLevel)}"> {{data.item.batteryLevel}}%</span>
            <span v-else class="vehicle-name" :style="{'color':isDeepSleep(data.item.batteryLevel)}"> - </span>
          </div>
        </template>
        <template #cell(inhibitStatus)="data">
          <div class="mobile-icons">
            <fcs-icon
              name="icon-AllDataOff"
              v-if="data.item.inhibitStatus === 'All Data Off'">
            </fcs-icon>
            <fcs-icon
              name="icon-GPSOff"
              v-if="data.item.inhibitStatus === 'GPS Tracking Off'">
            </fcs-icon>
            <b-icon-circle-fill v-if="isPrivacySet(data.item)" scale="2" :class="getInhibitStatusDisplayColor(data.item)"></b-icon-circle-fill>
            <span class="vehicle-name pr-2" :style="{'color':isDeepSleep(data.item.batteryLevel)}">{{getInhibitStatusDisplay(data.item)}}</span>
          </div>
        </template>
        <template #cell(schedules)="data">
          <span class="vehicle-name" :style="{'color':isDeepSleep(data.item.batteryLevel)}">{{getScheduleNameDisplay(data.item)}}</span>
        </template>
        <template #table-busy>
          <div class="text-center text-dark my-2">
            <fcs-spinner class="align-middle mr-2"></fcs-spinner>
            <strong>{{ $t('global.loading') }} </strong>
          </div>
        </template>
      </fcs-table>
      <div v-if="!loading" class="paginationContainer d-flex">
        <div v-if="selectedVins && selectedVins.length > 0">
          <span id="vehiclesSelected">{{ selectedVins.length }} Selected </span>
        </div>
        <div v-if="this.isModal === false" id="vehicleTotal">
          Total {{ this.totalResultSize}}
        </div>
        <fcs-pagination-size-selector v-model="requestSize"
                                      data-qa="service-rule-vehicle-table-pagination-size"
                                      @input="onPaginationSizeChange"/>
        <fcs-pagination
          v-model="pageRequestPageNumber"
          :per-page="pageRequestSize"
          :total-rows="totalResultSize"
          data-qa="service-rule-vehicle-table-pagination-arrows"
          @change="onPaginationPageChange"/>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SearchField from '@/components/common/SearchField';
import InhibitFilter from '@/fleetstartinhibit/components/InhibitFilter';
import { i18n } from '@/i18n';
import { getInhibitStatusDisplay, INHIBIT_STATUS } from '@/constants/DriveInhibit';

export default {
  name: 'InhibitVehiclePaginatedTable',
  components: {
    SearchField: SearchField,
    InhibitFilter: InhibitFilter
  },
  props: {
    isDeinhibitModal: {
      type: Boolean,
      required: false,
      default: false
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false
    },
    inhibitStatusFilter: {
      type: String,
      required: false,
      default: ''
    },
    shouldResetSelectedVins: {
      type: Boolean,
      required: false,
      default: true
    },
    shouldRunVehiclesRefresh: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      vehicles: [],
      emDash: '—',
      selectMode: 'multi',
      perPage: this.pageRequestSize,
      localChecked: this.$store.state.checkedItem,
      filteredList: [],
      search: '',
      hadFfmFilters: false,
      filters: [],
      filterQuery: '',
      filterText: i18n.t('global.filter'),
      filterHeader: i18n.t('global.selectFilters'),
      clearAllText: i18n.t('global.clearFilters'),
      showMoreText: i18n.t('global.showMore'),
      showLessText: i18n.t('global.showLess'),
      categoryLabels: [i18n.t('global.filters.make'), i18n.t('global.filters.model'), i18n.t('global.filters.year'),
        i18n.t('global.filters.inhibitStatus'), i18n.t('global.filters.batteryLevel'), i18n.t('global.filters.scheduleName')],
      supportNumberInput: '(111)-111-1111',
      selectedVinsAll: false,
      selectedVinsIndeterminate: false
    };
  },
  mounted () {
    this.resetFsiSearch();
    if (!this.inhibitStatusFilter) {
      // fetch first page and start refresh job on first page
      this.inhibitGoToPage({ ignoreLoadingState: false, pageRequested: 1, options: {} });
      this.startNewVehicleRefreshJob(() => this.inhibitGoToPage({ ignoreLoadingState: true, pageRequested: 1, options: {} }));
    }
    if (this.shouldResetSelectedVins) {
      this.resetSelectedVins();
    }
  },
  beforeDestroy () {
    this.resetFsiSearch();
    clearInterval(this.refreshVehiclesInterval);
    this.resetSelectedVins();
  },
  computed: {
    ...mapGetters(['isUserCompanyWhitelisted']),
    ...mapGetters('featureFlags', ['isSyncOverrideEnabled']),
    ...mapGetters('vehicles', [
      'getFsiVehicles',
      'pageRequestSize',
      'loading',
      'loaded',
      'error',
      'pageSelected',
      'resultSize',
      'totalResultSize',
      'getSelectedLength',
      'pageRequestPageNumber',
      'isSelected',
      'errorMessage',
      'getAvailableVehicleFilters',
      'getVehicleFilters',
      'getFfmFilters',
      'getSelectAllVehicles',
      'getFleetBatteryVinMap',
      'getSelectedVins',
      'getFsiSearch'
    ]),
    selectedVins: {
      set: function (selectedVins) {
        this.SET_SELECTED_VINS({ selectedVins });
      },
      get: function () {
        return this.getSelectedVins;
      }
    },
    fields () {
      const scheduleView = [
        { key: 'select' },
        { key: 'name', label: i18n.t('global.assignVehicleTable.header.vehicle'), sortable: true },
        { key: 'schedules', label: i18n.t('global.assignVehicleTable.header.schedules'), sortable: false }
      ];

      const nonSelectableView = [
        { key: 'name', label: i18n.t('global.assignVehicleTable.header.vehicle'), sortable: true, class: ['desktop-only-field', 'mobile-header'] },
        { key: 'name', label: i18n.t('global.assignVehicleTable.header.batteryLevel'), sortable: false, class: ['cell-styling'] },
        { key: 'inhibitStatus', label: i18n.t('global.assignVehicleTable.header.inhibitStatus'), sortable: false, class: ['cell-styling'] },
        { key: 'schedules', label: i18n.t('global.assignVehicleTable.header.schedules'), sortable: false, class: ['cell-styling', 'bottom-row'] }
      ];

      const batteryView = [
        { key: 'select' },
        { key: 'name', label: i18n.t('global.assignVehicleTable.header.vehicle'), sortable: true },
        { key: 'batteryLevel', label: i18n.t('global.assignVehicleTable.header.batteryLevel'), sortable: false }
      ];

      const deinhibitView = [
        { key: 'select' },
        { key: 'name', label: i18n.t('global.assignVehicleTable.header.vehicle'), sortable: true },
        { key: 'batteryLevel', label: i18n.t('global.assignVehicleTable.header.batteryLevel'), sortable: false }
      ];

      if (this.isUserCompanyWhitelisted || this.isSyncOverrideEnabled) {
        deinhibitView.push({ key: 'syncOverrideCode', label: i18n.t('global.assignVehicleTable.header.syncOverrideCode'), sortable: false });
      }

      if (this.isDeinhibitModal) {
        return deinhibitView;
      }
      if (this.$route.name === 'Vehicles') {
        return nonSelectableView;
      }
      if (this.$route.name === 'Inhibit') {
        return batteryView;
      }
      return scheduleView;
    },
    requestSize: {
      set (size) {
        this.$store.commit('vehicles/CHANGE_PAGE_SIZE', { size });
      },
      get () {
        return this.pageRequestSize;
      }
    },
    isAModal () {
      return this.$store.state.displayModal;
    },
    items () {
      return this.getFsiVehicles;
    },
    originalFilters () {
      return this.getFfmFilters;
    }
  },
  methods: {
    ...mapMutations('vehicles', [
      'SET_SELECTED_VINS'
    ]),
    startNewVehicleRefreshJob: function (refreshFuncAsync) {
      // if (!this.shouldRunVehiclesRefresh) {
      //   return;
      // }
      // const delay = 10000;
      // if (this.fields.some(f => f.key === 'schedules')) {
      //   clearInterval(this.refreshVehiclesInterval);
      //   this.refreshVehiclesInterval = setInterval(function () {
      //     refreshFuncAsync();
      //   }, delay);
      // }
    },
    onSelectAllVins (checked) {
      if (checked) {
        const selectedVinSet = new Set(this.selectedVins);
        const vinsOnCurrentPage = this.getFsiVehicles.map(vehicle => vehicle.vin);
        for (const vin of vinsOnCurrentPage) {
          if (!selectedVinSet.has(vin)) {
            this.selectedVins.push(vin);
          }
        }
      } else {
        const currPageVinSet = new Set(this.getFsiVehicles.map(vehicle => vehicle.vin));
        this.selectedVins = this.selectedVins.filter(vin => !currPageVinSet.has(vin));
      }
    },
    changeSelection () {
      console.log('do nothing');
    },
    getMobileClass () {
      if (this.$route.name === 'Vehicles') {
        return 'base-table';
      } else {
        return '';
      }
    },
    getModalMobileClass () {
      if (this.$route.name === 'Vehicles') {
        return 'mobile-table';
      } else {
        return 'modal-mobile-table';
      }
    },
    async makeFfmFilterCall () {
      await this.$store.dispatch('vehicles/ffmFilterFirst');
      this.updateFfmFilters(this.$store.getters['vehicles/getFfmFilters']);
    },
    updateVehicleFilters (filters) {
      this.setFsiVehicleFilters(filters)
        .then(() => {
          this.resetSelectedVins();
          this.inhibitReset();
        });
    },
    determineBatteryColor: function (battery) {
      const batteryLevel = parseInt(battery);
      if (batteryLevel < 20) {
        return 'red';
      } else if (batteryLevel >= 20 && batteryLevel < 70) {
        return 'gold';
      } else {
        return 'green';
      }
    },
    isPrivacySet: function (vehicle) {
      if (!vehicle || !vehicle.inhibitStatus) {
        return true;
      }
      const status = vehicle.inhibitStatus;

      if (status === INHIBIT_STATUS.ALL_DATA_OFF ||
        status === INHIBIT_STATUS.GPS_TRACKING_OFF) {
        return false;
      }
      return true;
    },
    getInhibitStatusDisplayColor: function (vehicle) {
      if (!vehicle || !vehicle.inhibitStatus) {
        return 'grey-icon';
      }

      const status = vehicle.inhibitStatus;

      if (status === INHIBIT_STATUS.ON) {
        return 'green-icon';
      } else if (status === INHIBIT_STATUS.PENDING_ON || status === INHIBIT_STATUS.PENDING_OFF) {
        return 'yellow-icon';
      } else if (status === INHIBIT_STATUS.FAILED ||
        status === INHIBIT_STATUS.UNKNOWN) {
        return 'red-icon';
      }

      return 'grey-icon';
    },
    getInhibitStatusDisplay: function (vehicle) {
      if (!vehicle || !vehicle.inhibitStatus) {
        return 'Off';
      }
      return getInhibitStatusDisplay(vehicle.inhibitStatus);
    },
    getScheduleNameDisplay: function (vehicle) {
      if (!vehicle || !vehicle.scheduleName) {
        return '';
      }
      return vehicle.scheduleName;
    },
    updateFfmFilters (value) {
      const newFilters = [];

      if (value.makes) {
        for (const i in value.makes) {
          newFilters.push({
            category: 'makes',
            value: value.makes[i]
          });
        }
      }
      if (value.models) {
        for (const i in value.models) {
          newFilters.push({
            category: 'models',
            value: value.models[i]
          });
        }
      }
      if (value.years) {
        for (const i in value.years) {
          newFilters.push({
            category: 'years',
            value: value.years[i]
          });
        }
      }
      if (value.inhibitStatus) {
        for (const i in value.inhibitStatus) {
          newFilters.push({
            category: 'inhibitStatuses',
            value: value.inhibitStatus[i],
            label: getInhibitStatusDisplay(value.inhibitStatus[i])
          });
        }
      }

      this.filters = newFilters;
    },
    getPageDetails () {
      return {
        options: {}
      };
    },
    onPaginationPageChange (value) {
      const payload = {
        pageRequested: value,
        ...this.getPageDetails()
      };
      this.resetSelectedVins();
      this.inhibitGoToPage(payload);
      this.startNewVehicleRefreshJob(() => this.inhibitGoToPage({ ignoreLoadingState: true, ...payload }));
    },
    onPaginationSizeChange (value) {
      const payload = {
        size: value,
        ...this.getPageDetails()
      };
      this.resetSelectedVins();
      this.changeInhibitPageSize(payload);
      this.startNewVehicleRefreshJob(() => this.changeInhibitPageSize({ ignoreLoadingState: true, ...payload }));
    },
    isSelectableVehicle (batteryLevel) {
      if ((this.isAModal && batteryLevel >= 20) || !this.isAModal) {
        return true;
      }
      return false;
    },
    isDeepSleep: function (battery) {
      const batteryLevel = parseInt(battery);
      if (batteryLevel < 20) {
        return 'grey';
      } else {
        return 'black';
      }
    },
    ...mapActions('vehicles', [
      'inhibitGoToPage',
      'clearSelected',
      'addSelected',
      'removeSelected',
      'inhibitFirst',
      'ffmFilterFirst',
      'changeInhibitPageSize',
      'setVehicleFilterByKey',
      'setFsiVehicleFilters',
      'inhibitReset',
      'fetchAllVehicles',
      'resetSelectedVins',
      'resetFsiSearch'
    ]),
    ...mapActions(['setCheckedItem']),
    updateSelectAllCheckboxState (selectedVins, fsiVehicles) {
      const selectedVinSet = new Set(selectedVins);
      let matches = 0;
      for (const { vin } of fsiVehicles) {
        if (selectedVinSet.has(vin)) {
          matches++;
        }
      }
      if (matches === 0) {
        this.selectedVinsIndeterminate = false;
        this.selectedVinsAll = false;
      } else if (fsiVehicles && matches === fsiVehicles.length) {
        this.selectedVinsIndeterminate = false;
        this.selectedVinsAll = true;
      } else {
        this.selectedVinsIndeterminate = true;
        this.selectedVinsAll = false;
      }
    }
  },
  watch: {
    getFsiVehicles (newValue) {
      this.updateSelectAllCheckboxState(this.selectedVins, newValue);
    },
    selectedVins (newValue) {
      this.updateSelectAllCheckboxState(newValue, this.getFsiVehicles);
    },
    getFsiSearch () {
      this.resetSelectedVins();
      this.inhibitReset();
    },
    filteredList: {
      handler (value) {
        if (value && value.length > 0) {
          this.hadFfmFilters = true;
          const filters = {};
          for (const i in value) {
            const key = value[i].category;
            const val = value[i].value;
            if (key in filters) {
              filters[key].push(val);
            } else {
              filters[key] = [val];
            }
          }
          this.updateVehicleFilters(filters);
        } else if (this.hadFfmFilters) {
          this.hadFfmFilters = false;
          this.updateVehicleFilters({});
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
#vehicleTotal {
  font-size: .85rem;
}

.d-inline-flex {
  height: 1px;
}
.privacyIcon {
  margin: 1px 5px 1px -10px !important;
}

td.fitwidth {
  width: 1px;
  white-space: nowrap;
}

.vehicle-paginated-table {
  overflow-y: auto;

  .inner-table-row {
    flex: 1;
    height: 100%;
    overflow-y: auto;
  }
}

[disabled="disabled"] {
  pointer-events: none;
  color: grey;
}

.fcs-pagination button {
  min-width: 0;
}

table.fcs-backend-table {
  th {
    border-top: 0;
    border-bottom: 1px solid black;
  }
}

.select-all-checkbox-tableheader {
  padding: 12px !important;
}

.icon- {
  &Pending {
    color: #ffc400;
  }

  &Confirmation {
    color: green;
  }

  &Alert {
    color: red;
  }
}

.b-icon {
  padding: 1px 8px 1px 1px;
}

.status-text {
  font-size: large;
}

.vehicle-title {
  padding-right: 5%
}

.vehicle-name-text {
  color: #292E35 !important;
  font-size: large;
}

.vehicle-name-subtext {
  color: #8A8A8A !important;
}

.green-icon {
  color: green;
}

.red-icon {
  color: red;
}

.yellow-icon {
  color: gold;
}

.grey-icon {
  color: lightgrey;
}

#supportNumber {
  max-height: 150px;
}

#headerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header{
  text-transform: uppercase;
  font-weight: normal;
  color: #002F6C;
  letter-spacing: 1.8px;
  font-size: 24px;
  height:36px;
}

#filterContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.paginationContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  column-gap: 10px;
}

@media only screen and (min-width: 641px) {
  .mobile-table {
    display: none;
  }

  .modal-mobile-table {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .base-table {
    display: none;
  }
  .modal-mobile-table {
    display: none;
  }
  .mobile-table {
    .desktop-only-field::before {
      display: none;
    }
    text-align: left !important;
  }

  .mobile-icons {
    display: flex;
    flex-direction: row-reverse;
  }
}

</style>
