import { STATES } from '@/constants';
import _ from 'lodash';
import { Schedule, VehicleMap } from '@/store/types';

const vehicleGetters = {
  getFsiSearch (state: any) {
    return state.fsiSearch;
  },
  getSelectedVins (state: any) {
    return state.selectedVins;
  },
  currentPage (state: any) {
    return state.page;
  },
  currentPageSelection (state: any) {
    return state.currentPageSelection;
  },
  pageRequestPageNumber (state: any) {
    // This needs to be increased by one because the Bootstrap pagination component uses 1 indexed page numbers.
    // Spring paging is zero indexed.
    return state.pageRequest.page + 1;
  },
  hasFsiEnrolledVehicles (state: any) {
    return state.hasFsiEnrolledVehicles;
  },
  loaded (state: any) {
    return state.currentState === STATES.LOADED;
  },
  pageRequestSize (state: any) {
    return state.pageRequest.size;
  },
  loading (state: any) {
    return state.currentState === STATES.LOADING;
  },
  error (state: any) {
    return state.currentState === STATES.ERROR;
  },
  getSelected (state: any) {
    return state.selection || {};
  },
  currentPageVehicles (state: any) {
    return state.page.results || [];
  },
  resultSize (state: any) {
    return state.page.numberOfElements;
  },
  totalResultSize (state: any): number {
    return state.page.totalElements;
  },
  getFirstSelected (state: any) {
    return state.selection[Object.keys(state.selection)[0]] || {};
  },
  isSelected: (state: any) => (vin: string) => state.selection[vin] !== undefined,
  getSelectedLength: (state: any) => Object.keys(state.selection).length,
  getFsiVehicles: (state: any) => (state.page ? state.page.results : []),
  getVehicles: (state: any) => (state.page ? state.page.results : []),
  getVehicleFilters (state: any) {
    return state.vehicleFilters || {};
  },
  getVehicleFilterCount (state: any) {
    const filterCategoryKeys = Object.keys(state.vehicleFilters);

    let filterCount = 0;
    filterCategoryKeys.forEach((key) => {
      filterCount += _.isEmpty(state.vehicleFilters[key]) ? 0 : 1;
    });
    return filterCount;
  },
  getVehicleSearch (state: any) {
    return state.vehicleFilters.vehicleSearch || '';
  },
  getScheduledPostedResponse (state: any) {
    return state.scheduledPostedResponse.response || {};
  },
  getAdHocInhibitResponse (state: any) {
    return state.adhocInhibitResponse || {};
  },
  getAdhocDeInhibitResponse (state: any) {
    return state.adhocDeInhibitResponse || {};
  },
  getVehicleFilterByKey: (state: any) => (filterKey: any) => state.vehicleFilters[filterKey] || [],
  getAvailableVehicleFilters (state: any) {
    return state.availableFilters || {};
  },
  errorMessage (state: any) {
    return state.errorMessage || '';
  },
  allSelected (state: any, getters: any) {
    return getters.getVehicles.length === getters.getSelectedLength;
  },
  pageSelected (state: any, getters: any) {
    return getters.currentPageVehicles.filter((v: VehicleMap) => !getters.isSelected(v.vin)).length < 1;
  },
  getSelectAllVehicles (state: any) {
    return state.selectAllVehicles;
  },
  getFfmFilters (state: any) {
    return state.ffmFilters || {};
  },
  getExistingSchedules (state: any) {
    return state.existingSchedules;
  },
  getScheduleNames (state: any) {
    if (state.existingSchedules !== undefined) {
      return state.existingSchedules.map((s: Schedule) => s.scheduleName.toLowerCase());
    } else {
      return [''];
    }
  },
  getRequestId (state: any) {
    return state.requestId;
  },
  getRequestVinsWithOverlappingScheduleSize (state: any) {
    return state.vinsWithOverlapSize;
  },
  getFleetBatteryVinMap (state: any) {
    return state.fleetBatteryVinMap;
  },
  getEditSchedule (state: any) {
    return state.editSchedule;
  }
};

export { vehicleGetters as getters };
