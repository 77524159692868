import axios, { AxiosInstance } from 'axios';
import { csrfInterceptor } from '@/services/CsrfInterceptor';

/* eslint-disable no-unused-vars, no-shadow */
enum CommandType {
  Alarm = 'ALARM',
  Location = 'LOCATION'
}
/* eslint-enable no-unused-vars, no-shadow */

interface CommandDTO {
  vin: string,
  type: CommandType
}

class CommandService {
  private readonly client: AxiosInstance;
  constructor (client?: AxiosInstance) {
    if (client) {
      this.client = client;
    } else {
      this.client = axios.create({
        baseURL: process.env.VUE_APP_SMA_BASE_URL,
        withCredentials: true
      });
    }
    this.client.interceptors.request.use(csrfInterceptor);
  }

  sendCommand (command: CommandDTO): Promise<void> {
    return this.client.post('v1/commands', command);
  }

  sendLocation (vin: string): Promise<void> {
    return this.client.get('v1/currentlocation/' + vin + '?retry=true');
  }
}

export default new CommandService();
export { CommandService, CommandDTO, CommandType };
