import { i18n } from '@/i18n';

export const DEFAULT_VEHICLE_SORT = 'name,asc';
export const STATES = {
  LOADING: `${i18n.t('constants.states.loading')}`,
  LOADED: `${i18n.t('constants.states.loaded')}`,
  ERROR: `${i18n.t('constants.states.error')}`
};
export const ALERT_TYPE = {
  security: `${i18n.t('constants.alertType.security')}`,
  inconsistentLocation: `${i18n.t('constants.alertType.inconsistentLocation')}`,
  incline: `${i18n.t('constants.alertType.incline')}`,
  impact: `${i18n.t('constants.alertType.impact')}`,
  alarm: `${i18n.t('constants.alertType.alarm')}`,
  onDemandLocation: `${i18n.t('constants.alertType.onDemandLocation')}`
};
export const MODAL_TYPE = {
  alarm: `${i18n.t('constants.modalType.alarm')}`,
  getLocation: 'getLocation'
};

export const PHONE_NUMBER = {
  example: '1234567890',
  regex: '^[0-9]{7,16}$'
};
