const state = {
  featureFlags: {}
};

const mockState = function (customState = {}) {
  return {
    featureFlags: {
      ...customState
    }
  };
};

export { state, mockState };
