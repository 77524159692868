<template>
  <fcs-container fluid class="overflow-auto">
    <fcs-row class="mt-3">
      <fcs-col>
        <div mx-auto ma-10 text-center>
          <h3 class="header mt-3 mb-3" style="padding-left: 13%">{{ $t('global.alertCardInfo.vehicleTitle') }}</h3>
          <hr>
        </div>
      </fcs-col>
    </fcs-row>
    <fcs-row >
      <div class="flex-grow-1 d-flex flex-column h-100 col-lg-9 mx-auto table">
        <InhibitVehiclePaginatedTable />
      </div>
    </fcs-row>
  </fcs-container>
</template>

<script>

import InhibitVehiclePaginatedTable from '@/fleetstartinhibit/components/InhibitVehiclePaginatedTable';

export default {
  name: 'Inhibit',
  components: { InhibitVehiclePaginatedTable }
};
</script>

<style lang="scss" scoped>
  .header{
    text-transform: uppercase;
    font-weight: normal;
    color: #002F6C;
    letter-spacing: 1.8px;
    font-size: 24px;
    height:36px;
  }
</style>
