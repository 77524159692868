<template>
  <fcs-container fluid class="scrollableContainer">
    <fcs-alert id="fcs-my-alert" ref="fcs-my-alert" :content="this.content" icon="icon-Info"
               :styleClass=styleClass :autoDisappear="false"></fcs-alert>
    <fcs-alert id="fcs-location-alert" ref="fcs-location-alert" :content="this.content" icon="icon-Info"
               :styleClass=this.$store.state.locationClassAlert :autoDisappear="false"></fcs-alert>
    <div align-v="center">
      <fcs-container class="desktopView">
        <h2 class="header">{{ $t('vehicleSecurity.titles.header') }}</h2>
        <!-- Banner for the notification thing -->
        <fcs-row :class="this.$store.state.locationClass" id="fcs-notification-box">
          <fcs-bronco-notification-in-app id="fcs-my-notification" icon="icon-Pin" class="floatingAlert"
                                          ref="fcs-my-notification" :title=this.$store.state.vehicleName
                                          :on-link-click="onLinkClick" :on-close-click="onCloseClick"
                                          :description="this.$store.state.locationCordinates"/>
        </fcs-row>
          <div>
            <fcs-button
              v-if="isUserCompanyWhitelisted || isGetLocationEnabled"
              v-fcs-modal.getLocationModal
              data-qa="getLocation-button"
              variant="outline-primary"
              @click="openLocationModal"
              :disabled="!this.$store.state.displayLocationButton"
              class="mr-3 mt-2">
                {{ $t('vehicleSecurity.buttons.getLocation') }}
            </fcs-button>
            <!--   Button for the display  of Trigger Vehicle Alarm   -->
            <fcs-button
              data-qa="triggerAlarm-button"
              variant="outline-primary"
              @click="openAlarmModal"
              class="mt-2">
                {{ $t('vehicleSecurity.buttons.triggerAlarm') }}
            </fcs-button>
          </div>
      </fcs-container>
      <fcs-row>
        <fcs-col style="align-self: center;" class="col-md-12"><hr></fcs-col>
      </fcs-row>
      <fcs-row style=" padding-left: 0.5%;">
        <fcs-col class="col-1" style="max-width: 20px"><b-icon icon="exclamation-triangle" scale="1.5" style="color:gold;" class="mr-3"></b-icon></fcs-col>
        <fcs-col class="mt-2"><p>{{ $t('vehicleSecurity.privacyPolicy.privacyWarning') }}</p></fcs-col>
      </fcs-row>
      <fcs-container class="mobileView">
        <!-- Banner for the notification thing -->
        <fcs-row :class="this.$store.state.locationClass" id="fcs-notification-box" style="margin-left: -1.5rem">
          <fcs-bronco-notification-in-app id="fcs-my-notification" icon="icon-Pin" class="floatingAlert"
                                          ref="fcs-my-notification" :title=this.$store.state.vehicleName
                                          :on-link-click="onLinkClick" :on-close-click="onCloseClick"
                                          :description="this.$store.state.locationCordinates"/>
        </fcs-row>
        <h2 class="header">{{ $t('vehicleSecurity.titles.header') }}</h2>
        <div class="mobile-button-row">
          <!--   This is the button for display of Get Location   -->
          <fcs-button v-fcs-modal.getLocationModal
                      v-if="isUserCompanyWhitelisted || isGetLocationEnabled"
                      data-qa="getLocation-button"
                      variant="outline-primary"
                      @click="openLocationModal"
                      :disabled="!this.$store.state.displayLocationButton"
                      class="pull-right custom-button mt-3" style="width: 9.75rem;">
            {{ $t('vehicleSecurity.buttons.mobile.getLocation') }}
          </fcs-button>
          <!--   Button for the display  of Trigger Vehicle Alarm   -->
          <fcs-button v-fcs-modal.triggerAlarmModal
                      data-qa="triggerAlarm-button"
                      variant="outline-primary"
                      @click="openAlarmModal"
                      class="custom-button mt-3"
                      style="width: 8rem;">
            {{ $t('vehicleSecurity.buttons.mobile.triggerAlarm') }}
          </fcs-button>
        </div>
        <div><fcs-divider color="medium"/></div>
      </fcs-container>
      <!--  Modal for the display of Trigger Vehicle Alarm -->
      <TriggerAlarmModal id="triggerAlarmModal" v-if="isAModal && modalType === 'alarm'" ref="triggerAlarmModal" @command="displayConfirmationAlert" />
      <!--   This is the modal for the Get Location     -->
      <GetLocationModal v-if="isAModal && modalType === 'currentLocation'" ref="getLocationModal" @command="displayCommandAlertLocation" />
      <fcs-row v-if="isUserCompanyWhitelisted || isInclineAlertEnabled"
               class="mt-3 mb-3">
        <fcs-col cols="12" mx-auto ma-10 text-centers>
          <div class="mobile-friendly">
            <p class="title">{{ $t('vehicleSecurity.titles.inclineAlert') }}</p>
            <p class="alerts">{{ $t('vehicleSecurity.descriptions.inclineAlert') }}</p>
            <fcs-button variant="primary" size="lg"
                        :to="{ name: 'CreateInclineAlert', params: {alertType: ALERT_TYPE.incline} }">
              {{ $t('global.create') }}
            </fcs-button>
            <div v-for="(cardInfo, index) in cardData.INCLINE" :key=index>
              <AlertCard @deleted="displayAlert" :card=cardInfo></AlertCard>
            </div>
          </div>
        </fcs-col>
      </fcs-row>
      <fcs-row class="mt-3 mb-3">
        <fcs-col cols="12" mx-auto ma-10 text-centers>
          <div class="mobile-friendly">
            <p class="title">{{ $t('vehicleSecurity.titles.inconsistentLocationAlert') }}</p>
            <p class="alerts">{{ $t('vehicleSecurity.descriptions.inconsistentLocationAlert') }}</p>
            <fcs-button variant="primary" size="lg"
                        :to="{ name: 'CreateInconsistentLocationAlert', params: {alertType: ALERT_TYPE.inconsistentLocation} }">
              {{ $t('global.create') }}
            </fcs-button>
            <div v-for="(cardInfo, index) in cardData.INCONSISTENT_LOCATION" :key=index>
              <AlertCard @deleted="displayAlert" :card=cardInfo></AlertCard>
            </div>
          </div>
        </fcs-col>
      </fcs-row>
      <fcs-row class="mt-3 mb-3" v-if="isUserCompanyWhitelisted || isImpactAlertEnabled">
        <fcs-col cols="12" mx-auto ma-10 text-centers>
          <div class="mobile-friendly">
            <p class="title">{{ $t('vehicleSecurity.titles.impactAlert') }}</p>
            <p class="alerts">{{ $t('vehicleSecurity.descriptions.impactAlert') }}</p>
            <fcs-button variant="primary" size="lg"
                        :to="{ name: 'CreateImpactAlert', params: {alertType:ALERT_TYPE.impact} }">
              {{ $t('global.create') }}
            </fcs-button>
            <div v-for="(cardInfo, index) in cardData.IMPACT" :key=index>
              <AlertCard @deleted="displayAlert" :card=cardInfo></AlertCard>
            </div>
          </div>
        </fcs-col>
      </fcs-row>
      <fcs-row class="mt-3 mb-3">
        <fcs-col cols="12" mx-auto ma-10 text-centers>
          <div class="mobile-friendly">
            <p class="title">{{ $t('vehicleSecurity.titles.securityAlert') }}</p>
            <p class="alerts">{{ $t('vehicleSecurity.descriptions.securityAlert') }}</p>
            <fcs-button variant="primary" size="lg"
                        :to="{ name: 'CreateSecurityAlert', params: {alertType: ALERT_TYPE.security} }">
              {{ $t('global.create') }}
            </fcs-button>
            <div v-for="(cardInfo, index) in cardData.SECURITY" :key=index>
              <AlertCard @deleted="displayAlert" :card=cardInfo></AlertCard>
            </div>
          </div>
        </fcs-col>
      </fcs-row>
    </div>
  </fcs-container>
</template>

<script>
import { groupBy } from 'lodash';
import AlertCard from '@/components/AlertCard';
import { ALERT_TYPE, MODAL_TYPE } from '@/constants';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import TriggerAlarmModal from '@/components/TriggerAlarmModal';
import GetLocationModal from '@/components/GetLocationModal';

export default {
  name: 'VehicleList',
  components: {
    FcsBroncoNotificationInApp: () => import('./FcsBroncoNotificationInApp.vue'), AlertCard, GetLocationModal, TriggerAlarmModal
  },
  props: {
    message: {
      type: String,
      default: ''
    },
    isLocNotify: {
      type: Boolean,
      default: false
    },
    isRetrieving: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      MODAL_TYPE,
      ALERT_TYPE,
      modalType: '',
      content: '',
      location: '',
      styleClass: 'fcs-alert-success',
      locationClass: 'd-none',
      locationCoordinates: '',
      notifications: {
        description: 'Latitude: ' + this.$store.state.latitude + ', Longitude: ' + this.$store.state.longitude
      }
    };
  },
  methods: {
    ...mapMutations(['toggleModal']),
    ...mapActions(['getNotifications', 'clearWizard', 'assignAlertType', 'sendCommand', 'sendLocation']),
    routeTo (routeName) {
      this.$router.push(routeName);
    },
    displayAlert (message) {
      if (this.isErrorStatus) {
        this.styleClass = 'fcs-alert-danger';
      } else {
        this.styleClass = 'fcs-alert-success';
      }
      this.content = message;
      this.$refs['fcs-my-alert'].showAlert();
    },
    displayConfirmationAlert (message, success) {
      if (success) {
        this.styleClass = 'fcs-alert-secondary';
      } else {
        this.styleClass = 'fcs-alert-danger';
      }
      this.content = message;
      this.$refs['fcs-my-alert'].showAlert();
    },
    displayCommandAlert (message) {
      this.styleClass = 'fcs-alert-secondary';
      this.content = message;
      this.$refs['fcs-my-alert'].showAlert();
    },
    displayCommandAlertLocation (message) {
      this.styleClass = 'fcs-alert-secondary';
      this.content = message;
      this.$refs['fcs-location-alert'].showAlert();
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onLinkClick () {

    },
    onCloseClick () {
      this.$store.commit('isLocNotify', false);
      this.$store.commit('locationClass', 'd-none');
    },
    async openAlarmModal () {
      this.modalType = 'alarm';
      await this.toggleModal();
      await this.$refs.triggerAlarmModal.$refs['alarm-modal'].showModal();
    },
    async openLocationModal () {
      this.modalType = 'currentLocation';
      await this.toggleModal();
      await this.$refs.getLocationModal.$refs['location-modal'].showModal();
    }
  },
  computed: {
    ...mapGetters(['isErrorStatus', 'getCheckedItem', 'isUserCompanyWhitelisted']),
    ...mapGetters('featureFlags', ['isGetLocationEnabled', 'isImpactAlertEnabled', 'isInclineAlertEnabled']),
    ...mapState(['isFsiEnabled']),
    cardData () {
      return groupBy(this.$store.state.inputArray, 'alertType');
    },
    isAModal () {
      return this.$store.state.displayModal;
    }
  },
  created () {
    this.clearWizard();
  },
  mounted () {
    this.isLocNotify = false;
    if (!this.$store.state.loadNotificationComplete) {
      this.$store.dispatch('getNotifications');
    }
    if (this.message !== '') {
      this.displayAlert(this.message);
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 450px) and (max-width: 1540px) {
  .pos-custom {
    position: initial !important
  }
}

.custom-button {
  height: inherit !important;
}

//Mobile view
@media only screen and (max-width: 640px) {
  .desktopView {
    display: none;
  }
  .mobileView {
    display: flex;
    flex-direction: column;
  }
  .mobile-friendly {
    display: flex;
    flex-direction: column;
  }
  .mobile-button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

//Desktop View
@media only screen and (min-width: 641px) {
  .desktopView {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
  }
  .mobileView {
    display: none;
  }
}

.btn-outline-info {
  border: 2px solid #17a2b8;
}

.row {
  margin: 0 5%;
}

.alerts {
  color: var(--black-500);
  margin-bottom: 2px;
  // TODO duplicate color properties, leaving commented for Bronco to choose the appropriate color
  //color: #18191E;
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 16px;
}

.header {
  text-transform: uppercase;
  font-weight: normal;
  color: #002F6C;
  letter-spacing: 1.8px;
  font-size: 24px;
  height: 36px;
  padding-top: 1rem;
}

.title {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1.0px;
  color: #18191E;
  margin-bottom: 0.1rem;
  font-weight: 400;
}

.btn-primary:hover {
  color: white !important;
}

button.disabled:not(.btn-link) {
  background-color: #57585D;
  cursor: default;
  opacity: 0.8;
  color: #FFFFFF !important;
}

button.disabled:hover {
  color: initial !important;
}

.scrollableContainer {
  overflow-y: auto;
  overflow-x: hidden;
}

.floatingAlert {
  position: absolute;
  z-index: 3000;
}
</style>
