<template>
  <fcs-row class="mt-4" align-h="center" align-self="center">
    <fcs-col class="mobile-friendly">
      <p class="mb-1 title">{{ cardTitle }}</p>
      <fcs-form-input-text
        class="alertName"
        id="alertName"
        :placeholder="$t(placeholderNameValue)"
        v-model="scheduleName"
        :error-state="isInvalid"
        :errorText="errorText"
        :disabled="isEditWindow"
        data-qa="scheduleName">
      </fcs-form-input-text>
      <div class="multi" data-qa="RecipientSelect" v-if="hideRecipients">
        <p class="mb-1 title"> {{ $t('global.alertCardInfo.recipientTitle') }} </p>
        <multiselect
          data-qa="makeRecipientSelect"
          v-model="value"
          :options="options"
          :multiple="true"
          label="name"
          track-by="name"
          :searchable="true"
          open-direction="bottom"
          :close-on-select="false"
          :clear-on-select="true"
          @remove=onRemove($event)
          :placeholder="$t('global.selectUsers')"
          :show-labels="false"
          @select="toggleSelected"
          class="mb-1">
          <template slot="tag"></template>
          <template slot="selection" slot-scope="{ values, search, isOpen }">
            <span class="multiselect__single" v-if="values.length && !isOpen">
              <p>{{ values.map(({ name }) => name).join(', ') }}</p>
            </span>
          </template>
          <span class="checkbox-label multiselect__element" slot="option" slot-scope="scope" @click.self="select(scope.option)">
            <fcs-form-checkbox :checked="scope.option.checked" @click.native.prevent>{{ scope.option.name }}</fcs-form-checkbox>
          </span>
        </multiselect>
      </div>
      <hr>
      <fcs-button class="mobile-friendly-buttons"
                  v-if="!isEditWindow"
                  :disabled="!isComplete()"
                  data-qa="next-button"
                  variant="primary"
                  @click="handleNextClick()"> {{ $t('global.next') }}
      </fcs-button>
      <fcs-button class="mobile-friendly-buttons"
                  v-if="isEditWindow"
                  :disabled="!isComplete()"
                  data-qa="finish-button"
                  variant="primary"
                  @click="update()"> {{ $t('global.finish') }}
      </fcs-button>
      <fcs-button class="mobile-friendly-buttons cancel-button"
                  data-qa="cancel-button"
                  variant="outline-primary"
                  @click="handleCancel()"> {{ $t('global.cancel') }}
      </fcs-button>
    </fcs-col>
  </fcs-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import userService from '@/services/UserService';
import { i18n } from '@/i18n';

export default {
  name: 'SetParameters',
  props: {
    cardTitle: String,
    isEditWindow: Boolean,
    card: Object,
    hideMultiselect: Boolean,
    placeholderNameValue: {
      default: 'securityAlert.cardInfo.setParams.placeholders.scheduleName'
    }
  },
  created () {
    // TODO: do we need some sort of loading indicator? or cacheing of users?
    userService.getAllUsers()
      .then(users => {
        this.options = users.map(u => {
          return {
            id: u.id,
            name: u.firstName.concat(' ', u.lastName),
            checked: this.value.some(e => e.id === u.id)
          };
        });
      });
  },
  data () {
    return {
      value: this.$store.state.alertRecipients,
      scheduleName: this.$store.state.scheduleName,
      hideRecipients: !this.hideMultiselect,
      options: [],
      isInvalid: false,
      errorText: ''
    };
  },
  watch: {
    scheduleName: function () {
      if (this.getAlertNames.includes(this.scheduleName.toLowerCase().trim())) {
        this.errorText = `${i18n.t('inhibit.editableText.validationDuplicateName')}`;
        this.isInvalid = true;
      } else if (/[^A-Z a-z0-9]/.test(this.scheduleName)) {
        this.errorText = `${i18n.t('inhibit.editableText.validationSpecialCharacters')}`;
        this.isInvalid = true;
      } else if (this.scheduleName.length > 30) {
        this.errorText = `${i18n.t('inhibit.editableText.validationMaxLength')}`;
        this.isInvalid = true;
      } else {
        this.isInvalid = false;
      }
    }
  },
  methods: {
    ...mapActions(['updateRecipient', 'clearWizard', 'getNotifications']),
    onRemove (event) {
      event.checked = false;
    },
    toggleSelected (event) {
      event.checked = true;
    },
    isComplete () {
      if (this.isEditWindow) {
        return (this.scheduleName.length && this.value.length && !this.isInvalid); // No need to check name is editing
      }
      if (this.hideRecipients === false) {
        return (this.scheduleName.length && !this.isInvalid && !this.isDuplicateName);
      } else {
        return (this.scheduleName.length && this.value.length && !this.isInvalid && !this.isDuplicateName);
      }
    },
    handleNextClick (e) {
      this.$store.commit('incrementProgressbarIndex');
      this.$store.commit('saveParameters', { scheduleName: this.scheduleName, value: this.value });
    },
    async update () {
      await this.updateRecipient({ originalCardInfo: this.card, newRecipients: this.value, newName: this.scheduleName });
      const message = this.isErrorStatus ? i18n.t('global.editErrorMessage') : i18n.t('global.editConfirmation', { name: this.scheduleName });

      return this.$router.push({ name: 'Home', params: { message } }).catch(() => ({}));
    },
    handleCancel () {
      this.clearWizard();
      this.$router.push('/');
    }
  },
  computed: {
    ...mapState(['activeIndex']),
    ...mapGetters(['isErrorStatus', 'getAlertNames']),
    isDuplicateName () {
      return this.getAlertNames.includes(this.scheduleName.toLowerCase().trim());
    }
  },
  mounted () {
    if (this.getAlertNames.length === 0) {
      this.getNotifications();
    }
  }
};
</script>
<style scoped lang="scss">
.multiselect {
  height: 3rem;
  &:focus {
    border-color: var(--number-background);
    border-radius: 5px 5px 0px 0px;
  }
}
.multiselect__tags {
  width: 30%;
  height: 3rem;
  display:none;
}
.multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  p {
    margin: 4px 0px 1px !important;
    margin: auto;
  }
}

.checkbox {
  width: 30%;
  height: 1rem;
}
.title{
  color: #8A8A8A !important;
}
.checkboxLabel{
  padding-top: 3px;
}

.checkbox-row {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
}

#alertName {
  height: 80px;
}

@media only screen and (min-width: 641px) {
  .cancel-button {
    display: none;
  }

  .multiselect {
    width: 40%;
  }

  .alertName {
    width: 40%;
  }
}

@media only screen and (max-width: 640px) {
  .mobile-friendly {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mobile-friendly-buttons {
    flex-grow: 1;
  }

  .cancel-button {
    margin-top: .5rem;
  }
}
</style>
