<template>
  <fcs-alert
    ref="fsiBanner"
    :content="message"
    :styleClass="styleClass"
    :auto-disappear="false"
  />
</template>

<script>
import { FcsAlert } from 'fcsui';

export default {
  name: 'Banner',
  components: {
    'fcs-alert': FcsAlert
  },
  data () {
    return {
      message: '',
      styleClass: ''
    };
  },
  methods: {
    error (info) {
      this.message = info.message;
      this.styleClass = 'fcs-alert-danger';
      this.$refs.fsiBanner.showAlert();
    },
    success (info) {
      this.message = info.message;
      this.styleClass = 'fcs-alert-success';
      this.$refs.fsiBanner.showAlert();
    }
  }
};
</script>
