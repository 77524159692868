/* eslint-disable no-unused-vars */
import { i18n } from '@/i18n';

export const DAYS_OF_THE_WEEK = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday'
};

export function getDayOfTheWeekDisplay (inhibitStatus: any) {
  switch (inhibitStatus) {
  case DAYS_OF_THE_WEEK.SUNDAY:
    return `${i18n.t('global.weekdays.sunday')}`;
  case DAYS_OF_THE_WEEK.MONDAY:
    return `${i18n.t('global.weekdays.monday')}`;
  case DAYS_OF_THE_WEEK.TUESDAY:
    return `${i18n.t('global.weekdays.tuesday')}`;
  case DAYS_OF_THE_WEEK.WEDNESDAY:
    return `${i18n.t('global.weekdays.wednesday')}`;
  case DAYS_OF_THE_WEEK.THURSDAY:
    return `${i18n.t('global.weekdays.thursday')}`;
  case DAYS_OF_THE_WEEK.FRIDAY:
    return `${i18n.t('global.weekdays.friday')}`;
  case DAYS_OF_THE_WEEK.SATURDAY:
    return `${i18n.t('global.weekdays.saturday')}`;
  }
  return '';
}
