import Vue from 'vue';
import Vuex, { Commit, Dispatch } from 'vuex';
import { i18n } from '@/i18n';

import {
  CardInfo,
  saveParameters,
  saveSchedulePayload,
  updateRecipient,
  updateSchedulePayload,
  updateVehicles
} from './types';

import vehicles from './vehicles';
import { state as vehicleState } from './vehicles/state';
import featureFlags from './modules/featureFlags';

import { alertHistoryResultType } from '@/store/types';
import _, { cloneDeep, groupBy } from 'lodash';

import notificationService from '@/services/NotificationService';
import alertService from '@/services/AlertService';

import commandService, { CommandType } from '@/services/CommandService';

Vue.use(Vuex);

export const state = {
  latitude: '0',
  longitude: '0',
  locationCordinates: '0',
  isLoggedIn: false,
  isFsiEnabled: false,
  userCompanyWhitelisted: false,
  isMfaStepUpRequired: false,
  tokenExpiration: 0,
  activeIndex: 0,
  scheduleName: '',
  supportNumber: '',
  alertRecipients: [],
  selectedText: false,
  selectedEmail: false,
  scheduleType: '',
  isLocNotify: false,
  locationClass: 'd-none',
  locationClassAlert: 'd-none',
  vehicleName: '',
  displayLocationButton: true,
  startTime: { hour: '12', min: '00', amPm: 'AM' },
  endTime: { hour: '12', min: '00', amPm: 'AM' },
  startDate: '',
  endDate: '',
  timeZone: 'UTC',
  selected: [''],
  inputArray: [],
  alertType: '',
  checkedItem: {},
  loadNotificationComplete: false,
  displayModal: false,
  totalAlertSize: 0,
  alerts: [],
  paginationSize: 25,
  loading: false,
  pageNum: 0,
  sortParam: 'timestamp,desc',
  vinAlertMap: {},
  scheduleStatus: '',
  alertHistorySelectedTypes: new Array<string>(),
  alertSearchKey: '',
  fsiTimeZone: 'US/Eastern',
  timeZoneOptions: [
    { text: `${i18n.t('timeZones.easternTime')}`, value: '-05' },
    { text: `${i18n.t('timeZones.centralTime')}`, value: '-06' },
    { text: `${i18n.t('timeZones.mountainTime')}`, value: '-07' },
    { text: `${i18n.t('timeZones.pacificTime')}`, value: '-08' },
    { text: `${i18n.t('timeZones.alaskaTIme')}`, value: '-09' },
    { text: `${i18n.t('timeZones.hawaiiTime')}`, value: '-10' },
    { text: `${i18n.t('timeZones.westernEuropeanTime')}`, value: '+00' },
    { text: `${i18n.t('timeZones.centralEuropeanTIme')}`, value: '+01' },
    { text: `${i18n.t('timeZones.easternEuropeanTIme')}`, value: '+02' }
  ],
  timeZoneConverter: [
    { text: `${i18n.t('timeZones.easternTime')}`, value: `${i18n.t('timeZones.abbreviation.easternTime')}` },
    { text: `${i18n.t('timeZones.centralTime')}`, value: `${i18n.t('timeZones.abbreviation.centralTime')}` },
    { text: `${i18n.t('timeZones.mountainTime')}`, value: `${i18n.t('timeZones.abbreviation.mountainTime')}` },
    { text: `${i18n.t('timeZones.pacificTime')}`, value: `${i18n.t('timeZones.abbreviation.pacificTime')}` },
    { text: `${i18n.t('timeZones.alaskaTIme')}`, value: `${i18n.t('timeZones.abbreviation.alaskaTIme')}` },
    { text: `${i18n.t('timeZones.hawaiiTime')}`, value: `${i18n.t('timeZones.abbreviation.hawaiiTime')}` },
    { text: `${i18n.t('timeZones.westernEuropeanTime')}`, value: `${i18n.t('timeZones.abbreviation.westernEuropeanTime')}` },
    { text: `${i18n.t('timeZones.centralEuropeanTIme')}`, value: `${i18n.t('timeZones.abbreviation.centralEuropeanTIme')}` },
    { text: `${i18n.t('timeZones.easternEuropeanTIme')}`, value: `${i18n.t('timeZones.abbreviation.easternEuropeanTIme')}` }
  ],
  featureFlags: []
};
type State = typeof state;

export const getters = {
  getElementIndex: (state: State) => (id:string) => {
    return state.inputArray.findIndex((elem: CardInfo) => elem.correlationID === id);
  },
  getSupportNumber: (state: State) => {
    return state.supportNumber ? state.supportNumber : '';
  },
  isValidSelection: (state: State) => {
    return !_.isEmpty(state.checkedItem);
  },
  getCheckedItem: (state: State) => {
    return state.checkedItem;
  },
  getAlertType: (state: State) => {
    return state.alertType;
  },
  getAlertRows: (state: State) => {
    return state.totalAlertSize;
  },
  getAlerts: (state: State) => {
    return state.alerts;
  },
  getPaginationSize: (state: State) => {
    return state.paginationSize;
  },
  getAlertsTableState: (state: State) => {
    return state.loading;
  },
  isErrorStatus: (state: State) => {
    return state.scheduleStatus === 'error';
  },
  getStartTime: (state: State) => {
    return state.startTime;
  },
  getEndTime: (state: State) => {
    return state.endTime;
  },
  getScheduleType: (state: State) => {
    return state.scheduleType;
  },
  isLoggedIn: (state: State) => {
    return state.isLoggedIn;
  },
  isMfaStepUpRequired: (state: State) => {
    return state.isMfaStepUpRequired;
  },
  isFsiEnabled: (state: State) => {
    return state.isFsiEnabled;
  },
  getAlertNames: (state: State) => {
    if (state.inputArray) {
      return state.inputArray.map((n: CardInfo) => n.scheduleName.toLowerCase());
    }
    return [];
  },
  isUserCompanyWhitelisted: (state: State) => {
    return state.userCompanyWhitelisted;
  },
  getFsiTimeZone: (state: State) => {
    return state.fsiTimeZone;
  }
};

export const actions = {
  clearWizard: ({ commit } : {commit:Commit}) => {
    commit('clearWizard');
    commit('vehicles/CLEAR_SELECTED');
  },
  assignAlertType: ({ commit }: {commit:Commit}, payload: string) => {
    commit('assignAlertType', payload);
  },
  editNotification: async ({ commit, state }: {commit:Commit, state:State}, payload: CardInfo) => {
    const response = await notificationService.editNotification(payload);
    if (response === null) {
      commit('setScheduleStatus', 'error');
    } else {
      commit('setScheduleStatus', '');
    }
  },
  removeAlertCard: ({ commit }: {commit:Commit}, payload: CardInfo) => {
    commit('removeCard', payload);
  },
  updateSchedule: async ({ commit, getters, dispatch, state }: {commit:Commit, getters: any, dispatch: Dispatch, state:State}, payload: updateSchedulePayload) => {
    const elementIndex: number = getters.getElementIndex(payload.originalCardInfo.correlationID);

    const { updatedDays, updatedStartTime, updatedEndTime, updatedTimeZone } = payload;

    let card : CardInfo = Object.assign({}, state.inputArray[elementIndex]);
    card.days = updatedDays;
    card.startTime = updatedStartTime;
    card.endTime = updatedEndTime;
    card.timeZone = updatedTimeZone;
    await dispatch('editNotification', card);
    if (state.scheduleStatus !== 'error') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      card.startTime = updatedStartTime.hour + ':' + updatedStartTime.min + updatedStartTime.amPm;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      card.endTime = updatedEndTime.hour + ':' + updatedEndTime.min + updatedEndTime.amPm;
      commit('updateSchedule', { card, elementIndex });
    } else {
      card = payload.originalCardInfo;
      commit('updateSchedule', { card, elementIndex });
    }
  },
  updateVehicles: async ({ commit, getters, dispatch, state }: {commit:Commit, getters: any, dispatch: Dispatch, state:State}, payload: updateVehicles) => {
    const elementIndex = getters.getElementIndex(payload.originalCardInfo.correlationID);
    if (state.scheduleStatus !== 'error') {
      commit('updateVehicles', { payload, elementIndex });
    }
    await dispatch('editNotification', state.inputArray[elementIndex]);
  },
  saveTimeZone: ({ commit }: {commit:Commit}, payload: string) => {
    commit('saveTimeZone', payload);
  },
  saveScheduleType: ({ commit }:{commit:Commit}, payload: string) => {
    commit('saveScheduleType', payload);
  },
  saveStartTime: ({ commit }:{commit:Commit}, payload: string) => {
    commit('saveStartTime', payload);
  },
  saveEndTime: ({ commit }:{commit:Commit}, payload: string) => {
    commit('saveEndTime', payload);
  },
  saveStartDate: ({ commit }:{commit:Commit}, payload: string) => {
    commit('saveStartDate', payload);
  },
  saveEndDate: ({ commit }:{commit:Commit}, payload: string) => {
    commit('saveEndDate', payload);
  },
  saveSchedule: ({ commit }:{commit:Commit}, payload: saveSchedulePayload) => {
    commit('saveSchedule', payload);
  },
  saveParameters: ({ commit }:{commit:Commit}, payload:saveParameters) => {
    commit('saveParameters', payload);
  },
  saveInputData: async ({ commit }: { commit:Commit }, payload:CardInfo) => {
    const response = await notificationService.postNotification(payload);
    if (response === null) {
      commit('setScheduleStatus', 'error');
      return;
    }
    commit('setScheduleStatus', '');
    const cardInfoObject = await notificationService.convertResponseObjectToCardInfo(response);
    commit('saveInputData', cardInfoObject);
  },
  getNotifications: async ({ commit }:{ commit:Commit }) => {
    const notifications = await notificationService.getNotification();
    commit('saveNotification', notifications);
    commit('updateLoadNotificationStatus');
  },
  updateRecipient: async ({ commit, getters, state, dispatch }:{commit:Commit, getters: any, state:State, dispatch: Dispatch}, payload: updateRecipient) => {
    const elementIndex = getters.getElementIndex(payload.originalCardInfo.correlationID);
    const { newRecipients, newName } = payload;
    const card : CardInfo = Object.assign({}, state.inputArray[elementIndex]);
    card.recipients = newRecipients;
    card.scheduleName = newName;

    if (state.scheduleStatus !== 'error') {
      commit('updateRecipient',
        { card, elementIndex });
    }
    await dispatch('editNotification', card);
  },
  updateName: async ({ commit, getters, dispatch, state }: {commit:Commit, getters: any, dispatch: Dispatch, state: State}, card: CardInfo) => {
    const cardIndex = getters.getElementIndex(card.correlationID);
    await dispatch('editNotification', state.inputArray[cardIndex]);
    if (state.scheduleStatus !== 'error') {
      commit('updateName', { card, cardIndex });
    }
  },
  setCheckedItem: ({ commit }: {commit:Commit}, payload: { id:string, name:string }) => {
    commit('setCheckedItem', payload);
  },
  deleteNotification: async ({ commit, state }: {commit:Commit, state: State}, id:string) => {
    const response = await notificationService.deleteNotification(parseInt(id));
    if (response === null) {
      commit('setScheduleStatus', 'error');
      return;
    }
    commit('setScheduleStatus', '');
  },
  sendCommand: ({ getters }: { getters: any }, type: CommandType): Promise<void> => {
    const vehicle = getters['vehicles/getFirstSelected'];
    const command = { vin: vehicle.vin, type: type };
    return commandService.sendCommand(command);
  },
  sendLocation: ({ getters }: { getters: any }): Promise<void> => {
    const vehicle = getters['vehicles/getFirstSelected'];
    return commandService.sendLocation(vehicle.vin);
  },
  async getAlertHistory ({ commit, state }: {commit: Commit, state: State}) {
    commit('toggleBusy');
    const response = await alertService.getAlertHistory(state.pageNum, state.paginationSize, state.sortParam, state.alertHistorySelectedTypes, state.alertSearchKey);
    commit('saveAlertHistory', response);
    commit('toggleBusy');
  },
  mapAssignedAlerts: ({ commit, state }:{commit: Commit, state: State }) => {
    const mapping = state.inputArray.flatMap( // 1:1 mapping
      ({
        selected,
        scheduleName
      }) => Object.values(selected).map((item: any) => ({
        vin: item.vin,
        name: scheduleName
      }))
    );
    const groupAlertsByVin = groupBy(mapping, 'vin');
    commit('vinAlertMap', groupAlertsByVin);
  },
  setSortParam: ({ commit, state }:{commit: Commit, state: State}, sortParam: string) => {
    commit('setSortParam', sortParam);
  },
  clearSupportNumber: ({ commit } : {commit:Commit}) => {
    commit('updateSupportNumber', { supportNumber: '' });
  }
};

export const mutations = {
  vehicleName: (state: State, vehicleName: string) => {
    state.vehicleName = vehicleName;
  },
  latitude: (state: State, latitude: string) => {
    state.latitude = latitude;
  },
  longitude: (state: State, longitude: string) => {
    state.longitude = longitude;
  },
  locationCordinates: (state: State, locationCordinates: string) => {
    state.locationCordinates = locationCordinates;
  },
  locationClass: (state: State, locationClass: string) => {
    state.locationClass = locationClass;
  },
  locationClassAlert: (state: State, locationClassAlert: string) => {
    state.locationClassAlert = locationClassAlert;
  },
  isLocNotify: (state: State, isLocNotify: boolean) => {
    state.isLocNotify = isLocNotify;
  },
  displayLocationButton: (state: State, displayLocationButton: boolean) => {
    state.displayLocationButton = displayLocationButton;
  },
  loggedIn: (state: State, isLoggedIn: boolean) => {
    state.isLoggedIn = isLoggedIn;
  },
  fsiEnabled: (state: State, fsiEnabled: boolean) => {
    state.isFsiEnabled = fsiEnabled;
  },
  setLoggedIn: (state: State, isLoggedIn: boolean) => {
    state.isLoggedIn = isLoggedIn;
  },
  setFsiEnabled: (state: State, isFsiEnabled: boolean) => {
    state.isFsiEnabled = isFsiEnabled;
  },
  setMfaStepUpRequired: (state: State, isMfaStepUpRequired: boolean) => {
    state.isMfaStepUpRequired = isMfaStepUpRequired;
  },
  tokenExpiration: (state: State, tokenExpiration: number) => {
    state.tokenExpiration = tokenExpiration;
  },
  updateLoadNotificationStatus: (state: State) => {
    state.loadNotificationComplete = true;
  },
  clearWizard: (state: State) => {
    state.activeIndex = 0;
    state.scheduleName = '';
    state.alertRecipients = [];
    state.selectedText = true;
    state.selectedEmail = true;
    state.startTime = { hour: '12', min: '00', amPm: 'AM' };
    state.endTime = { hour: '12', min: '00', amPm: 'AM' };
    state.startDate = '';
    state.endDate = '';
    state.selected = [];
    state.alertType = '';
    state.checkedItem = {};
    state.sortParam = 'timestamp,desc';
    state.pageNum = 0;
    vehicleState.vehicleFilters = {};
    vehicleState.page = {
      totalElements: 0,
      numberOfElements: 0,
      results: []
    };
  },
  assignAlertType: (state: State, alertType: string) => {
    state.alertType = alertType;
  },
  incrementProgressbarIndex: (state: State) => {
    state.activeIndex++;
  },
  decrementProgressbarIndex: (state: State) => {
    state.activeIndex--;
  },
  saveParameters: (state: any, payload: saveParameters) => {
    state.scheduleName = payload.scheduleName;
    state.alertRecipients = payload.value;
    state.selectedText = payload.selectedText;
    state.selectedEmail = payload.selectedEmail;
  },
  updateSupportNumber: (state: any, payload: any) => {
    state.supportNumber = payload.supportNumber;
  },
  saveScheduleType: (state: State, payload: string) => {
    state.scheduleType = payload;
  },
  saveStartTime: (state: State, payload: any) => {
    state.startTime = payload;
  },
  saveEndTime: (state: State, payload: any) => {
    state.endTime = payload;
  },
  saveStartDate: (state: State, payload: string) => {
    state.startDate = payload;
  },
  saveEndDate: (state: State, payload: string) => {
    state.endDate = payload;
  },
  saveTimeZone: (state: State, payload: string) => {
    state.timeZone = payload;
  },
  saveFsiTimeZone: (state: State, payload: string) => {
    state.fsiTimeZone = payload;
  },
  removeCard: (state: State, payload: CardInfo) => {
    const cardToRemove = state.inputArray.findIndex((element) => element === payload);
    state.inputArray.splice(cardToRemove, 1);
  },
  resetTimeZone: (state: State, payload: string) => {
    state.timeZone = payload;
  },
  saveSchedule: (state: State, payload: any) => {
    state.startTime = payload.startTime;
    state.endTime = payload.endTime;
    state.timeZone = payload.timeZone;
    state.selected = payload.selected;
  },
  saveInputData: (state: any, payload: CardInfo) => {
    state.inputArray.push(payload);
  },
  saveNotification: (state: State, payload: any) => {
    state.inputArray = payload;
  },
  updateRecipient: (state: State, { card, elementIndex }: { card: CardInfo, elementIndex: number }) => {
    Vue.set(state.inputArray, elementIndex, card);
  },
  updateSchedule: (state: State, { card, elementIndex }: { card: CardInfo, elementIndex: number }) => {
    Vue.set(state.inputArray, elementIndex, card);
  },
  updateVehicles: (state: State, { payload, elementIndex }: { payload: updateVehicles, elementIndex: number }) => {
    const { updateSelected, updatedSelectedLength } = payload;
    const newInputArray: Array<CardInfo> = cloneDeep(state.inputArray);
    newInputArray[elementIndex].selected = updateSelected;
    newInputArray[elementIndex].numberOfVehicles = updatedSelectedLength;
    Object.assign(state.inputArray, newInputArray);
  },
  setCheckedItem: (state: State, payload: string) => {
    state.checkedItem = payload;
  },
  updateName: (state: State, { card, cardIndex }: { card: CardInfo, cardIndex: number }) => {
    Vue.set(state.inputArray, cardIndex, card);
  },
  deleteNotification: (state: State, id: number) => {
    const newInputArray = _.filter(state.inputArray, ({ correlationID }) => correlationID !== id);
    state.inputArray = newInputArray;
  },
  toggleModal: (state: State) => {
    state.displayModal = !state.displayModal;
    if (state.displayModal) {
      state.locationClass = 'd-none';
    }
  },
  saveAlertHistory: (state: any, data: { totalSize: number, results: Array<alertHistoryResultType> }) => {
    state.totalAlertSize = data.totalSize;
    state.alerts = data.results;
  },
  setPaginationSize: (state: State, pageSize: number) => {
    state.paginationSize = pageSize;
  },
  setPageNum: (state: State, pageNum: number) => {
    state.pageNum = pageNum;
  },
  setSortParam: (state: State, sortParam: string) => {
    state.sortParam = sortParam;
  },
  toggleBusy: (state: State) => {
    state.loading = !state.loading;
  },
  vinAlertMap: (state: State, vinAlertMap: any) => {
    state.vinAlertMap = vinAlertMap;
  },
  setScheduleStatus: (state: State, status: string) => {
    state.scheduleStatus = status;
  },
  setAlertHistorySelectedTypes: (state: State, alertTypes: Array<string>) => {
    state.alertHistorySelectedTypes = alertTypes;
  },
  setAlertSearchKey: (state: State, alertSearchKey: string) => {
    state.alertSearchKey = alertSearchKey;
  },
  setUserCompanyWhitelisted: (state: State, userCompanyWhitelisted: boolean) => {
    state.userCompanyWhitelisted = userCompanyWhitelisted;
  }
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: { vehicles, featureFlags }
});
