<template>
  <fcs-container fluid align-v="center">
    <Header class="mt-3" :title="$t('inconsistentLocationAlert.title')" />
    <div class="mt-2"></div>
    <fcs-row v-if="activeIndex===0">
      <fcs-col class="mx-auto" lg="12" xl="12">
        <fcs-progress-tracker
          :currentValue="$t('global.progressBar.setParam')"
          :steps="$t('global.progressBarTwoStepElements')"
          :all-steps-completed="false"
          :small-version="false"
        />
      </fcs-col>
      <fcs-col class="mx-auto" lg="8">
        <SetParameters :activeIndex='0' :cardTitle="$t('global.cardSubtitle')"></SetParameters>
      </fcs-col>
    </fcs-row>
      <fcs-container fluid v-if="activeIndex===1">
        <fcs-row>
          <fcs-col class="mx-auto" lg="12" xl="12">
            <fcs-progress-tracker
              :currentValue="$t('global.progressBar.assignVehicle')"
              :steps="$t('global.progressBarTwoStepElements')"
              :all-steps-completed="false"
              :small-version="false"
            />
          </fcs-col>
        </fcs-row>
        <AssignVehicle :cardTitle="$t('global.assignVehicles')" ></AssignVehicle>
      </fcs-container>
  </fcs-container>
</template>

<script>
import SetParameters from '@/components/common/SetParameters';
import Header from '@/components/common/Header';
import AssignVehicle from '@/components/common/AssignVehicle';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CreateInconsistentLocationAlert',
  components: { SetParameters, Header, AssignVehicle },
  mounted () {
    this.clearWizard();
    this.assignAlertType(this.$route.params.alertType);
  },
  methods: { ...mapActions({ clearWizard: 'clearWizard', assignAlertType: 'assignAlertType' }) },
  computed: {
    ...mapState(['activeIndex'])
  }
};
</script>

<style scoped lang="scss">
@import '../assets/css/vue-multiselect.min.css';
</style>
<style scoped lang="scss">
</style>
