import Vue from 'vue';
import App from './App.vue';
import store from './store';
import FcsUI from 'fcsui';
import VueI18n from 'vue-i18n';
import router from '@/router/router';
import 'fcsui/src/scss/index.scss';
import './assets/css/main.scss';
import FeaturesService from '@/services/FeaturesService';
import { i18n } from '@/i18n';
import Multiselect from 'vue-multiselect';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

const preloadFeatureFlags = async function (store:any, FeaturesService:any) {
  try {
    await store.dispatch('featureFlags/updateFeatures', { service: FeaturesService });
  } catch (error) {
    throw new Error('Feature Flag API failed to load' + error);
  }
};

const preloadAppNonCriticalState = async function (store:any, FeaturesService:any) {
  try {
    await preloadFeatureFlags(store, FeaturesService);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

(async () => {
  await preloadAppNonCriticalState(store, FeaturesService);
  Vue.component('multiselect', Multiselect);

  Vue.config.productionTip = false;
  Vue.use(FcsUI);
  Vue.use(VueI18n);

  Vue.use(BootstrapVue);
  Vue.use(BootstrapVueIcons);

  new Vue({
    router,
    store,
    render: h => h(App),
    i18n,
    provide: FeaturesService
  }).$mount('#app');
})();
