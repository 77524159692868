import Vue from 'vue';
import { STATES } from '@/constants';
import { clone } from 'lodash';
import { Schedule } from '@/store/types';

const mutations = {
  SET_HAS_FSI_ENROLLED_VEHICLES (state: any, payload: any) {
    state.hasFsiEnrolledVehicles = payload.hasFsiEnrolledVehicles;
  },
  SET_SELECTED_VINS (state: any, payload: any) {
    state.selectedVins = payload.selectedVins;
  },
  RESET (state: any) {
    Object.assign(state, {
      errorMessage: null,
      currentState: STATES.LOADING,
      page: {
        totalElements: 0,
        results: [],
        numberOfElements: 0
      },
      currentPageSelection: {},
      pageRequest: {
        ...state.pageRequest
      }
    });
    state.pageRequest.page = 0;
  },
  LOADED_STATE (state: any, payload: any) {
    state.errorMessage = null;
    state.page = {
      results: payload.response.results,
      numberOfElements: payload.response.results.length,
      totalElements: payload.response.totalSize
    };
    state.currentState = STATES.LOADED;
  },
  SCHEDULED_INHIBIT_RESPONSE (state: any, payload: any) {
    state.scheduledPostedResponse = payload;
  },
  UPDATE_AD_HOC_INHIBIT_RESPONSE (state: any, payload: any) {
    state.adhocInhibitResponse = payload;
  },
  UPDATE_ADHOC_DEINHIBIT_RESPONSE (state: any, payload: any) {
    state.adhocDeInhibitResponse = payload;
  },
  SET_FSI_SEARCH (state: any, payload: any) {
    state.fsiSearch = payload;
  },
  FSI_LOADED_STATE (state: any, payload: any) {
    state.errorMessage = null;
    state.page = {
      results: payload.response.results[0].vehicles,
      numberOfElements: payload.response.resultSize,
      totalElements: payload.response.totalResultSize
    };
    state.currentState = STATES.LOADED;
  },
  FILTER_LOADED_STATE (state: any, payload: any) {
    state.filterErrorMessage = null;
    state.ffmFilters = {
      makes: payload.response.makes,
      models: payload.response.models,
      years: payload.response.years,
      inhibitStatus: payload.response.inhibitStatus,
      batteryLevel: payload.response.batteryLevel,
      scheduleName: payload.response.scheduleName
    };
    state.filterState = STATES.LOADED;
  },
  FSI_FILTERS_STATE (state: any, payload: any) {
    state.fsiVehicleFilters = payload.response;
  },
  ERROR_STATE (state: any, payload: any) {
    state.currentState = STATES.ERROR;
    state.errorMessage = payload.message;
  },
  FILTER_ERROR_STATE (state: any, payload: any) {
    state.filterState = STATES.ERROR;
    state.filterErrorMessage = payload.message;
  },
  FSI_ERROR_STATE (state: any, payload: any) {
    state.currentState = STATES.ERROR;
    state.errorMessage = payload.message;
  },
  SET_STATE (state: any, currentState: string) {
    state.currentState = currentState;
  },
  SET_FILTER_STATE (state: any, filterState: string) {
    state.filterState = filterState;
  },
  DESELECT_ALL (state: any) {
    state.selection = {};
  },
  GO_TO_PAGE (state: any, payload: any) {
    state.pageRequest.page = payload.pageRequested - 1;
  },
  NEXT_PAGE (state: any) {
    if (!state.page.last) {
      state.pageRequest.page += 1;
    }
  },
  PREV_PAGE (state: any) {
    if (!state.page.first) {
      state.pageRequest.page -= 1;
    }
  },
  FIRST_PAGE (state: any) {
    if (state.page.page !== 0) {
      state.pageRequest.page = 0;
    }
  },
  LAST_PAGE (state: any) {
    if (!state.page.last && state.page.totalPages) {
      state.pageRequest.page = state.page.totalPages - 1;
    }
  },
  CHANGE_PAGE_SIZE (state: any, payload: any) {
    if (payload.size && payload.size > 0) {
      state.pageRequest.size = payload.size;
    }
  },
  SET_FSI_VEHICLE_FILTERS (state: any, data: any) {
    state.vehicleFilters = data;
  },
  SET_VEHICLE_SEARCH_QUERY (state: any, data: any) {
    Vue.set(state.vehicleFilters, 'vehicleSearch', data);
  },
  SET_VEHICLE_FILTER_BY_KEY (state: any, payload: any) {
    Vue.set(state.vehicleFilters, payload.key, payload.data);
  },
  REMOVE_VEHICLE_FILTER_BY_KEY (state: any, payload: any) {
    Vue.delete(state.vehicleFilters, payload.key);
  },
  SET_VEHICLE_FILTERS (state: any, data: any) {
    Vue.set(state, 'vehicleFilters', data);
  },
  SET_AVAILABLE_FILTERS (state: any, data: any) {
    Vue.set(state, 'availableFilters', data);
  },
  ADD_SELECTED (state: any, data: any) {
    Vue.set(state, 'selection', { ...state.selection, ...data });
  },
  SET_SELECTED (state: any, array: any) {
    state.selection = array;
  },
  SET_PAGINATION_QUERY (state: any, payload: any) {
    state.page = payload;
  },
  REMOVE_SELECTED (state: any, payload: string) {
    if (payload && Array.isArray(payload)) {
      payload.forEach(vin => {
        Vue.delete(state.selection, payload);
      });
    } else {
      Vue.delete(state.selection, payload);
    }
  },
  CLEAR_SELECTED (state: any) {
    state.selection = {};
  },
  SET_PREV_VEHICLE_FILTER (state: any) {
    state.previousVehicleFilters = clone(state.vehicleFilters);
  },
  RESET_VEHICLE_FILTER (state: any) {
    state.vehicleFilters = {};
    state.previousVehicleFilters = {};
  },
  SET_EXISTING_SCHEDULES (state: any, payload: any) {
    state.existingSchedules = payload;
  },
  SELECT_ALL_VEHICLES (state: any, flag: boolean) {
    state.selectAllVehicles = flag;
  },
  SET_VINS_WITH_OVERLAPPING_SCHEDULES_SIZE (state: any, payload: any) {
    state.errorMessage = null;
    state.vinsWithOverlapSize = payload.response;
  },
  SET_FLEET_BATTERY_VIN_MAP (state: any, payload: any) {
    state.fleetBatteryVinMap = [];
    const vehicleCount = payload.response.results[0].vehicles.length;
    for (let i = 0; i < vehicleCount; i++) {
      state.fleetBatteryVinMap.push({
        vin: payload.response.results[0].vehicles[i].vin,
        batteryLevel: payload.response.results[0].vehicles[i].batteryLevel
      });
    }
  },
  SET_MAX_RESULTS (state: any) {
    state.pageRequest.size = state.page.numberOfElements;
  },
  RESET_PAGE_REQUEST_SIZE (state: any, pageSize: any) {
    state.pageRequest.size = pageSize;
  },
  SET_REQUEST_ID (state: any, payload: any) {
    state.requestId = payload;
  },
  SET_EDIT_SCHEDULE (state: any, payload: any) {
    state.schedule = payload.schedule;
  },
  UPDATE_SCHEDULE_NAME (state: any, requestObj: any) {
    state.existingSchedules[state.existingSchedules.findIndex((elem: Schedule) => elem.requestId === requestObj.requestId)]
      .scheduleName = requestObj.scheduleName;
  },
  SET_ALERT_PREFERENCES (state: any, payload: any) {
    state.textAlertPrefOn = payload.response.sms;
    state.emailAlertPrefOn = payload.response.email;
  },
  SET_USER_PREFERENCES_CALL_STATUS (state: any, status: any) {
    if (status.status === 200) {
      state.isUserPrefCallSuccess = true;
    } else {
      state.isUserPrefCallSuccess = false;
    }
  }
};

export { mutations };
