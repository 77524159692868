<template>
  <fcs-card no-body :id="`card-element-${this.card.preferenceId}`" class="scheduleCardStyle">
    <fcs-card-body class="flexCardLayout">
      <div>
        <fcs-editable-text
          :default-error-text="translatedStrings.validationDefault"
          id="sma-editable-text"
          :saveText="translatedStrings.saveButtonText"
          :cancelText="translatedStrings.cancelButtonText"
          :ariaHidden="false"
          :value="card.scheduleName"
          :validationRules="validationRules"
          :validatesOn='["input", "blur", "change"]'
          class="editableScheduleName"
          @edit="disableEditButtons($event)"
          @save="editName(card.requestId, ...arguments)"
          @cancel="enableEditButtons()"
        />
        <fcs-divider color="medium" />
      </div>
      <div class="cardContainer">
        <div>
          {{ $t('global.alertCardInfo.recipientTitle') }}
          <br>
          <span class="subtitleText">{{ getRecipientNames(card.recipients) }}</span>
        </div>
        <div @click="editRecipients()" class="linkText">
          <b-iconstack>
            <b-icon-plus stacked style="color: #0064D4; font-size: 1.5rem;" scale="1.75"/>
            <b-icon-dash stacked style="color: #0064D4; font-size: 1.5rem;" scale="1.75" shift-v="-9"/>
          </b-iconstack>
          {{ $t('vehicleSecurity.buttons.updateRecipient') }}
        </div>
      </div>
      <div><fcs-divider color="medium"/></div>
      <div class="cardContainer" v-if="card.alertType === ALERT_TYPE.security">
        <div>
          {{ $t('global.alertCardInfo.scheduleTitle') }}
          <br>
          {{ formatTime(card.startTime) }} - {{ formatTime(card.endTime) }}
          <br>
          <span v-for="(item,index) in card.days" :key="index" class="subtitleText">
            {{ displayDay(item) }}
          </span>
        </div>
        <div @click="editSchedule()">
          <b-icon-pencil style="color: #0064D4; font-size: 1.5rem;"/>
          <span class="linkText"> {{ $t('vehicleSecurity.buttons.updateSchedule') }} </span>
        </div>
      </div>
      <div v-if="card.alertType === ALERT_TYPE.security"><fcs-divider color="medium"/></div>
      <div class="cardContainer">
        <div>
          {{ $t('global.alertCardInfo.vehicleTitle') }}
          <br/>
          <span v-if="card.numberOfVehicles == 1" class="vehicleText"> {{ card.numberOfVehicles }} {{ $t('securityAlert.tableHeader.vehicle') }} </span>
          <span v-else class="vehicleText"> {{ card.numberOfVehicles }} {{ $t('global.alertCardInfo.vehicleTitle') }} </span>
        </div>
        <div @click="editVehicle()" class="linkText">
          <b-iconstack>
            <b-icon-plus stacked style="color: #0064D4; font-size: 1.5rem;" scale="1.75"/>
            <b-icon-dash stacked style="color: #0064D4; font-size: 1.5rem;" scale="1.75" shift-v="-9"/>
          </b-iconstack>
          {{ $t('vehicleSecurity.buttons.updateVehicle') }}
        </div>
      </div>
      <div><fcs-divider color="medium"/></div>
      <div class="delete">
        <span
          v-fcs-modal.deleteModal
          @click="toggleModal()"
          data-qa="delete-button">
          <b-icon-trash style="color: red;"/> {{ $t('global.delete') }}
        </span>
        <fcs-modal id="deleteModal" v-if="showDeleteModal" :title="$t('global.deleteAlert')" centered hide-footer @hide="toggleModal">
          <p class="subtitle"> {{ $t('global.deletePrompt') }} "{{this.card.scheduleName}}"?
          </p>
          <div>
            <fcs-row class="mr-0" align-h="end">
              <p @click="toggleModal" class="cancelLink"> {{ $t('global.cancel') }} </p>
              <fcs-button data-qa="delete-confirm-button" variant="danger" @click="handleDelete()" size="lg" class="ml-4"> {{ $t('global.delete') }} </fcs-button>
            </fcs-row>
          </div>
        </fcs-modal>
      </div>
    </fcs-card-body>
  </fcs-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { i18n } from '@/i18n';
import { ALERT_TYPE } from '@/constants';
import { getDayOfTheWeekDisplay } from '@/constants/DaysOfTheWeek';

const specialChar = function (value) {
  return !/[^A-Z a-z0-9]/.test(value);
};
const maxLength = function (value) {
  return value.length <= 30;
};

export default {
  name: 'AlertCard',
  props: {
    card: Object
  },
  data () {
    return {
      ALERT_TYPE,
      currentDuplicateName: '',
      isEditing: false,
      originalScheduleName: this.card.scheduleName,
      showDeleteModal: false,
      validationRules: [
        {
          isValid: specialChar,
          error: `${i18n.t('inhibit.editableText.validationSpecialCharacters')}`
        },
        {
          isValid: maxLength,
          error: `${i18n.t('inhibit.editableText.validationMaxLength')}`
        },
        {
          isValid: this.duplicateNames,
          error: `${i18n.t('inhibit.editableText.validationDuplicateName')}`
        }
      ]
    };
  },
  methods: {
    ...mapActions(['saveParameters', 'saveSchedule', 'updateName', 'removeAlertCard', 'deleteNotification', 'saveStartTime', 'saveEndTime', 'saveTimeZone']),
    formatTime (time) {
      return moment(time, 'h:mma').format('h:mm A');
    },
    displayDay (day) {
      return getDayOfTheWeekDisplay(day);
    },
    convertTimeStringToObject (timeString) {
      var colonIndex = timeString.indexOf(':');
      var timeObj = {
        hour: timeString.substring(0, colonIndex),
        min: timeString.substring(colonIndex + 1, colonIndex + 3),
        amPm: timeString.substring(colonIndex + 3).toUpperCase()
      };
      return timeObj;
    },
    getRecipientNames (recipientInfo) {
      const nameArray = recipientInfo.map((item) => item.name);
      return nameArray.join(', ');
    },
    editRecipients () {
      this.saveParameters({ scheduleName: this.card.scheduleName, value: this.card.recipients, selectedText: this.card.selectedText, selectedEmail: this.card.selectedEmail });
      this.$router.push({ name: 'Edit', path: '/edit/recipients', params: { id: this.card.correlationID, type: 'recipients' } });
    },
    editSchedule () {
      var startTimeObj = this.convertTimeStringToObject(this.card.startTime);
      var endTimeObj = this.convertTimeStringToObject(this.card.endTime);
      this.saveSchedule({ startTime: this.card.startTime, endTime: this.card.endTime, selected: this.card.days, type: 'recipients' });
      // Save the initial timezone in case we are using cached data instead of GET data
      this.saveTimeZone(this.card.timeZone);
      this.saveStartTime(startTimeObj);
      this.saveEndTime(endTimeObj);
      this.convertTimeZone(this.card.timeZone);
      this.$router.push({ name: 'Edit', path: '/edit/schedule', params: { id: this.card.correlationID, type: 'schedule' } });
    },
    convertTimeZone (timeZoneValue) {
      for (const i in this.timeZoneConverter) {
        if (timeZoneValue === this.timeZoneConverter[i].value) {
          this.saveTimeZone(this.timeZoneConverter[i].text);
        }
      }
    },
    editVehicle () {
      this.$store.commit('vehicles/SET_SELECTED', this.card.selected);
      this.$router.push({ name: 'Edit', path: '/edit/vehicles', params: { id: this.card.correlationID, type: 'vehicle' } });
    },

    toggleEditMode () {
      this.isEditing = !this.isEditing;
    },

    async editName (requestId, e, input) {
      input = input.trim();
      this.isEditing = false;
      this.card.scheduleName = input;
      await this.updateName(this.card);
      if (this.isErrorStatus) {
        this.card.scheduleName = this.originalScheduleName;
      }
      this.originalScheduleName = input;
      this.enableEditButtons();
      const message = this.isErrorStatus ? i18n.t('global.editErrorMessage') : i18n.t('global.editConfirmation', { name: this.originalScheduleName });
      this.$emit('updated', message);
    },
    disableEditButtons (event) {
      this.currentDuplicateName = event.trim();
      const allButtons = document.querySelectorAll('button[data-qa="fcs-editable-text-edit-button"]');
      allButtons.forEach(button => {
        button.setAttribute('disabled', 'disabled');
      });
    },
    enableEditButtons () {
      const allButtons = document.querySelectorAll('button[data-qa="fcs-editable-text-edit-button"]');
      allButtons.forEach(button => {
        button.removeAttribute('disabled');
      });
    },
    cancelEditName () {
      this.card.scheduleName = this.originalScheduleName;
      this.isEditing = false;
    },
    toggleModal () {
      this.showDeleteModal = !this.showDeleteModal;
    },
    async handleDelete () {
      await this.deleteNotification(this.card.correlationID);
      this.toggleModal();
      const message = this.isErrorStatus ? i18n.t('global.deleteErrorMessage') : i18n.t('global.deleteConfirmation', { name: this.card.scheduleName });
      this.$emit('deleted', message);
      if (!this.isErrorStatus) {
        this.removeAlertCard(this.card);
      }
      return this.$store.commit('deleteNotification', message);
    },
    duplicateNames (value) {
      const allowedScheduleNames = this.getAlertNames.filter(alert => alert.trim() !== this.currentDuplicateName.toLowerCase().trim());
      return !allowedScheduleNames.includes(value.toString().toLowerCase().trim());
    }
  },
  computed: {
    ...mapState('vehicles', ['selection']),
    ...mapState(['timeZone', 'timeZoneConverter']),
    ...mapGetters(['isErrorStatus', 'getAlertNames']),
    translatedStrings () {
      return {
        validationDefault: `${i18n.t('inhibit.editableText.validationDefault')}`,
        saveButtonText: `${i18n.t('global.save')}`,
        cancelButtonText: `${i18n.t('global.cancel')}`,
        deleteModalTitle: `${i18n.t('inhibit.deleteSchedule.confirmTitle')}`,
        deleteOkButton: `${i18n.t('global.delete')}`,
        deInhibitText: `${i18n.t('inhibit.deleteSchedule.deInhibitText')}`,
        leaveInhibitedButtonText: `${i18n.t('inhibit.deleteSchedule.leaveInhibitedButtonText')}`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.editableScheduleName {
  font-size: large;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .cardContainer {
    flex-direction: column;
  }
}

.subtitleText {
  color: grey;
}

.linkText {
  color: #0064D4;
  font-weight: lighter;
}

.vehicleText {
  font-weight: lighter;
}

.delete {
  color: red;
  align-self: flex-end;
  font-weight: lighter;
}

@media only screen and (max-width: 640px) {
  .delete {
    align-self: center;
  }
}

.scheduleCardStyle {
  margin: 0.5rem 0.5rem 0.5rem 0rem;
}
@media only screen and (min-width:641px) {
  .scheduleCardStyle {
    max-width: 50%;
  }
}

.flexCardLayout {
  display: flex;
  flex-direction: column;
}
</style>
