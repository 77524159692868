import axios, { AxiosInstance } from 'axios';
import { filterUrlParams, vehicleUrlParams } from '@/store/types';
import { csrfInterceptor } from '@/services/CsrfInterceptor';

class VehicleService {
  private readonly client: AxiosInstance;

  constructor (client?: AxiosInstance) {
    if (client) {
      this.client = client;
    } else {
      this.client = axios.create({
        baseURL: process.env.VUE_APP_SMA_BASE_URL,
        withCredentials: true
      });
    }
    this.client.interceptors.request.use(csrfInterceptor);
  }

  async getVehicles (urlParams: vehicleUrlParams) {
    const response = await this.client.get('v1/vehicles', { params: urlParams });
    return response.data;
  }

  async getVehicleFilters (urlParams: filterUrlParams) {
    try {
      const response = await this.client.get('v1/filters', { params: urlParams });
      return response.data;
    } catch (e) {
      console.log('Unable to get filters from v1/filters');
    }
  }
}

export default new VehicleService();
export { VehicleService };
