/* eslint-disable no-unused-vars */
import { i18n } from '@/i18n';

export const INHIBIT_TYPE = {
  START_INHIBIT: `${i18n.t('inhibit.type.crank')}`,
  DRIVE_INHIBIT: `${i18n.t('inhibit.type.driving')}`
};

export const INHIBIT_ACTION = {
  APPLY_VEHICLE_INHIBITION: `${i18n.t('inhibit.action.apply')}`,
  REMOVE_VEHICLE_INHIBITION: `${i18n.t('inhibit.action.remove')}`,
  SCHEDULE_VEHICLE_INHIBITION: `${i18n.t('inhibit.action.schedule')}`
};

export const INHIBIT_REQUEST_STATUS = {
  REQUESTED: `${i18n.t('inhibit.requestStatus.requested')}`,
  PENDING: `${i18n.t('inhibit.requestStatus.pending')}`,
  FAILURE: `${i18n.t('inhibit.requestStatus.failure')}`,
  SUCCESS: `${i18n.t('inhibit.requestStatus.success')}`,
  COMPLETED: `${i18n.t('inhibit.requestStatus.completed')}`,
  CANCELLED: `${i18n.t('inhibit.requestStatus.cancelled')}`
};

export const INHIBIT_STATUS = {
  ON: `${i18n.t('inhibit.status.on')}`,
  OFF: `${i18n.t('inhibit.status.off')}`,
  PENDING_ON: `${i18n.t('inhibit.status.pendingOn')}`,
  PENDING_OFF: `${i18n.t('inhibit.status.pendingOff')}`,
  FAILED: `${i18n.t('inhibit.status.failed')}`,
  GPS_TRACKING_OFF: `${i18n.t('inhibit.status.gpsTrackingOff')}`,
  ALL_DATA_OFF: `${i18n.t('inhibit.status.allDataOff')}`,
  UNKNOWN: `${i18n.t('inhibit.status.unknown')}`
};

export function getInhibitStatusDisplay (inhibitStatus: any) {
  switch (inhibitStatus) {
  case INHIBIT_STATUS.OFF:
    return `${i18n.t('inhibit.returnStatus.off')}`;
  case INHIBIT_STATUS.ON:
    return `${i18n.t('inhibit.returnStatus.on')}`;
  case INHIBIT_STATUS.PENDING_OFF:
    return `${i18n.t('inhibit.returnStatus.pendingOff')}`;
  case INHIBIT_STATUS.PENDING_ON:
    return `${i18n.t('inhibit.returnStatus.pendingOn')}`;
  case INHIBIT_STATUS.FAILED:
    return `${i18n.t('inhibit.returnStatus.failed')}`;
  case INHIBIT_STATUS.ALL_DATA_OFF:
    return `${i18n.t('inhibit.returnStatus.allDataOff')}`;
  case INHIBIT_STATUS.GPS_TRACKING_OFF:
    return `${i18n.t('inhibit.returnStatus.gpsTrackingOff')}`;
  case INHIBIT_STATUS.UNKNOWN:
    return `${i18n.t('inhibit.returnStatus.unknown')}`;
  }
  return '';
}
