<template>
  <fcs-row align-h="center" align-self="center">
    <fcs-col class="d-flex flex-column">
      <div class="options ml-2 pl-2">
        <fcs-row align-v="center">
          <p class="mb-2"> {{ $t('inhibit.' + scheduleType + '.createSchedule.activate') }} </p>
        </fcs-row>
        <fcs-row align-v="center">
          <fcs-date-picker
            :value="startDate"
            data-qa="startDatePicker"
            @onInput="updateStartDate"
            boundary="viewport"
            :initialText="displayDate(this.startDate)"
            :locale="i18n().locale"
            :labelText="$t('global.createSchedule.startDate')"
            :errorState="false"
            :errorText="$t('inhibit.requiredErrorText')"
            :dateDisabled="getStartDate"
          />
          <span style="padding-top: 20px; margin-top: 20px">
            <p class="mr-2 ml-2"> {{ $t('global.at') }} </p>
          </span>
          <fcs-time-picker
            ref = "startTimePicker"
            data-qa="startTimePicker"
            @onInput="updateStartTime"
            :labelText="$t('global.createSchedule.startTime')"
            :errorState="false"
            :disabled="false"
            :clear="false"
            hoursMins
            :hoursMinsInput="true"
            twelveHour
            helperText=""
            :value="presetStartTime"
          />
        </fcs-row>
        <div>
        <fcs-row align-v="center" class="mt-4">
          <p class="mb-0 pb-0"> {{ $t('inhibit.' + scheduleType + '.createSchedule.deactivate') }}</p>
        </fcs-row>
          <fcs-row>
            <p class="description mb-1 pb-1">{{ $t('inhibit.start.note') }}</p></fcs-row>
        </div>
        <fcs-row align-v="center">
          <fcs-date-picker
            :value="endDate"
            data-qa="endDatePicker"
            @onInput="updateEndDate"
            boundary="viewport"
            :initialText="displayDate(this.endDate)"
            :locale="i18n().locale"
            :labelText="$t('global.createSchedule.endDate')"
            :errorState="false"
            :errorText="$t('inhibit.requiredErrorText')"
            :dateDisabled="getEndDateRange"
          />
          <span style="padding-top: 20px; margin-top: 20px">
            <p class="mr-2 ml-2"> {{ $t('global.at') }} </p>
          </span>
          <fcs-time-picker
            @onInput="updateEndTime"
            data-qa="endTimePicker"
            ref="endTimePicker"
            :labelText="$t('global.createSchedule.endTime')"
            :errorState="false"
            :disabled="false"
            :clear="false"
            hoursMins
            :hoursMinsInput="true"
            twelveHour
            helperText=""
            :value="presetEndTime"
          />
        </fcs-row>
      </div>
      <div>
        <fcs-row class="pt-2" align-v="center">
         <TimezoneSelector class="TimezoneSelector" data-qa="TimezoneSelector" />
        </fcs-row>
      </div>
      <hr>
      <fcs-row class="pr-3 pl-3 mobile-friendly-buttons" align-h="end">
        <fcs-button class="mr-4" v-if="activeIndex>0" data-qa="previous-button" variant="outline-primary" @click="handlePrevClick()">{{ $t('global.previous') }}</fcs-button>
        <fcs-button class="stacked-buttons" :disabled="!isValidDateTimeRange()" data-qa="next-button" variant="primary" @click="handleNextClick()"> {{ $t('global.next') }} </fcs-button>
      </fcs-row>
      <fcs-row>
        <fcs-col></fcs-col>
        <fcs-col>
          <div style="color: red; text-align: right" class="mt-2" :hidden="isInhibitRangeValid" data-qa="error-text-range">{{ $t('inhibit.scheduleOutOfRangeError') }}</div>
          <div style="color: red; text-align: right" class="mt-2" :hidden="isScheduleInFuture" data-qa="error-text-past">{{ $t('inhibit.scheduleBeforeNowError') }}</div>
        </fcs-col>
      </fcs-row>
    </fcs-col>
  </fcs-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { i18n } from '@/i18n';
import moment from 'moment-timezone';
import TimezoneSelector from '@/components/common/TimezoneSelector';

export default {
  name: 'ChooseOneTimeSchedule',
  components: { TimezoneSelector },
  props: {
    card: Object,
    scheduleType: String
  },
  data () {
    return {
      isValidated: false,
      isInhibitRangeValid: true,
      isScheduleInFuture: true,
      presetStartTime: undefined,
      presetEndTime: undefined
    };
  },
  methods: {
    i18n () {
      return i18n;
    },
    ...mapActions(['updateSchedule', 'saveStartTime', 'saveEndTime', 'saveStartDate', 'saveEndDate']),
    updateStartTime (text) {
      this.saveStartTime(text);
      this.showHelperText();
    },
    updateEndTime (text) {
      this.saveEndTime(text);
      this.showHelperText();
    },
    updateStartDate (text) {
      this.saveStartDate(text);
      this.showHelperText();
    },
    updateEndDate (text) {
      this.saveEndDate(text);
      this.showHelperText();
    },
    getStartDate (ymd, date) {
      var today = moment();
      return !moment(date).isSameOrAfter(today, 'days');
    },
    displayDate (dateVariable) {
      if (dateVariable !== undefined && dateVariable.length > 0) {
        return dateVariable;
      }
      return 'MM-DD-YYYY';
    },
    isValidDateTimeRange () {
      if (!this.isComplete()) {
        return false;
      }

      const now = moment().utc(); // assume system/browser time and convert to UTC. we should really be fetching this from a server/third-party.
      const format = 'YYYY-MM-DD hh:mm A';
      const startTime = moment.tz(`${this.startDate} ${this.startTime.hour}:${this.startTime.min} ${this.startTime.amPm}`, format, this.fsiTimeZone).utc();
      const endTime = moment.tz(`${this.endDate} ${this.endTime.hour}:${this.endTime.min} ${this.endTime.amPm}`, format, this.fsiTimeZone).utc();

      this.isScheduleInFuture = startTime.isAfter(now);
      this.isInhibitRangeValid = endTime.isAfter(startTime) && moment.duration(endTime.diff(startTime)).asDays() <= 7;

      return this.isScheduleInFuture && this.isInhibitRangeValid;
    },
    getEndDateRange (ymd, date) {
      var selectedStartDate = this.startDate;
      var sevenDays = moment(selectedStartDate).add(7, 'days');

      var expression = moment(date).isBetween(selectedStartDate, sevenDays, 'days', '[]');
      return !expression;
    },
    showHelperText () {
      if (this.startDate && this.endDate && this.startTime !== `${i18n.t('global.selectTime')}` && this.endTime !== `${i18n.t('global.selectTime')}` && this.fsiTimeZone !== '') {
        this.isValidDateTimeRange();
      }
    },
    displayInitialSetTimes () {
      if (this.startTime.hour !== undefined) {
        this.presetStartTime = {
          hour: this.startTime.hour,
          min: this.startTime.min,
          amPm: this.startTime.amPm
        };
      }
      if (this.endTime.hour !== undefined) {
        this.presetEndTime = {
          hour: this.endTime.hour,
          min: this.endTime.min,
          amPm: this.endTime.amPm
        };
      }
    },
    isComplete () {
      return this.startTime.hour > 0 && this.endTime.hour > 0 && this.startDate.length > 0 && this.endDate.length && this.timeZone !== '';
    },
    handleNextClick (e) {
      if (!this.isValidDateTimeRange()) {
        return;
      }
      this.$store.commit('incrementProgressbarIndex');
      this.$store.commit('saveSchedule', { startTime: this.startTime, endTime: this.endTime, selected: this.selected, timeZone: this.fsiTimeZone });
    },
    handlePrevClick (e) {
      this.$store.commit('decrementProgressbarIndex');
      this.$store.commit('saveSchedule', { startTime: this.startTime, endTime: this.endTime, selected: this.selected, timeZone: this.fsiTimeZone });
    },
    update () {
      this.updateSchedule({ originalCardInfo: this.card, updatedDays: this.selected, updatedStartTime: this.startTime, updatedEndTime: this.endTime, updatedTimeZone: this.fsiTimeZone });
      const message = `${i18n.t('global.editConfirmation', { name: this.card.scheduleName })}`;
      return this.$router.push({ name: 'Vehicles', params: { message } }).catch(() => ({}));
    }
  },
  computed: {
    ...mapState(['activeIndex', 'startTime', 'endTime', 'startDate', 'endDate', 'fsiTimeZone'])
  },
  watch: {
    fsiTimeZone: function () {
      this.isValidDateTimeRange();
    }
  },
  mounted () {
    this.displayInitialSetTimes();
    this.showHelperText();
  }
};
</script>

<style scoped  lang="scss">

button.btn.disabled {
  background-color: var(--black-700) !important;
  border: none;
}
.outline-button {
  background-color: transparent !important;
  border: 0.1rem solid var(--number-background) !important;
  color: var(--number-background) !important;
  &:hover {
    background: rgb(0, 166, 186, 0.1) !important;
    box-shadow: none !important;
  }
}
p.card-text {
  color: var(--black-700);
  margin-bottom: 3px;
}
h4.card-title {
  font-weight: 600;
}
.to{
  display: inline;
  text-transform: lowercase;
}
.TimezoneSelector {
  padding-left: 30.5px;
}

.description {
  color: var(--black-300);
  margin-bottom: 2px;
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 16px;
}

@media only screen and (max-width: 640px) {
  .mobile-friendly-buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .stacked-buttons {
    margin-right: 1.5rem;
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
}
</style>
