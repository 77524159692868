import { state } from '@/store/vehicles/state';
import { actions } from '@/store/vehicles/actions';
import { mutations } from '@/store/vehicles/mutations';
import { getters } from '@/store/vehicles/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export {
  state, mutations, actions, getters
};
